import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import cx from 'classnames';
import compose from 'lodash.flowright';
import { Alert, Input, Button, IconButton, EyeOpened, EyeClosed } from '@airtm/airtm-ui';
import GoogleLoginButton from 'components/GoogleLoginButton/GoogleLoginButton';
import Captcha from 'components/Captcha/Captcha';
import FacebookLoginButton from 'components/FacebookLoginButton/FacebookLoginButton';
import isMainDomain from 'utils/isMainDomain';
import { captchaPropsPropType, formikPropsShape } from 'utils/propTypes';
import { loginSchema } from 'utils/validationSchemas';
import AppleLoginButton from 'components/AppleLoginButton/AppleLoginButton';

class LoginForm extends React.Component {
  static propTypes = {
    captchaProps: captchaPropsPropType.isRequired,
    submitting: PropTypes.bool.isRequired,
    ...formikPropsShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { submitting, setSubmitting } = this.props;

    if (prevProps.submitting !== submitting) {
      setSubmitting(submitting);
    }
  }

  togglePasswordView = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  render() {
    const {
      isSubmitting,
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      errors,
      t,
      serverErrors,
      onAppleLogin,
      onFacebookLogin,
      onGoogleLogin,
      captchaProps: { captchaConfig, captchaIsMounted, captchaIsVerified },
    } = this.props;
    const { showPassword } = this.state;

    return (
      <section className="section mb-6">
        {isMainDomain() && (
          <Alert variant="warning" title={t('LOGIN:AVOID_FRAUD_ALERT_TITLE')}>
            <div className="text--sm">
              <Trans i18nKey="LOGIN:AVOID_FRAUD_ALERT_SUBTITLE_LOGIN" />
            </div>
          </Alert>
        )}
        <h1 className="u-my-4">{t('LOGIN:TITLE')}</h1>
        <form id="login" onSubmit={handleSubmit} className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between align-items-start">
            <span className="social-button-mask" />
            <GoogleLoginButton
              onGoogleLogin={onGoogleLogin}
              text="signin_with"
              data-tracking-id="login-button-google"
              data-testid="google-login"
            />
            <span className="social-button-spacer" />
            <FacebookLoginButton
              onFacebookLogin={onFacebookLogin}
              text="login_with"
              data-tracking-id="login-button-facebook"
              data-testid="facebook-login"
            />
            <span className="social-button-spacer" />
            <AppleLoginButton
              onAppleLogin={onAppleLogin}
              text="sign-in"
              data-tracking-id="login-button-apple"
              data-testid="apple-login"
            />
            <span className="social-button-mask" />
          </div>
          <hr data-text={t('STATIC:OR')} />
          <div className="form-group">
            <div className="form-control-styled">
              <Input
                autoFocus
                autoComplete="email"
                id="email"
                label={t('LOGIN:LABEL_EMAIL')}
                placeholder={t('LOGIN:PLACEHOLDER_EMAIL')}
                type="email"
                value={values.email}
                onChange={handleChange}
                invalid={!!((touched.email && errors.email) || serverErrors.email)}
              />
              {!((touched.email && errors.email) || serverErrors.email) && (
                <Input.HelperText>{t('LOGIN:EMAIL_HELPER_TEXT')}</Input.HelperText>
              )}
              {touched.email && errors.email && (
                <Input.HelperText invalid>{errors.email}</Input.HelperText>
              )}
              {serverErrors.email &&
                serverErrors.email.map((error) => (
                  <Input.HelperText invalid key={error}>
                    {error}
                  </Input.HelperText>
                ))}
            </div>
          </div>
          <div className="form-group">
            <Input
              autoComplete="password"
              id="password"
              label={t('LOGIN:LABEL_PASSWORD')}
              placeholder={t('LOGIN:PLACEHOLDER_PASSWORD')}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              invalid={!!((touched.password && errors.password) || serverErrors.password)}
              addonRight={
                <IconButton
                  icon={showPassword ? <EyeOpened /> : <EyeClosed />}
                  size="sm"
                  tabIndex="-1"
                  variant="primary"
                  onClick={this.togglePasswordView}
                />
              }
            />
            {touched.password && errors.password && (
              <Input.HelperText invalid>{errors.password}</Input.HelperText>
            )}
            {serverErrors.password &&
              serverErrors.password.map((error) => (
                <Input.HelperText invalid key={error}>
                  {error}
                </Input.HelperText>
              ))}
          </div>

          <div className={cx({ 'mb-5': captchaConfig && captchaIsMounted })} data-testid="captcha">
            <Captcha />
          </div>

          <Button
            variant="primary"
            id="submit"
            data-tracking-id="login-button-submit-form"
            data-testid="login-submit-login"
            block
            type="submit"
            disabled={!(values.email && values.password) || isSubmitting || !captchaIsVerified}
            loading={isSubmitting}
          >
            {t('LOGIN:BUTTON_SUBMIT')}
          </Button>

          <Link className="text--lg mt-4" data-testid="forgot-password-login" to="/forgot-password">
            {t('LOGIN:LINK_FORGOT_PASSWORD')}
          </Link>

          <div className="mt-5">
            <span className="text--lg mr-2 text--base">{t('LOGIN:TEXT_NOT_REGISTERED')}</span>
            <Link
              className="text--lg mt-5 text--base"
              data-testid="create-account-login"
              to="/sign-up"
            >
              {t('LOGIN:LINK_CREATE_ACCOUNT')}
            </Link>
          </div>
        </form>
      </section>
    );
  }
}

export default compose(
  withTranslation(['LOGIN']),
  withFormik({
    handleSubmit: (values, { props: { onSubmit } }) => {
      onSubmit({ ...values, email: values.email.toLowerCase() });
    },
    mapPropsToValues: (props) => ({
      email: (props.initialValues && props.initialValues.email) || '',
      password: '',
    }),
    validationSchema: ({ t }) => loginSchema(t),
  }),
)(LoginForm);
