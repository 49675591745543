import { REGEX_FORM_FIELDS } from '@airtm/utils/dist/constants';

export default function getPatternTag(pattern) {
  const isNumber = /^\^(\[0-9\]|\\d)\{(\d+|\d+,\d+)\}\$$/.test(pattern);
  const isAlphanumeric = /^\^\[(A-Za-z|a-zA-Z)0-9\]\{(\d+|\d+,\d+)\}\$$/.test(pattern);

  if (isNumber || isAlphanumeric) {
    // (^[0-9]{n}$ | ^[0-9]{n,m}$) | (^[A-Za-z0-9]{n}$ | ^[A-Za-z0-9]{n,m}$)
    const type = isNumber ? 'NUMBER' : 'ALPHANUMERIC';
    const values = pattern.substring(pattern.indexOf('{') + 1, pattern.indexOf('}')).split(','); // n | n,m
    const result = {
      tag: `FORM_REGEXP_${type}_${values.length}`, // REGEXP_NUMBER_1 | REGEXP_NUMBER_2 | REGEXP_ALPHANUMERIC_1 | REGEXP_ALPHANUMERIC_2
      values:
        values.length === 1
          ? { length: values[0] } // The input must have length n
          : { min: values[0], max: values[1] }, // The input can have  a length between n and m
    };
    return result;
  }
  switch (pattern) {
    case REGEX_FORM_FIELDS.REGEX_PHONE_NUMBER:
      return {
        tag: 'FORM_REGEXP_PHONE_NUMBER',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_PHONE_NUMBER_WITHOUT_CODE:
      return {
        tag: 'FORM_REGEX_PHONE',
        values: { min: 6, max: 15 },
      };
    case REGEX_FORM_FIELDS.REGEX_EMAIL:
      return {
        tag: 'FORM_REGEX_EMAIL',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_WEBSITE:
      return {
        tag: 'FORM_REGEX_WEBSITE',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_IFSC:
      return {
        tag: 'FORM_REGEX_IFSC',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_RUT:
      return {
        tag: 'FORM_REGEX_RUT',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_ID_VENEZUELA:
      return {
        tag: 'FORM_REGEX_ID_VENEZUELA',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_ADDRESS_BITCOINCASH:
      return {
        tag: 'FORM_REGEX_ADDRESS_BITCOINCASH',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_PERFECT_MONEY_ACCOUNT:
      return {
        tag: 'FORM_REGEX_PERFECT_MONEY_ACCOUNT',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_UPI_ADDRESS:
      return {
        tag: 'FORM_REGEX_UPI_ADDRESS',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_NATIONAL_IDENTIFICATION_NUMBER_COLOMBIA:
      return {
        tag: 'FORM_REGEX_NATIONAL_IDENTIFICATION_NUMBER_COLOMBIA',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_RONIN_WALLET:
      return {
        tag: 'FORM_REGEX_RONIN_WALLET',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_SWIFT_BIN_CODE:
      return {
        tag: 'FORM_REGEX_SWIFT_BIN_CODE',
        values: null,
      };
    case REGEX_FORM_FIELDS.REGEX_NUBAN:
      return {
        tag: 'FORM_REGEX_NUBAN',
        values: null,
      };
    default:
      return {
        tag: 'FORM_PATTERN',
        values: null,
      };
  }
}
