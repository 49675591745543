const isScriptLoaded = (src: string): boolean => !!document.querySelector(`script[src="${src}"]`);

/**
 * Inject a script Tag in the dom
 * @param src The src of the script
 * @param checkFn ID to check if the script has been loaded. (script#id) or a checker function that determines if the script has been already loaded
 *
 * @return A promise to rejects on script error or resolves to true if the script was just added or to false if script was already injected
 */

function loadScript(
  src: string,
  checkFn?: () => boolean,
  options?: { async: boolean },
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (checkFn?.()) {
      resolve(false);
      return;
    }

    if (isScriptLoaded(src)) {
      resolve(false);
      return;
    }

    const node = document.createElement('script');
    node.onload = () => resolve(true);
    node.onerror = (e) => {
      node.remove();
      reject(e);
    };
    node.src = src;
    if (options?.async != null) {
      node.async = options.async;
    }

    if (document.body) {
      document.body.appendChild(node);
    }
  });
}

export default loadScript;
