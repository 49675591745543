import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectText } from '@airtm/airtm-ui';
import { useCountries, useCountryFromValue } from 'utils/hooks';

const SelectCountry = function ({ onChange, value, ...props }) {
  const [country, setCountry] = useState(null);
  const [options] = useCountries();

  const onSelect = useCallback(
    (selected) => {
      setCountry(selected);
      onChange(selected);
    },
    [onChange],
  );

  // If value prop is provided it'll sync internal and external state.
  useCountryFromValue({ setCountry, value, options });

  return (
    <SelectText id="country" options={options} value={country} onChange={onSelect} {...props} />
  );
};

SelectCountry.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

SelectCountry.defaultProps = {
  onChange: () => null,
  value: false,
};

export default SelectCountry;
