import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Lock } from '@airtm/airtm-ui';
import cx from 'classnames';
import Captcha from 'components/Captcha/Captcha';
import { useCaptcha } from 'components/Captcha/withCaptcha';

import MfaForm from '../MfaForm/MfaForm';

const MfaFormContainer = function (props) {
  const { t, sendMfaRecoveryCodes, submitting } = props;

  const { captchaIsVerified } = useCaptcha();

  return (
    <section className="section my-8 mt-sm-6 mt-md-5">
      <Lock className="text--black mb-4" size={32} />

      <h1>
        <span>{t('LOGIN:MFA_HEADER')}</span>
      </h1>

      <div className="section__content">
        <div className="u-mb-3">
          <MfaForm {...props} />
        </div>

        <Button
          data-tracking-id="mfa-button-recover-mfa"
          variant="link"
          className={cx('link--dark', 'text--black', 'break-line')}
          onClick={sendMfaRecoveryCodes}
          disabled={submitting || !captchaIsVerified}
        >
          {t('LOGIN:MFA_UNAVAILABLE_LINK')}
        </Button>

        <Captcha className="u-mt-3" onSuccess={sendMfaRecoveryCodes} />
      </div>
    </section>
  );
};

MfaFormContainer.propTypes = {
  t: PropTypes.func.isRequired,
  sendMfaRecoveryCodes: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default MfaFormContainer;
