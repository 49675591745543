import { useTranslation } from 'react-i18next';

function createTranslateFuncs(t, aliases) {
  return Object.values(aliases).reduce((acc, prefix) => {
    return {
      ...acc,
      [prefix]: function tt(str) {
        return t(`${prefix}${str}`);
      },
    };
  }, {});
}

export default function useTranslationWithPrefix(prefix, opts = []) {
  const translate = useTranslation(opts);
  const { t } = translate;
  // Prefix can be either a string (for a prefixed namespace) or an object,
  // which means creating a aliases k/v.
  const aliases = typeof prefix === 'string' ? {} : createTranslateFuncs(t, prefix);

  // Translate text (tt) will only be available when using single prefixed namespace.
  function tt(key) {
    if (typeof prefix !== 'string') {
      throw new Error('tt (translate text) function cannot be used if aliases object is used.');
    }
    if (Array.isArray(key)) {
      return t(key.map((k) => `${prefix}${k}`));
    } 
    return t(`${prefix}${key}`);
  }
  return { ...translate, tt, aliases };
}
