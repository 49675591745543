// @flow
import { makeVar } from '@apollo/client';

export const initialData = {
  __typename: 'Local__NetworkStatus',
  online: true,
  retrying: false,
};
export const networkStatusVar = makeVar(initialData);

export const writeNetworkStatus = (networkStatus) => {
  networkStatusVar(networkStatus);
};

export const resolvers = {
  Query: {
    networkStatus: () => {
      try {
        const networkStatus = networkStatusVar();

        return networkStatus;
      } catch (error) {
        return {
          __typename: 'Local__NetworkStatus',
          retrying: false,
          online: true,
        };
      }
    },
  },
};
