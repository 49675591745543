type Params = {
  unifiedRate: { destinationNetAmount: number | null } | null | undefined;
  fundsConfig?: { destinationAmount: number | null };
};

export const getDestinationAmount = ({
  unifiedRate,
  fundsConfig = { destinationAmount: null },
}: Params): number | null => {
  if (fundsConfig.destinationAmount) {
    return fundsConfig.destinationAmount;
  }

  if (!unifiedRate) {
    return null;
  }

  return unifiedRate.destinationNetAmount && Number(unifiedRate.destinationNetAmount);
};

export default getDestinationAmount;
