/* eslint-disable react/require-default-props */
// @flow
import { Button, IconModal, Input, InputOTP, Lock } from '@airtm/airtm-ui';
import React, { useEffect } from 'react';

type Props = {
  invalidCode: boolean,
  isOpen: boolean,
  submitting: boolean,
  textCancelButton?: ?string,
  textInvalidCode?: ?string,
  textLabelCode?: ?string,
  title?: ?string,
  onCancel: () => void,
  onSubmit: (event: SyntheticEvent<HTMLButtonElement>, { mfaCode: string }) => Promise<void>,
};

type TFAInputProps = {
  label: string,
  value: string,
  onChange: (value: string) => void,
  onBlur: () => void,
  errors?: Array<string>,
  helpText?: string,
};

export const TFAInput = function ({
  label,
  value,
  onChange,
  onBlur,
  errors,
  helpText,
  ...inputProps
}: TFAInputProps) {
  const handleOnChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <div className="d-flex flex-column align-items-start justify-content-start">
      <InputOTP
        autoFocus
        id="pass2fa"
        name="code"
        value={value}
        onChange={handleOnChange}
        onBlur={onBlur}
        label={label}
        invalid={errors?.length > 0}
        {...inputProps}
      />
      {helpText && <Input.HelperText key={helpText}>{helpText}</Input.HelperText>}
      {errors?.map((error) => (
        <Input.HelperText invalid key={error}>
          {error}
        </Input.HelperText>
      ))}
    </div>
  );
};

const MfaForm = function ({
  invalidCode,
  isOpen,
  onCancel,
  onSubmit,
  submitting,
  textCancelButton,
  textInvalidCode,
  textLabelCode,
  title,
}: Props) {
  const [code, setCode] = React.useState('');

  const onCodeChange = (value) => {
    setCode(value);
    if (value.length === 6) {
      onSubmit({ mfaCode: value });
    }
  };

  // clean the input on close
  useEffect(() => {
    if (!isOpen) setCode('');
  }, [isOpen]);

  useEffect(() => {
    if (invalidCode && code.length === 6) {
      setCode('');
    }
  }, [invalidCode]);

  return (
    <IconModal modalClose={onCancel} show={isOpen}>
      <IconModal.Body icon={<Lock />} title={title}>
        <div>
          <TFAInput
            label={textLabelCode}
            value={code}
            onChange={onCodeChange}
            errors={invalidCode ? [textInvalidCode] : undefined}
          />
        </div>
      </IconModal.Body>
      <IconModal.Actions>
        <Button variant="destructive" disabled={submitting} onClick={onCancel}>
          {textCancelButton}
        </Button>
      </IconModal.Actions>
    </IconModal>
  );
};

MfaForm.defaultProps = {
  textCancelButton: 'Cancel',
  textInvalidCode: '2FA Code is invalid',
  textLabelCode: 'Please type your Two-Factor code to continue',
  title: 'Confirm with 2FA',
};

export default MfaForm;
