import React, { Component, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import compose from 'lodash.flowright';
import { useSubscription } from '@apollo/client';
import withUser from 'hoc/withUser';
import JwtManager from 'lib/JwtManager';
import {
  addInteractionListener,
  removeInteractionListener,
} from 'utils/listenForInteraction/listenForInteraction';
import useHeapUserProperties from './useHeapUserProperties';
import ANALYTICS_PING_SUBSCRIPTION from './AnalyticsPingSubscription.graphql';

const { ANCLAP_BASE_URL } = process.env;

export default compose(
  (WrappedComponent) =>
    function SessionContainerComponent(props) {
      const history = useHistory();

      const [jwt, setJwt] = useState(JwtManager.jwt);
      const [expiresAt, setExpiresAt] = useState(JwtManager.expiresAt);
      const idleInterval = useRef(null);

      useSubscription(ANALYTICS_PING_SUBSCRIPTION, { skip: !jwt });

      useHeapUserProperties(jwt);

      useEffect(
        () => {
          const messageListener = async (e) => {
            if (e.origin === ANCLAP_BASE_URL && e.data === 'card-load-request') {
              const paymentMethodCategoryId = encodeURIComponent('airtm:prepaid-card:anclap');
              history.push(`/funds/withdraw?paymentMethodCategoryId=${paymentMethodCategoryId}`);
            }
          };

          window.addEventListener('message', messageListener);

          return () => {
            window.removeEventListener('message', messageListener);
          };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
      );

      // destroy jwt if idleExpiresAt is outdated
      useEffect(() => {
        if (!idleInterval.current) {
          idleInterval.current = setInterval(() => {
            if (isAfter(new Date(), JwtManager.idleExpiresAt)) {
              JwtManager.destroyJwt();
            }
          }, 1000);
        }
        return () => {
          clearInterval(idleInterval.current);
          idleInterval.current = null;
        };
      }, []);

      // reset idleExpiresAt date on interact
      useEffect(() => {
        const cb = () => JwtManager.resetIdleSessionExpirationDate();
        addInteractionListener(cb);
        return () => removeInteractionListener(cb);
      }, []);

      // update internal state of jwt using a listener
      useEffect(() => {
        const onJwtUpdated = (newJwt, newExpiresAt) => {
          setJwt(newJwt);
          setExpiresAt(newExpiresAt);
          window.ReactNativeWebView?.postMessage?.(JSON.stringify({ jwt: newJwt }));
        };

        JwtManager.addJwtListener(onJwtUpdated);
        return () => JwtManager.removeJwtListener(onJwtUpdated);
      }, []);

      return <WrappedComponent {...props} jwt={jwt} expiresAt={expiresAt} />;
    },
  withUser,
  // eslint-disable-next-line react/no-multi-comp
  (WrappedComponent) =>
    class extends Component {
      static displayName = `SessionManager(${WrappedComponent.displayName || 'UnkownComponent'})`;
      static propTypes = {
        jwt: PropTypes.string,
        refetchUser: PropTypes.func,
        user: PropTypes.shape({
          id: PropTypes.string,
        }),
      };

      static defaultProps = {
        jwt: null,
        refetchUser: () => null,
        user: null,
      };

      componentDidUpdate(prevProps) {
        if (this.props.jwt !== prevProps.jwt && this.props.refetchUser) {
          this.props.refetchUser();
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    },
)((props) => props.children);
