export const singleActionState = {
  working: false,
  finished: false,
  error: false,
};

export const STARTED = 'started';
export const ERRORED = 'errored';
export const FINISHED = 'finished';
export const RESET = 'reset';
export const KV = 'kv';

/**
 * This reusable reducer can help with a single action UI state.
 * @param {*} state
 * @param {*} action
 */
export function singleActionReducer(state, action) {
  const { type, ...others } = action;
  switch (action.type) {
    case STARTED:
      return { ...state, error: false, working: true };
    case KV:
      return { ...state, [action.field]: action.value };
    case FINISHED:
      return {
        ...state,
        working: false,
        error: false,
        finished: true,
        ...others,
      };
    case ERRORED:
      return { ...state, working: false, error: action.error };
    case RESET:
      if (state.error) {
        return { ...state, error: false };
      }
      return { ...state, ...singleActionState };
    default:
      throw new Error(`Invalid ${action.type} action in singleActionReducer.`);
  }
}

export function setValue(field, value = false) {
  return { type: KV, field, value };
}

export function setFinished(others = {}) {
  return { type: FINISHED, ...others };
}

export function setErr(error) {
  return { type: ERRORED, error };
}
