import React, { useEffect, useRef, useState } from 'react';
import { RGCaptcha } from 'react-geetest-captcha';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE } from '@constants';
import { useChangePreventer, useRemovalPreventer } from 'utils/hooks/useMutationObserver';
import { CAPTCHA_NAME } from './Captcha.constants';
import { useCaptcha } from './withCaptcha';
import { GeetestSolution } from './Captcha.types';
import useNetwork from './useNetwork';

type GeetestUnprocessedSolution = {
  geetest_challenge: string;
  geetest_validate: string;
  geetest_seccode: string;
};

type CaptchaProps = {
  className?: string;
  onSuccess?: (solution: GeetestSolution) => void;
  warningText?: string;
};

const langMapping: { [key: string]: string } = {
  en: 'en',
  es: 'es',
  pt: 'pt-pt',
};

const Captcha = function ({ className, onSuccess, warningText }: CaptchaProps): JSX.Element | null {
  const {
    captchaConfig,
    captchaErrorCallback,
    captchaIsLoading,
    captchaIsMounted,
    captchaSolution,
    captchaSuccessCallback,
  } = useCaptcha();

  const warningElementId = 'captcha-warning';
  const container = useRef<HTMLDivElement>(null);
  const warningElement = useRef<HTMLDivElement>(null);
  useRemovalPreventer(container, warningElementId);
  useChangePreventer(warningElement);

  const { isOnline } = useNetwork();

  const { t } = useTranslation(['ALERTS']);

  const userLanguage = langMapping[localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || 'en'];

  const [lang, setLang] = useState(userLanguage);
  const [willReload, setWillReload] = useState(false);

  // Changing the language will throw an `old challenge` error because of the re-rendering.
  // In `handleError`, we reload the widget to show it with the new language.
  useEffect(() => {
    setLang(userLanguage);
    setWillReload(true);
  }, [userLanguage]);

  useEffect(() => {
    if (captchaSolution && onSuccess) {
      onSuccess(captchaSolution);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaSolution]);

  // RGCaptcha doesn't return the error
  const handleError = () => {
    if (willReload) {
      setWillReload(false);
    } else {
      // On network error, default to English instead of Chinese
      setLang(langMapping.en);
    }
    captchaErrorCallback();
  };

  const handleSuccess = ({
    geetest_challenge: challenge,
    geetest_validate: validate,
    geetest_seccode: seccode,
  }: GeetestUnprocessedSolution) => {
    const solution = {
      challenge,
      validate,
      seccode,
    };
    captchaSuccessCallback(solution);
  };

  if (!captchaIsMounted || !captchaConfig) {
    return null;
  }

  if (!isOnline) {
    return <span>{t('ALERTS:OFFLINE_CAPTCHA_MESSAGE')}</span>;
  }

  if (captchaIsLoading) {
    return null;
  }

  return (
    <div ref={container} className={className}>
      {warningText && (
        <div
          id={warningElementId}
          ref={warningElement}
          className="alert alert--warning text--sm text--left u-mb-2"
        >
          {warningText}
        </div>
      )}
      <RGCaptcha
        name={CAPTCHA_NAME}
        width="100%"
        onSuccess={handleSuccess}
        onError={handleError}
        data={captchaConfig}
        lang={lang}
        product="float"
      />
    </div>
  );
};

Captcha.defaultProps = {
  className: undefined,
  onSuccess: undefined,
  warningText: undefined,
};

export default Captcha;
