import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Button } from '@airtm/airtm-ui';
import { LOCAL_STORAGE, PLAYSTORE_LINK } from '@constants';
import playstoreEs from 'assets/images/logo-playstore-es.svg';
import playstoreEn from 'assets/images/logo-playstore-en.svg';
import playstorePT from 'assets/images/logo-playstore-pt.svg';

// new logos on https://play.google.com/intl/es-419/badges/
const playstoreLogoSrc = {
  es: playstoreEs,
  en: playstoreEn,
  pt: playstorePT,
};

const PlaystoreButton = function ({ className }) {
  const { i18n } = useTranslation();
  const classes = cx('p-0', className);
  const referrer = localStorage.getItem(LOCAL_STORAGE.ANDROID_REFERRER);

  let link = `${PLAYSTORE_LINK}&hl=${i18n.language}`;
  if (referrer) {
    link = `${link}&referrer=${encodeURIComponent(referrer)}`;
  }

  return (
    <Button
      component="a"
      variant="tertiary"
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      data-testid="google-play-login"
    >
      <img src={playstoreLogoSrc[i18n.language]} className="u-h50" alt="logo playstore" />
    </Button>
  );
};

PlaystoreButton.propTypes = {
  className: PropTypes.string,
};

PlaystoreButton.defaultProps = {
  className: null,
};

export default PlaystoreButton;
