import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Alert } from '@airtm/airtm-ui';
import { withTranslation } from 'react-i18next';

class Notification extends Component {
  static propTypes = {
    removeNotification: PropTypes.func,
    autoClose: PropTypes.bool,
    showCta: PropTypes.bool,
    autoCloseDuration: PropTypes.number,
    id: PropTypes.string.isRequired,
    message: PropTypes.string,
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    errorCode: PropTypes.string,
  };

  static defaultProps = {
    showCta: false,
    autoClose: true,
    autoCloseDuration: 10000,
    type: 'informative',
    message: null,
    removeNotification: null,
    errorCode: null,
  };

  componentDidMount() {
    const { autoClose, autoCloseDuration, removeNotification, id } = this.props;

    if (autoClose) {
      this.timeout = setTimeout(() => removeNotification({ id }), autoCloseDuration);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  removeNotification = () => {
    const { removeNotification, id } = this.props;

    removeNotification({ id });
  };

  render() {
    const { errorCode, title, type, t, showCta } = this.props;
    let { message } = this.props;

    if (message?.includes?.('Network error:')) {
      // SB-1012
      console.warn(`Unexpected network error: ${message}`);
      message = t('ERRORS:INTERNAL_SERVER_ERROR');
    }

    const actionProps = showCta
      ? {
          btnLabel: t('STATIC:UNDERSTOOD'),
          btnAction: this.removeNotification,
        }
      : {
          onClickContainer: this.removeNotification,
        };

    return (
      <Alert
        className="u-miw300"
        variant={type}
        title={title}
        draggable
        closable
        data-testid="dismiss-notification"
        message={showCta ? message : <p>{message}</p>}
        errorCode={errorCode}
        errorLabel={t('ERRORS:ERROR')}
        {...actionProps}
      />
    );
  }
}

export default withTranslation(['STATIC', 'ERRORS'])(Notification);
