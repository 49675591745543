import React, { useEffect, useRef } from 'react';
import compose from 'lodash.flowright';
import { withFormik, Form } from 'formik';
import { withTranslation } from 'react-i18next';
import { TFAInput } from 'components/MfaForm/MfaForm';
import { formikPropsShape } from 'utils/propTypes';
import { mfaCodeSchema } from 'utils/validationSchemas';

const MfaForm = function ({
  t,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  serverErrors,
  setFieldValue,
  submitForm,
}) {
  const formRef = useRef(null);

  useEffect(() => {
    if (formRef.current && values.code && values.code.length === 6) {
      submitForm();
    }
  }, [submitForm, values]);

  useEffect(() => {
    if (formRef.current && serverErrors?.code && values.code?.length === 6) {
      setFieldValue('code', '');
    }
  }, [serverErrors?.code]);

  return (
    <Form
      ref={formRef}
      autoComplete="off"
      onSubmit={handleSubmit}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          // Prevent submitting form on enter, which caused the user to return to login (SB2B-638)
          e.preventDefault();
        }
      }}
    >
      <TFAInput
        label={t('LOGIN:LABEL_ONE_TIME_PASSWORD')}
        value={values.code}
        onChange={handleChange('code')}
        onBlur={handleBlur}
        errors={serverErrors?.code}
      />
    </Form>
  );
};

MfaForm.propTypes = formikPropsShape;

export default compose(
  withTranslation(),
  withFormik({
    handleSubmit: ({ code }, { props: { onSubmit } }) => {
      onSubmit({ code });
    },
    mapPropsToValues: () => ({
      code: '',
    }),
    validationSchema: mfaCodeSchema,
  }),
)(MfaForm);
