import { useCallback, useEffect, useState } from 'react';
import loadScript from 'utils/loadScript';
import { DEFAULT_LANGUAGE } from '@constants';
import { useTranslation } from 'react-i18next';

const APPLE_LOCALES = {
  en: 'en_US',
  es: 'es_MX', // Apple doesn't support es_LA
  pt: 'pt_BR',
};

type Props = {
  onLogin: (appleAuth: any) => void;
  onError: (error: any) => void;
};

function useAppleJs({ onLogin, onError }: Props) {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const { language = DEFAULT_LANGUAGE } = i18n;
  const locale = APPLE_LOCALES[language as keyof typeof APPLE_LOCALES];

  const handleSuccess = useCallback(
    (event) => {
      if (onLogin) {
        // @ts-ignore
        onLogin(event.detail);
      }
    },
    [onLogin],
  );

  const handleError = useCallback(
    (event) => {
      if (onError) {
        onError(event);
      }
    },
    [onError],
  );

  useEffect(() => {
    let unmounted = false;

    const loadApple = async () => {
      try {
        // @ts-ignore
        window.AppleID = null;

        // Remove script, we need to re-init when lang changes
        ((d) => {
          const existing = d.querySelectorAll(
            `script[src*="appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1"]`,
          );
          existing.forEach((script) => {
            script.parentNode?.removeChild(script);
          });
        })(document);
        const loaded = await loadScript(
          `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${locale}/appleid.auth.js`,
          // @ts-ignore
          () => !!window.AppleID,
        );
        if (loaded && !unmounted) {
          // @ts-ignore
          window.AppleID?.auth.init({
            clientId: process.env.APPLE_OAUTH_CLIENT_ID,
            scope: 'email name',
            redirectURI: window.location.origin,
            usePopup: true,
          });
          document.removeEventListener('AppleIDSignInOnSuccess', handleSuccess);
          document.removeEventListener('AppleIDSignInOnFailure', handleError);
          // 'once' is required.  The third party script often triggers 2 events
          document.addEventListener('AppleIDSignInOnSuccess', handleSuccess, { once: true });
          document.addEventListener('AppleIDSignInOnFailure', handleError);
        }
      } catch (error) {
        // @ts-ignore
        console.warn('Error loading Apple api', { error: error.message || null });
      } finally {
        if (!unmounted) setLoading(false);
      }
    };
    loadApple();
    return () => {
      unmounted = true;
      document.removeEventListener('AppleIDSignInOnSuccess', handleSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleError);
    };
  }, [locale, handleError, handleSuccess]);

  return { loading };
}

export default useAppleJs;
