// eslint-disable-next-line import/prefer-default-export
import { REGEX_FORM_FIELDS } from '@airtm/utils/dist/constants';

export const validateEmail = (email) =>
  new RegExp(REGEX_FORM_FIELDS.REGEX_EMAIL).test(String(email).toLowerCase());

export function validRedirectUrl(target) {
  const url = `${window.location.protocol}//${window.location.host}`;
  return url === target.substr(0, url.length);
}

export function getRedirectPath(target) {
  const url = `${window.location.protocol}//${window.location.host}`;
  return target.substr(url.length);
}
