import React from 'react';
import Slider from 'react-slick';
import AdrianaImage from 'assets/images/landing/Adriana.jpg';
import DanielImage from 'assets/images/landing/Daniel.jpg';
import SamanthaImage from 'assets/images/landing/Samantha.jpg';
import { useTranslation } from 'react-i18next';
import CommentSlide from './CommentSlide';

const CommentsSlider = function () {
  const { t } = useTranslation('LANDING');

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: false,
    pauseOnHover: false,
  };

  return (
    <Slider {...settings} className="mb-0 w--50 position-fixed">
      <CommentSlide
        imageSrc={SamanthaImage}
        name={t('LANDING:CARROUSEL_NAME_1')}
        title={t('LANDING:CARROUSEL_JOB_1')}
        text={t('LANDING:CARROUSEL_QUOTE_1')}
      />
      <CommentSlide
        imageSrc={AdrianaImage}
        name={t('LANDING:CARROUSEL_NAME_2')}
        title={t('LANDING:CARROUSEL_JOB_2')}
        text={t('LANDING:CARROUSEL_QUOTE_2')}
      />
      <CommentSlide
        imageSrc={DanielImage}
        name={t('LANDING:CARROUSEL_NAME_3')}
        title={t('LANDING:CARROUSEL_JOB_3')}
        text={t('LANDING:CARROUSEL_QUOTE_3')}
      />
    </Slider>
  );
};

export default CommentsSlider;
