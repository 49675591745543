/* eslint-disable import/prefer-default-export */

import { PARTNER } from '@airtm/utils/dist/constants/userRoles';
import { WithUser_me } from 'graphqlDocuments/queries/__generated__/WithUser';

export const resolvers = {
  Auth__User: {
    canUseP2P: (root: WithUser_me): boolean => root?.country !== 'USA',

    isPartner: (root: WithUser_me): boolean => !!root?.roles?.includes(PARTNER),
  },
};
