import React from 'react';

const CommentSlide = function ({ imageSrc, name, title, text }) {
  return (
    <div>
      <div className="position-absolute text--white w-60 top-60 start-20">
        <h2 className="text--white mb-1">{name}</h2>
        <h3 className="text--white mb-5">{title}</h3>
        <p className="h4 font-weight--regular text--white">&quot;{text}&quot;</p>
      </div>
      <img src={imageSrc} alt={name} className="img-login-signup slide" />
    </div>
  );
};

export default CommentSlide;
