// @flow
export default function formatRecipient(recipient: {
  email: string,
  username: string,
  idMethod: string,
}) {
  const { email: rawEmail, username, idMethod } = recipient;
  let email = rawEmail;

  if (idMethod === 'username') {
    const tkns = email.split('@');

    let newAlias;
    if (tkns[0].length > 2) {
      newAlias = `${tkns[0].substring(0, 2)}${tkns[0].substring(2).replace(/./g, '*')}`;
    } else {
      newAlias = `${tkns[0].replace(/./g, '*')}`;
    }

    email = `${newAlias}@${tkns[1][0]}${tkns[1].substring(1).replace(/./g, '*')}`;
  }

  return `${email}${username ? ` (${username})` : ''}`;
}
