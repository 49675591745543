import * as React from 'react';
import { FacebookLogoButton } from '@airtm/airtm-ui';

type Props = {
  onFacebookLogin: (response: any) => void;
  text: 'continue_with' | 'login_with';
  width: number;
};

const FacebookLoginButton: React.FC<Props> = function (props) {
  const { onFacebookLogin, text = '', ...buttonProps } = props;
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const cb = (response: any) => {
      if (response.status === 'connected') {
        onFacebookLogin(response);
      }
    };

    if (window.FB) {
      // XFBML.parse required for buttons created dynamically (ie: not directly in index.html)
      window.FB.XFBML.parse();
      window.FB.Event.subscribe('auth.statusChange', cb);
    }
    setLoading(false);

    return () => {
      if (window.FB) {
        window.FB.Event.unsubscribe('auth.statusChange', cb);
      }
    };
  }, [onFacebookLogin]);

  return (
    <div className="social-button-container">
      <div className="social-button-wrapper">
        <FacebookLogoButton {...buttonProps} disabled={loading} />
      </div>
      <div
        className="fb-login-button social-button-hidden"
        data-size="large"
        data-width="100"
        data-layout="rounded"
        data-button-type={text}
        data-auto-logout-link="false"
        data-use-continue-as="false"
        data-scope="email"
      />
    </div>
  );
};

export default FacebookLoginButton;
