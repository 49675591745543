import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { AirtmLogotype, IconCheck, Dropdown, Header, Download } from '@airtm/airtm-ui';
import { LANGUAGES } from '@airtm/utils/dist/constants';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getBaseURL as getBURL } from 'utils';
import AppStoreButton from 'components/AppStoreButton';
import PlaystoreButton from 'components/PlaystoreButton';

const PublicHeader = function (props) {
  const { i18n, t } = useTranslation(['SIGNUP']);
  const [menus, updateMenu] = useState({ isDownloadOpen: false, isLanguageOpen: false });
  const { showContent } = props;
  const landingUrl = getBURL();

  return (
    <Header id="header" className="header--public header--dark">
      <span className="d-none d-md-block col-1" />
      <Header.Brand className="d-block">
        <a href={landingUrl} className="d-flex">
          <AirtmLogotype className="text--white" />
        </a>
      </Header.Brand>
      {showContent && (
        <Header.Content>
          <Dropdown
            className="language-dropdown mr-4"
            onToggle={(toggle) => updateMenu({ isDownloadOpen: toggle, isLanguageOpen: false })}
            show={menus.isDownloadOpen}
          >
            <Dropdown.Toggle>
              <Download className="mr-3 d-none d-lg-block" /> {t('DOWNLOAD_APP_TITLE')}
            </Dropdown.Toggle>
            <Dropdown.Menu autoPosition={false}>
              <Dropdown.Item className="dropdown-item__text">
                <div className="d-flex flex-column break-line">
                  <div className="font-weight--regular text--sm">
                    {t('DOWNLOAD_APP_DESCRIPTION')}
                  </div>
                  <div className="mb-4 u-mt-1 pt-1 d-none d-lg-block">
                    <QRCodeSVG size={160} value="https://airtm.app.link/appdownload" />
                  </div>
                  <div className="d-flex flex-sm-column justify-content-around mt-2 mt-lg-0">
                    <PlaystoreButton />
                    <AppStoreButton />
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="language-dropdown"
            onToggle={(toggle) => updateMenu({ isDownloadOpen: false, isLanguageOpen: toggle })}
            show={menus.isLanguageOpen}
          >
            <Dropdown.Toggle>{i18n.language.toUpperCase()}</Dropdown.Toggle>
            <Dropdown.Menu autoPosition={false}>
              {LANGUAGES.map((language) => (
                <Dropdown.Item
                  key={language.isoCode}
                  onClick={() => i18n.changeLanguage(language.isoCode)}
                  className={language.isoCode === i18n.language ? 'dropdown-item__active' : ''}
                >
                  <div className="dropdown-item__language">{language.nativeName}</div>
                  {language.isoCode === i18n.language && (
                    <div className="dropdown-item__check">
                      <IconCheck />
                    </div>
                  )}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Header.Content>
      )}
    </Header>
  );
};

PublicHeader.propTypes = {
  showContent: PropTypes.bool,
};

PublicHeader.defaultProps = {
  showContent: true,
};

export default PublicHeader;
