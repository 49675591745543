import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useQuery } from '@apollo/client';
import { LOCAL_STORAGE } from '@constants';
import { BUSINESS_ACCOUNT, PARTNER } from '@airtm/utils/dist/constants/userRoles';
import JwtManager from 'lib/JwtManager';
import LAST_COMPLETED_TRANSACTION_QUERY from './LastCompletedTransactionQuery.graphql';
import {
  LastCompletedTransactionQuery,
  LastCompletedTransactionQuery_activity_edges_node,
} from './__generated__/LastCompletedTransactionQuery';
import HEAP_USER_QUERY from './HeapUserQuery.graphql';
import { HeapUserQuery, HeapUserQuery_me } from './__generated__/HeapUserQuery';

const getLastCompletedTransactionDate = (
  lastCompletedTransaction: LastCompletedTransactionQuery_activity_edges_node,
) =>
  lastCompletedTransaction.updatedAt &&
  format(new Date(lastCompletedTransaction.updatedAt), 'dd/MM/yy');

const getHeapUserProperties = (user: HeapUserQuery_me) => {
  let userType = 'regular';
  const isBusinessAccount = [BUSINESS_ACCOUNT, PARTNER].find((role) => user.roles?.includes(role));
  if (isBusinessAccount) {
    userType = 'enterprise';
  } else if (user.isCashier) {
    userType = 'cashier';
  }

  return {
    user_type: userType,
    completed_trx: user.numbers?.completedOperations,
    user_verification_tier: String(user.securityHub?.tierLevel),
  };
};

const useHeapUserProperties = (jwt: string) => {
  const { passwordExpiry } = (JwtManager.currentUser ?? {}) as { passwordExpiry?: number };
  const [isHeapLoaded, setIsHeapLoaded] = useState(
    !!localStorage.getItem(LOCAL_STORAGE.HEAP_LOAD_TIMESTAMP),
  );

  const lastCompletedTransactionQuery = useQuery<LastCompletedTransactionQuery>(
    LAST_COMPLETED_TRANSACTION_QUERY,
    {
      fetchPolicy: 'cache-first',
      skip: !jwt || !isHeapLoaded || (passwordExpiry != null && passwordExpiry <= 0),
    },
  );
  const lastCompletedTransactionLoaded = !!lastCompletedTransactionQuery.data;
  const lastCompletedTransaction = lastCompletedTransactionQuery.data?.activity?.edges?.[0]?.node;

  const userQuery = useQuery<HeapUserQuery>(HEAP_USER_QUERY, {
    fetchPolicy: 'cache-first',
    skip: !jwt || !isHeapLoaded,
  });
  const userLoaded = !!userQuery.data?.me;
  const user = userQuery.data?.me;

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      const { key } = e;
      if (key === LOCAL_STORAGE.HEAP_LOAD_TIMESTAMP) {
        setIsHeapLoaded(true);
      }
    };
    window.addEventListener('storage', listener);

    return () => window.removeEventListener('storage', listener);
  }, []);

  useEffect(() => {
    if (isHeapLoaded && window.heap?.addUserProperties && lastCompletedTransactionLoaded && user) {
      const lastCompletedTransactionDate =
        lastCompletedTransaction && getLastCompletedTransactionDate(lastCompletedTransaction);
      const userProperties = getHeapUserProperties(user);

      window.heap.addUserProperties({
        app_version: __APP_VERSION__,
        last_completed_trx: lastCompletedTransactionDate,
        ...userProperties,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCompletedTransactionLoaded, userLoaded, isHeapLoaded]);
};

export default useHeapUserProperties;
