export default {
  setItem(key, value) {
    try {
      return Promise.resolve(sessionStorage.setItem(key, value));
    } catch (error) {
      if (['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(error.name)) {
        // Current length of the session storage. (this is a weird way to calculate the size of the storage)
        // but it is currently the most cross-browser one.
        const currentLength = unescape(encodeURIComponent(JSON.stringify(sessionStorage))).length;

        // Store limits for next load.
        localStorage.setItem('memoryCacheMaxSize', currentLength);

        console.info('quota error has been caught and cache size has been limited');
      }
    }
    return Promise.resolve(true);
  },
  getItem(key) {
    return Promise.resolve(sessionStorage.getItem(key));
  },
  removeItem(key) {
    return Promise.resolve(sessionStorage.removeItem(key));
  },
};
