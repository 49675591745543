/* eslint-disable import/prefer-default-export */
import { useMutation } from '@apollo/client';
import { AddNotification, AddMinimalNotification } from 'graphqlDocuments/mutations';
import { useTranslation } from 'react-i18next';

export const useToast = () => {
  const { t } = useTranslation(['LAYOUT', 'STATIC']);
  const [addNotification] = useMutation(AddNotification);
  const [addMinimalNotification] = useMutation(AddMinimalNotification);

  const notificationFromErr = (error) => {
    const { code, errorNumber, response } = error.graphQLErrors?.[0]?.extensions || error;
    const translationOptions = {
      defaultValue: error.message,
      ...response?.body?.data,
    };

    return {
      autoClose: true,
      errorCode: errorNumber,
      message: t([`ERRORS:${code}`, error.message], translationOptions),
      title: t([`ERRORS:${code}_TITLE`, 'ERRORS:SOMETHING_WENT_WRONG']),
      type: 'error',
    };
  };

  const toast = (notification) => {
    addNotification({ variables: { notification } });
  };

  const toastMinimal = (notification) => {
    addMinimalNotification({ variables: { notification } });
  };

  const toastErr = (error) => {
    const notification = notificationFromErr(error);
    toast(notification);
  };

  return { toast, toastMinimal, toastErr };
};
