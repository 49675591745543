/* eslint-disable import/prefer-default-export */
import { useQuery } from '@apollo/client';

export const useQueryFix = <Query = any, QueryVariables = any>(query: any, options: any) => {
  const { data, ...queryResult } = useQuery<Query, QueryVariables>(query, options);

  let newData = data;
  if (queryResult.error) {
    if (data === null) newData = undefined;
  } else if (!data) newData = queryResult.previousData;

  return {
    data: newData,
    ...queryResult,
  };
};
