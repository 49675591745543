import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import {
  EyeClosed,
  EyeOpened,
  IconButton,
  Button,
  Input,
  Alert,
  IconModal,
  IconCheckCircle,
} from '@airtm/airtm-ui';
import { Formik, Form } from 'formik';
import LoginImage from 'assets/images/login.jpg';
import { ConfirmPasswordReset } from 'graphqlDocuments/mutations';
import { GetPasswordResetCode } from 'graphqlDocuments/queries';
import {
  singleActionReducer,
  singleActionState,
  STARTED,
  FINISHED,
  ERRORED,
} from 'utils/hooks/singleAction';
import PasswordValidation from 'components/PasswordValidation';
import PasswordSafetyAlert from 'components/PasswordAlerts/PasswordSafetyAlert';
import RecoverPasswordFormSchema from './RecoverPasswordFormSchema';

const RecoverPassword = function ({ history }) {
  const { t } = useTranslation(['RECOVER_PASSWORD', 'STATIC', 'SIGNUP', 'ERRORS']);

  // We use the single action reducer because it's all we need for this UI.
  const [state, dispatch] = useReducer(singleActionReducer, singleActionState);
  const { working, error, finished } = state;
  const [showPassword, setShowPassword] = useState({
    password: false,
    passwordConfirmation: false,
  });
  const { code: errorCode } = error?.graphQLErrors?.[0]?.extensions ?? error ?? {};

  const params = querystring.parse(window.location.search);
  const code = params.code || '';

  const {
    loading,
    data,
    error: reqErr,
  } = useQuery(GetPasswordResetCode, {
    variables: { code },
  });

  async function onSubmit({ confirmPasswordReset, ...props }) {
    if (working || finished) {
      return;
    }
    try {
      dispatch({ type: STARTED });
      await confirmPasswordReset({ variables: { ...props } });
      dispatch({ type: FINISHED });
    } catch (err) {
      dispatch({ type: ERRORED, error: err });
    }
  }

  const toggleShowPassword = (field) =>
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });

  if (loading) return null;

  return (
    <div className="row col-12">
      <div className="col-lg-6">
        <img className="img-login-signup" src={LoginImage} alt="login" />
      </div>

      <div className="col-12 col-sm-10 col-md-8 col-lg-4 offset-sm-1 offset-md-2 offset-lg-1">
        <section className="section my-8 mt-sm-6 mt-md-5">
          <div className="section__heading">
            <h1>
              {t(reqErr ? 'RECOVER_PASSWORD:INVALID_CODE_TITLE' : 'RECOVER_PASSWORD:FORGOT_TITLE')}
            </h1>
          </div>
          <div className="section__content mt-6">
            {error && (
              <Alert
                variant="error"
                title={t([`ERRORS:${errorCode}_TITLE`, 'STATIC:SOMETHING_WENT_WRONG'])}
              >
                {t([`ERRORS:${errorCode}`, 'RECOVER_PASSWORD:FORGOT_RECOVER_ERROR'])}
              </Alert>
            )}
            {reqErr && (
              <>
                <h4 className="mb-5">{t('RECOVER_PASSWORD:INVALID_CODE_MESSAGE')}</h4>
                <Button block onClick={() => history.push('/login')}>
                  {t('RECOVER_PASSWORD:FORGOT_LOGIN')}
                </Button>
              </>
            )}
            {!reqErr && (
              <>
                <h4 className="mb-5">{t('RECOVER_PASSWORD:FORGOT_RESET')}</h4>
                <Mutation mutation={ConfirmPasswordReset}>
                  {(confirmPasswordReset) => (
                    <Formik
                      initialValues={{
                        password: '',
                        passwordConfirmation: '',
                        email: data?.passwordResetCode?.email,
                      }}
                      onSubmit={(values) =>
                        onSubmit({
                          code,
                          confirmPasswordReset,
                          ...values,
                        })
                      }
                      validationSchema={RecoverPasswordFormSchema({ t })}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                      }) => (
                        <Form>
                          {!finished && (
                            <>
                              <PasswordSafetyAlert />
                              <div className="form-group">
                                <div className="form-control-styled">
                                  <Input
                                    type={showPassword.password ? 'text' : 'password'}
                                    name="password"
                                    id="password"
                                    label={t('SIGNUP:CONFIRMATION_LABEL_PASSWORD')}
                                    placeholder={t('RECOVER_PASSWORD:FORGOT_NEW_PLACEHOLDER')}
                                    autoComplete="off"
                                    invalid={!!(touched.password && errors.password)}
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    addonRight={
                                      <IconButton
                                        icon={showPassword.password ? <EyeOpened /> : <EyeClosed />}
                                        size="sm"
                                        tabIndex="-1"
                                        variant="primary"
                                        onClick={() => toggleShowPassword('password')}
                                      />
                                    }
                                  />
                                </div>
                                {touched.password && errors.password && (
                                  <Input.HelperText invalid>{errors.password}</Input.HelperText>
                                )}
                                <PasswordValidation
                                  password={values.password}
                                  invalid={!!(touched.password && errors.password)}
                                />
                              </div>
                              <div className="form-group">
                                <div className="form-control-styled">
                                  <Input
                                    type={showPassword.passwordConfirmation ? 'text' : 'password'}
                                    name="passwordConfirmation"
                                    id="passwordConfirmation"
                                    label={t('SIGNUP:CONFIRMATION_LABEL_PASSWORD_CONFIRMATION')}
                                    placeholder={t(
                                      'RECOVER_PASSWORD:FORGOT_NEW_REPEAT_PLACEHOLDER',
                                    )}
                                    autoComplete="off"
                                    invalid={
                                      !!(
                                        touched.passwordConfirmation && errors.passwordConfirmation
                                      )
                                    }
                                    value={values.passwordConfirmation}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    addonRight={
                                      <IconButton
                                        icon={
                                          showPassword.passwordConfirmation ? (
                                            <EyeOpened />
                                          ) : (
                                            <EyeClosed />
                                          )
                                        }
                                        size="sm"
                                        tabIndex="-1"
                                        variant="primary"
                                        onClick={() => toggleShowPassword('passwordConfirmation')}
                                      />
                                    }
                                  />
                                </div>
                                {touched.passwordConfirmation && errors.passwordConfirmation && (
                                  <Input.HelperText invalid>
                                    {errors.passwordConfirmation}
                                  </Input.HelperText>
                                )}
                              </div>
                              <div className="form-group text--center text-md-left">
                                <Button
                                  variant="primary"
                                  type="submit"
                                  data-testid="reset-password-request"
                                  disabled={
                                    !(values.password && values.passwordConfirmation) ||
                                    isSubmitting
                                  }
                                  block
                                >
                                  {t('STATIC:CONTINUE')}
                                </Button>
                              </div>
                            </>
                          )}
                        </Form>
                      )}
                    />
                  )}
                </Mutation>
                <IconModal show={finished} modalClose={() => history.push('/login')}>
                  <IconModal.Body
                    icon={<IconCheckCircle />}
                    title={t('RECOVER_PASSWORD:FORGOT_PASSWORD_CHANGED_TITLE')}
                  >
                    <div className="text--lg">{t('RECOVER_PASSWORD:FORGOT_PASSWORD_CHANGED')}</div>
                  </IconModal.Body>
                  <IconModal.Actions>
                    <Button block onClick={() => history.push('/login')}>
                      {t('RECOVER_PASSWORD:FORGOT_LOGIN')}
                    </Button>
                  </IconModal.Actions>
                </IconModal>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

RecoverPassword.propTypes = {
  history: PropTypes.shape({ goBack: PropTypes.func.isRequired }).isRequired,
};

export default RecoverPassword;
