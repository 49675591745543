import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicHeader from 'screens/Layout/PublicHeader';
import getWarningMessage from 'utils/getWarningMessage';
import { loadScript } from 'utils';
import Login from 'screens/Login/Login';
import SignUp from 'screens/SignUp/SignUp';
import EmailSent from 'screens/SignUp/EmailSent/EmailSent';
import ForgotPassword from 'screens/ForgotPassword/ForgotPassword';
import RecoverPassword from 'screens/RecoverPassword/RecoverPassword';
import AppBanner from './AppBanner';

const FRESHCHAT_TOKEN = process.env.FRESHCHAT_TOKEN || '';

const SignupOrLoginContainer = function ({ skipAppBanner = false }) {
  const { i18n } = useTranslation();
  const [showAppBanner, setShowAppBanner] = useState(
    navigator.userAgent.match(/(Android|iPhone)/g) && !skipAppBanner,
  );
  const warningMessage = getWarningMessage('');

  React.useEffect(() => {
    (async () => {
      try {
        if (
          FRESHCHAT_TOKEN &&
          !navigator.userAgent.match(/(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/g)
        ) {
          await loadScript(
            'https://wchat.freshchat.com/js/widget.js',
            () => window.fcWidget !== undefined,
            { async: true },
          );

          if (!window.fcWidget.isInitialized()) {
            window.fcWidget.init({
              token: FRESHCHAT_TOKEN,
              host: 'https://wchat.freshchat.com',
              locale: i18n.language,
            });
          }
        }
      } catch (error) {
        console.warn('Error loading freshchat widget', { error: error.message || null });
      }
    })();
  }, []);

  if (showAppBanner) {
    return <AppBanner onClose={() => setShowAppBanner(false)} />;
  }

  return (
    <>
      <PublicHeader />
      {warningMessage && <span className="data-warning" data-warning={warningMessage} />}
      <div className="content content--no-navigation">
        <div className="u-ovh">
          <div className="row justify-content-center">
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/sign-up/sent" exact component={EmailSent} />
              <Route path="/sign-up" component={SignUp} />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/recover-password" exact component={RecoverPassword} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupOrLoginContainer;
