import debounce from 'lodash.debounce';

let listeners = [];

const handleInteraction = debounce(() => {
  listeners.forEach((listener) => {
    listener();
  });
}, 1000);

// events to track
const eventsToListen = ['mousedown', 'mousemove', 'keydown', 'touchstart', 'scroll'];

const startInteractionListener = () => {
  eventsToListen.forEach((eventName) => {
    document.addEventListener(eventName, handleInteraction);
  });
};

const stopInteractionListener = () => {
  listeners = [];
  eventsToListen.forEach((eventName) => {
    document.removeEventListener(eventName, handleInteraction);
  });
};

export const addInteractionListener = (listener) => {
  if (listeners.indexOf(listener) === -1) {
    listeners.push(listener);
    if (listeners.length === 1) {
      startInteractionListener();
    }
  }
};

export const removeInteractionListener = (listener) => {
  const idx = listeners.indexOf(listener);
  if (idx !== -1) listeners.splice(idx, 1);
  if (listeners.length === 0) {
    stopInteractionListener();
  }
};
