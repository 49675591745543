import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Alert, MinimalNotificationsContainer } from '@airtm/airtm-ui';
import { useMutation, useReactiveVar } from '@apollo/client';
import { minimalNotificationsVar } from 'lib/apollo/clientSchema/MinimalNotification';
import REMOVE_MINIMAL_NOTIFICATION_MUTATION from './RemoveMinimalNotificationMutation.graphql';
import {
  RemoveMinimalNotificationMutation,
  RemoveMinimalNotificationMutationVariables,
} from './__generated__/RemoveMinimalNotificationMutation';

type RemoveMinimalNotificationFn = ({
  variables,
}: {
  variables: RemoveMinimalNotificationMutationVariables;
}) => void;

const FADE_IN_DURATION = 500; // Duration for the fade-in transition
const IDLE_DURATION = 1000; // Duration for the idle state
const FADE_OUT_DURATION = 300; // Duration for the fade-out transition

const TOTAL_DURATION = FADE_IN_DURATION + IDLE_DURATION + FADE_OUT_DURATION;
const FADE_IN_AND_IDLE_DURATION = FADE_IN_DURATION + IDLE_DURATION;

const Notification = function ({
  notification,
  removeNotification,
}: {
  notification: { id: string; message: string };
  removeNotification: RemoveMinimalNotificationFn;
}) {
  const [isClosing, setIsClosing] = useState(false);

  const { id, message } = notification;

  useEffect(() => {
    const removeNotificationTimeoutId = setTimeout(
      () => removeNotification({ variables: { id } }),
      TOTAL_DURATION,
    );

    const setIsClosingTimeoutId = setTimeout(() => setIsClosing(true), FADE_IN_AND_IDLE_DURATION);

    return () => {
      clearTimeout(removeNotificationTimeoutId);
      clearTimeout(setIsClosingTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Alert
      variant="minimal"
      hideIcon
      className={cx('fade-in', {
        'collapse-leave-active': isClosing,
      })}
    >
      {message}
    </Alert>
  );
};

const MinimalNotifications = function () {
  const notifications = useReactiveVar(minimalNotificationsVar);

  const [removeNotification] = useMutation<
    RemoveMinimalNotificationMutation,
    RemoveMinimalNotificationMutationVariables
  >(REMOVE_MINIMAL_NOTIFICATION_MUTATION);

  if (!notifications || notifications.length === 0) {
    return null;
  }

  const firstNotification = notifications[0];

  return (
    <MinimalNotificationsContainer>
      {firstNotification && (
        <Notification
          key={firstNotification.id}
          notification={firstNotification}
          removeNotification={removeNotification}
        />
      )}
    </MinimalNotificationsContainer>
  );
};

export default MinimalNotifications;
