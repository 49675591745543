import React from 'react';
import * as Sentry from '@sentry/react';
import { RouteComponentProps } from 'react-router-dom';
import { Trans, TFunction } from 'react-i18next';
import ONIX_ERROR_CODES from '@airtm/utils/dist/errorCodes/onix';
import DODRIO_ERROR_CODES from '@airtm/utils/dist/errorCodes/dodrio';
import CHANSEY_ERROR_CODES from '@airtm/utils/dist/errorCodes/chansey';
import { signupSources as SIGNUP_PLATFORM_SOURCE } from '@airtm/utils/dist/constants';
import { ApolloError } from '@apollo/client';
import { trackCustomEvent } from 'lib/gtag4/gtag4';
import JwtManager from 'lib/JwtManager';
import formatDate from 'utils/formatDate';
import { Login, Login_login, LoginVariables } from 'graphqlDocuments/mutations/__generated__/Login';
import {
  AppleLogin,
  AppleLogin_appleLogin,
  AppleLoginVariables,
} from 'graphqlDocuments/mutations/__generated__/AppleLogin';
import {
  FacebookLogin,
  FacebookLogin_facebookLogin,
  FacebookLoginVariables,
} from 'graphqlDocuments/mutations/__generated__/FacebookLogin';
import {
  GoogleLogin,
  GoogleLogin_googleLogin,
  GoogleLoginVariables,
} from 'graphqlDocuments/mutations/__generated__/GoogleLogin';
import { NotificationInput } from 'types/__generated__/graphql-global-types';
import { GeetestSolution } from 'components/Captcha/Captcha.types';

type LoginMutation = (variables: LoginVariables) => Promise<{ data: Login }>;

type AppleLoginMutation = (variables: AppleLoginVariables) => Promise<{ data: AppleLogin }>;

type FacebookLoginMutation = (
  variables: FacebookLoginVariables,
) => Promise<{ data: FacebookLogin }>;

type GoogleLoginMutation = (variables: GoogleLoginVariables) => Promise<{ data: GoogleLogin }>;

export type RecoverMfaUser = { email: string | null; phone: string | null };

export type RecoverMfaErrors = {
  emailCode?: string[];
  phoneCode?: string[];
};

type LoginClass = {
  props: {
    t: TFunction<['LOGIN', 'RECOVER_PASSWORD', 'SIGNUP']>;
    toast: (input: NotificationInput) => void;
    toastErr: (error: Error | ApolloError) => void;
  } & RouteComponentProps;
  setState: (state: {
    error?: string | JSX.Element | null;
    loginErrorData?: {
      time?: string;
      count?: number;
    } | null;
    showKountInReview?: boolean;
    kountIp?: string;
    recoverMfaUser?: RecoverMfaUser;
    recoverMfaErrors?: RecoverMfaErrors;
    recoverMfaIsSendSuccessful?: boolean;
    mfaMethod?: string | null;
    submitting?: boolean;
  }) => void;
  maybeRedirectTo: () => void;
};

export const LOGIN_ERRORS = {
  INVALID_CREDENTIALS: 'LOGIN:INVALID_CREDENTIALS',
  INVALID_CREDENTIALS_WITH_COUNTER: 'LOGIN:INVALID_CREDENTIALS_WITH_COUNTER',
  INVALID_2FA_CODE: 'LOGIN:INVALID_2FA_CODE',
  BLOCKED_ACCOUNT: 'LOGIN:BLOCKED_ACCOUNT',
  UNEXPECTED: 'LOGIN:UNEXPECTED_ERROR',
  EXISTING_SESSION_FOUND: 'LOGIN:EXISTING_SESSION_FOUND',
  ATTEMPTS_LIMIT_REACHED: 'LOGIN:MAX_LOGIN_ATTEMPTS_REACHED',
  INVALID_KOUNT_SESSIONID: 'LOGIN:INVALID_KOUNT_SESSIONID',
  KOUNT_CONTROL_INVALID_REQUEST: 'LOGIN:INVALID_KOUNT_ACCESS_REQUEST',
  KOUNT_LOGIN_BLOCKED: 'LOGIN:KOUNT_ACCESS_DENIED',
  KOUNT_CONTROL_SERVER_ERROR: 'LOGIN:KOUNT_ACCESS_SERVER_ERROR',
  NETWORK_CONNECTION_ERROR: 'LOGIN:NETWORK_CONNECTION_ERROR',
  INVALID_PLATFORM_SOURCE: 'LOGIN:INVALID_PLATFORM_SOURCE',
};

export type ConditionalLoginVariables = {
  credentials: { email: string; password: string };
  appleAuthData: { appleResponse: any };
  facebookAuthData: { fbResponse: any };
  googleAuthData: { idToken: any };
};

export type MfaData = {
  isMfaReset?: boolean;
  code?: string;
  emailCode?: string;
  phoneCode?: string;
};

export async function conditionalLogin({
  loginVariables,
  utmData,
  mfaData,
  login,
  appleLogin,
  facebookLogin,
  googleLogin,
  captcha,
}: {
  loginVariables: ConditionalLoginVariables;
  utmData: any;
  mfaData: MfaData;
  login: LoginMutation;
  appleLogin: AppleLoginMutation;
  facebookLogin: FacebookLoginMutation;
  googleLogin: GoogleLoginMutation;
  captcha?: GeetestSolution;
}) {
  const { credentials, appleAuthData, facebookAuthData, googleAuthData } = loginVariables;

  let data;

  if (credentials) {
    const mutation = await login({
      ...credentials,
      ...mfaData,
      metadata: {
        ...utmData,
        oauth_source: SIGNUP_PLATFORM_SOURCE.INTERNAL,
      },
      captcha,
    });
    data = mutation.data?.login;
  } else if (appleAuthData) {
    const mutation = await appleLogin({
      params: {
        appleResponse: appleAuthData.appleResponse,
        metadata: {
          ...utmData,
          oauth_source: SIGNUP_PLATFORM_SOURCE.APPLE,
        },
      },
      ...mfaData,
    });
    data = mutation.data?.appleLogin;
  } else if (facebookAuthData) {
    const mutation = await facebookLogin({
      params: {
        fbResponse: facebookAuthData.fbResponse,
        metadata: {
          ...utmData,
          oauth_source: SIGNUP_PLATFORM_SOURCE.FACEBOOK,
        },
      },
      ...mfaData,
    });
    data = mutation.data?.facebookLogin;
  } else if (googleAuthData) {
    const mutation = await googleLogin({
      params: {
        idToken: googleAuthData.idToken,
        metadata: {
          ...utmData,
          oauth_source: SIGNUP_PLATFORM_SOURCE.GOOGLE,
        },
      },
      ...mfaData,
    });
    data = mutation.data?.googleLogin;
  } else {
    throw new Error('No authentication method selected');
  }

  return data;
}

function handleLoginNetworkError(loginClass: LoginClass, error: any) {
  const { t } = loginClass.props;

  if (error.networkError.statusCode === undefined) {
    loginClass.setState({
      error: (
        <Trans
          components={[
            <a href={t('LOGIN:AIRTM_FAQ_LINK')} target="_blank" rel="noreferrer noopener" key="faq">
              Airtm FAQ
            </a>,
          ]}
          i18nKey={LOGIN_ERRORS.NETWORK_CONNECTION_ERROR}
        />
      ),
    });
  } else {
    throw error.networkError;
  }
}

function handleLogin422Error(loginClass: LoginClass, error: ApolloError) {
  const { t, history, toast } = loginClass.props;
  const {
    extensions: {
      response: {
        body: { messages },
      },
    },
  } = error.graphQLErrors[0];

  if (messages?.oauthSource) {
    loginClass.setState({
      error: LOGIN_ERRORS.INVALID_PLATFORM_SOURCE,
      loginErrorData: null,
    });
  } else {
    toast({
      title: t('LOGIN:NOTIFICATION_SIGNUP_OAUTH_TITLE'),
      message: t('LOGIN:NOTIFICATION_SIGNUP_OAUTH_MESSAGE'),
      autoClose: false,
      type: 'informative',
    });
    history.replace('/sign-up');
  }
}

function handleLoginNonCorsolaError(loginClass: LoginClass, error: ApolloError) {
  const { toastErr, t } = loginClass.props;
  const {
    extensions: { errorNumber, remainingTries, response },
  } = error.graphQLErrors[0];

  let customEventName = '';
  let errorMessage;
  let loginErrorData = null;

  switch (errorNumber) {
    case ONIX_ERROR_CODES.INVALID_CREDENTIALS.code: {
      customEventName = 'INVALID_CREDENTIALS';
      if (typeof remainingTries === 'number') {
        errorMessage = LOGIN_ERRORS.INVALID_CREDENTIALS_WITH_COUNTER;
        loginErrorData = { count: remainingTries };
      } else {
        errorMessage = LOGIN_ERRORS.INVALID_CREDENTIALS;
      }
      break;
    }
    case ONIX_ERROR_CODES.DELETED_ACCOUNT.code:
      customEventName = 'DELETED_ACCOUNT';
      if (typeof remainingTries === 'number') {
        errorMessage = LOGIN_ERRORS.INVALID_CREDENTIALS_WITH_COUNTER;
        loginErrorData = { count: remainingTries };
      } else {
        errorMessage = LOGIN_ERRORS.INVALID_CREDENTIALS;
      }
      break;
    case ONIX_ERROR_CODES.INVALID_MFA.code:
      customEventName = 'INVALID_MFA';
      errorMessage = LOGIN_ERRORS.INVALID_2FA_CODE;
      break;
    case ONIX_ERROR_CODES.BANNED_ACCOUNT.code:
      customEventName = 'BANNED_ACCOUNT';
      errorMessage = LOGIN_ERRORS.BLOCKED_ACCOUNT;
      break;
    case ONIX_ERROR_CODES.UNUSABLE_ACCOUNT.code:
      customEventName = 'UNUSABLE_ACCOUNT';
      errorMessage = '';
      break;
    case ONIX_ERROR_CODES.SESSION_ALREADY_ACTIVE.code:
      customEventName = 'SESSION_ALREADY_ACTIVE';
      errorMessage = LOGIN_ERRORS.EXISTING_SESSION_FOUND;
      break;
    case ONIX_ERROR_CODES.KOUNT_DEVICE_NOT_TRUSTED.code:
      loginClass.setState({
        showKountInReview: true,
      });
      // Kount In Review screen is shown
      return;

    case DODRIO_ERROR_CODES.MFA_RECOVERY_CODE_NOT_FOUND.code:
    case DODRIO_ERROR_CODES.MFA_RECOVERY_CODE_EXPIRED.code:
    case CHANSEY_ERROR_CODES.TOO_MANY_ATTEMPTS.code:
    case CHANSEY_ERROR_CODES.TOO_MANY_CONSECUTIVE_ATTEMPTS.code: {
      toastErr(error);
      return;
    }

    case DODRIO_ERROR_CODES.MFA_RECOVERY_INVALID_CODES.code: {
      const { isEmailCodeValid, isPhoneCodeValid } = response?.body?.data ?? {};

      const recoverMfaErrors: RecoverMfaErrors = {};

      if (isEmailCodeValid === false) {
        recoverMfaErrors.emailCode = [t('RECOVER_PASSWORD:INVALID_MAIL_CODE')];
      }

      if (isPhoneCodeValid === false) {
        recoverMfaErrors.phoneCode = [t('RECOVER_PASSWORD:INVALID_PHONE_CODE')];
      }

      loginClass.setState({
        recoverMfaErrors,
      });
      return;
    }

    default:
      customEventName = 'UNEXPECTED';
      errorMessage = LOGIN_ERRORS.UNEXPECTED;
      break;
  }

  trackCustomEvent(`error__login__${customEventName.toLowerCase()}`);

  loginClass.setState({
    error: errorMessage,
    loginErrorData,
  });
}

function handleLoginCorsolaError(loginClass: LoginClass, error: ApolloError) {
  const graphQLError = error.graphQLErrors[0];
  const {
    extensions: { code, clearTime },
  } = graphQLError;

  trackCustomEvent(`error__login__${code.toLowerCase()}`, {});

  switch (code) {
    case 'GEETEST_CHALLENGE':
      // Don't show error
      break;
    case 'LOGIN_LIMIT_REACHED_ERROR':
      loginClass.setState({
        error: 'LOGIN:MAX_LOGIN_ATTEMPTS_REACHED',
        loginErrorData: {
          time: formatDate(new Date(clearTime), {
            showTime: true,
            showDate: false,
            showSeconds: false,
          }),
        },
      });
      break;
    case 'KOUNT_CONTROL_INVALID_REQUEST':
      loginClass.setState({
        error: LOGIN_ERRORS.KOUNT_CONTROL_INVALID_REQUEST,
      });
      break;
    case 'KOUNT_LOGIN_BLOCKED':
      loginClass.setState({
        error: LOGIN_ERRORS.KOUNT_LOGIN_BLOCKED,
      });
      break;
    case 'KOUNT_DEVICE_NOT_TRUSTED':
      loginClass.setState({
        showKountInReview: true,
        kountIp: graphQLError.message,
      });
      break;
    case 'KOUNT_CONTROL_SERVER_ERROR':
      loginClass.setState({
        error: LOGIN_ERRORS.KOUNT_CONTROL_SERVER_ERROR,
      });
      break;
    case 'CLOUDFRONT_504':
      loginClass.setState({
        error: LOGIN_ERRORS.UNEXPECTED,
      });
      break;
    case 'RATE_LIMIT_ERROR':
      // do nothing, toast is shown
      break;
    default:
      loginClass.setState({
        error: code,
      });
      break;
  }
}

function handleLoginApolloError(loginClass: LoginClass, error: ApolloError) {
  const {
    extensions: { code, errorNumber, response },
  } = error.graphQLErrors[0];

  const isNonCorsolaError = errorNumber && !errorNumber.startsWith('222');

  if (response?.status === 422) {
    handleLogin422Error(loginClass, error);
  } else if (isNonCorsolaError) {
    handleLoginNonCorsolaError(loginClass, error);
  } else if (code) {
    handleLoginCorsolaError(loginClass, error);
  } else {
    throw error;
  }
}

export function handleLoginError(loginClass: LoginClass, error: any) {
  try {
    if (error.networkError) {
      handleLoginNetworkError(loginClass, error);
    } else if (error.graphQLErrors) {
      handleLoginApolloError(loginClass, error);
    } else {
      throw error;
    }
  } catch (unexpectedError: any) {
    trackCustomEvent('error__login__unhandled', {
      message: unexpectedError.message || '',
    });
    Sentry.captureException(unexpectedError);
    loginClass.setState({ error: LOGIN_ERRORS.UNEXPECTED });
  }
}

export function handleLoginSuccess(
  loginClass: LoginClass,
  loginData:
    | Login_login
    | AppleLogin_appleLogin
    | FacebookLogin_facebookLogin
    | GoogleLogin_googleLogin
    | null,
  mfaData?: MfaData,
) {
  const { t, toast } = loginClass.props;
  const { challengeMethod, authenticationResponse, email, phone } =
    loginData ?? ({} as Record<string, undefined>);
  const jwt = authenticationResponse?.jwt;

  loginClass.setState({ submitting: false, mfaMethod: null });

  if (mfaData?.isMfaReset) {
    loginClass.setState({
      recoverMfaUser: {
        email: email ?? null,
        phone: phone ?? null,
      },
      recoverMfaIsSendSuccessful: true,
    });

    return;
  }

  if (challengeMethod && !mfaData) {
    loginClass.setState({ mfaMethod: challengeMethod });

    return;
  }

  if (jwt) {
    // Persist session token in manager.
    JwtManager.jwt = jwt;

    loginClass.maybeRedirectTo();

    return;
  }

  toast({
    title: t('SIGNUP:ERROR_USER_REGISTERED'),
    autoClose: false,
    message: '',
    type: 'warning',
  });
}
