export default (number, currencyCode, currencySymbol, precision = 2) => {
  let maximumFractionDigits = 2;
  const minimumFractionDigits = 2;

  if (!Number.isNaN(parseInt(precision, 10))) {
    maximumFractionDigits = Math.min(precision, 8);
  }

  // We use the browser's configured locale to format the numbers
  const formattedNumber = new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: Math.min(minimumFractionDigits, maximumFractionDigits),
    maximumFractionDigits,
  }).format(number);

  if (currencySymbol && currencyCode) {
    return `${currencySymbol}${formattedNumber} ${currencyCode}`;
  }

  if (currencySymbol) {
    return `${currencySymbol}${formattedNumber}`;
  }

  if (currencyCode) {
    return `${formattedNumber} ${currencyCode}`;
  }

  return formattedNumber;
};
