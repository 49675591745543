import React from 'react';
import { useToast } from 'utils/hooks/useToast';

function withToast(Component) {
  const WithToast = function (props) {
    const { toast, toastErr } = useToast();
    return <Component {...props} toast={toast} toastErr={toastErr} />;
  };

  return WithToast;
}

export default withToast;
