import { useLazyQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EnabledCountries } from 'graphqlDocuments/queries';

export default function useCountries() {
  const { t, i18n } = useTranslation(['COUNTRIES']);
  const [options, setOptions] = useState([]);

  const [fetchCountries] = useLazyQuery(EnabledCountries, { fetchPolicy: 'cache-first' });

  function sorted(list) {
    return list
      .map((a) => ({ label: t(a.translationTag), value: a.id, ...a }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  // Let's sync our list and post process it only once.
  useEffect(() => {
    // Fire the query and then sync state.
    (async () => {
      const { data, errors } = await fetchCountries();
      if (errors && errors.length) {
        console.warn('could not load countries, empty set', errors);
        return;
      }
      if (data && Array.isArray(data.countries) && data.countries?.length > 0) {
        setOptions(sorted(data.countries));
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return [options, setOptions];
}
