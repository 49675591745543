import React from 'react';
import * as Sentry from '@sentry/react';
import WITH_USER from 'graphqlDocuments/queries/WithUser.graphql';
import { useQueryFix } from 'utils/hooks/useQueryFix';

import JwtManager from 'lib/JwtManager';

export const UserContext = React.createContext(false);

const withUser = (Component) => {
  const WithUser = function (props) {
    const { data, loading, error, refetch } = useQueryFix(WITH_USER, {
      fetchPolicy: 'cache-and-network',
      skip: !JwtManager.jwt,
    });

    const user = data?.me;
    
    if (user) {
      Sentry.setUser({ email: user.email, id: user.id });
    }

    if (error) {
      Sentry.addBreadcrumb({
        category: 'WithUser',
        level: Sentry.Severity.Error,
        message: 'Failed to fetch user',
        data: {
          error: JSON.stringify(error, null, 2),
        },
      });
    }

    if (!JwtManager.jwt) {
      return (
        <Component
          {...props}
          error={null}
          errorUser={null}
          loading={false}
          loadingUser={false}
          user={null}
        />
      );
    }

    return (
      <UserContext.Provider value={user}>
        <Component
          {...props}
          error={error}
          errorUser={error}
          loading={loading}
          loadingUser={loading}
          refetchUser={refetch}
          user={user}
        />
      </UserContext.Provider>
    );
  };
  WithUser.displayName = `WithUser(${Component.displayName || Component.name || 'Component'})`;

  return WithUser;
};

export default withUser;
