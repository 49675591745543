/* eslint-disable no-console */
export const logInfo = (...msg) => {
  console.info(...msg);
};

export const logWarn = (...msg) => {
  console.warn(...msg);
};

export const logError = (...msg) => {
  console.error(...msg);
};
