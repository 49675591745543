// @flow
import { DEFAULT_LANGUAGE, LOCALES } from '@constants';
import { format, utcToZonedTime } from 'date-fns-tz';

type Options = {
  timezone?: string,
  language?: string,
  showTimezone?: boolean,
  showDate?: boolean,
  showTime?: boolean,
  showSeconds?: boolean,
  format?: string,
};

const getFormatString = ({
  showDate = true,
  includeDateComma = true,
  showTime = true,
  showSeconds = true,
  showTimezone = false,
  language = DEFAULT_LANGUAGE,
}) => {
  if (showDate && showTime) {
    if (language === 'pt') {
      return `d MMM yyyy H:mm${showSeconds ? ':ss' : ''}${showTimezone ? ' zzz' : ''}`;
    }
    return `d MMM yyyy h:mm${showSeconds ? ':ss' : ''} ${language === 'es' ? 'aaa' : 'aa'}${
      showTimezone ? ' zzz' : ''
    }`;
  }

  if (showDate) {
    return includeDateComma ? 'MMM d, yyyy' : 'MMM d yyyy';
  }

  if (showTime) {
    if (language === 'pt') {
      return `H:mm${showSeconds ? ':ss' : ''}${showTimezone ? ' zzz' : ''}`;
    }
    return `h:mm${showSeconds ? ':ss' : ''} ${language === 'es' ? 'aaa' : 'aa'}${
      showTimezone ? ' zzz' : ''
    }`;
  }

  return '';
};

export default (date: string | Date, options?: Options = {}) => {
  if (!date) return '';

  const formatStr = options?.format || getFormatString(options);

  if (options.timezone) {
    // Capitalize here instead of everywhere this is called. Avoiding editing the locale
    return format(utcToZonedTime(new Date(date), options.timezone), formatStr, {
      timeZone: options.timezone,
      locale: LOCALES[options.language || DEFAULT_LANGUAGE],
    }).replace(/[a-zA-Z]/, (l) => (options.showDate !== false ? l.toUpperCase() : l));
  }

  return format(new Date(date), formatStr, {
    locale: LOCALES[options.language || DEFAULT_LANGUAGE],
  });
};
