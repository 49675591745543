import React from 'react';
import { Alert } from '@airtm/airtm-ui';
import { useTranslation } from 'react-i18next';

const PasswordSafetyAlert = function () {
  const { t } = useTranslation('SETTINGS');
  return (
    <Alert variant="warning" title={t('PASSWORD_PROTECT_ACCOUNT')}>
      {t('PASSWORD_PROTECT_ACCOUNT_BODY')}
    </Alert>
  );
};

export default PasswordSafetyAlert;
