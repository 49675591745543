import { useEffect, useState } from 'react';
import loadScript from 'utils/loadScript';

function useGoogleApi() {
  const [loading, setLoading] = useState(true);
  const [gapi, setGapi] = useState(null);

  useEffect(() => {
    let unmounted = false;

    const loadGoogle = async () => {
      try {
        await loadScript(
          'https://accounts.google.com/gsi/client',
          () => window.google?.accounts !== undefined,
        );

        if (!unmounted) setGapi(window.google);
      } catch (error) {
        console.warn('Error loading google api', { error: error.message || null });
      } finally {
        if (!unmounted) setLoading(false);
      }
    };
    loadGoogle();
    return () => {
      unmounted = true;
    };
  }, []);

  return [gapi, loading];
}

export default useGoogleApi;
