import { LOCAL_STORAGE } from '@constants';

export default function getWarningMessage(userEmail) {
  const env = process.env.NODE_ENV || 'development';
  const disabled = Boolean(process.env.DISABLE_WARNING);
  const language = localStorage.getItem(LOCAL_STORAGE.LANGUAGE) || '';

  if (env !== 'production' && !disabled) {
    switch (language) {
      case 'es':
        return `ADVERTENCIA: ESTA ES UNA PÁGINA DE PRUEBA. EL DINERO ES FALSO. [${window.location.host}] ${userEmail}`;
      case 'pt':
        return `AVISO: ESTA É UMA PÁGINA DE TESTE. O DINHEIRO É FALSO. [${window.location.host}] ${userEmail}`;
      case 'en':
      default:
        return `WARNING: THIS IS A TESTING SITE. THE MONEY IS FAKE. [${window.location.host}] ${userEmail}`;
    }
  }

  return null;
}
