import * as yup from 'yup';
import {
  email,
  firstName,
  lastName,
  lastNameSecond,
  password,
  passwordConfirmation,
} from 'utils/schemaUtils';

export default yup.object({
  email,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  secondLastName: lastNameSecond,
});
