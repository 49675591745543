import * as yup from 'yup';
import {
  AIRTM_MIN_AGE,
  AIRTM_REGEX,
  CONSECUTIVE_CHARS_REGEX,
  PASSWORD_LENGTH_REGEX,
  PASSWORD_LETTER_REGEX,
  PASSWORD_NUMBER_REGEX,
  PASSWORD_SPECIAL_CHARS_REGEX,
  PHONE_COUNTRY_CODE_REGEX,
  PHONE_REGEX,
  POSTCODE_GBR_REGEX,
  REGEX_FORM_FIELDS,
  REGEX_USERNAME,
  SEQUENTIAL_CHARS_REGEX,
} from '@airtm/utils/dist/constants';
import { BUSINESS, INDIVIDUAL } from '@airtm/utils/dist/constants/accountTypes';
import { subYears } from 'date-fns';
import i18n from 'i18next';

export const addressLine1 = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_ADDRESS_LINE_1'),
    }),
  )
  .max(60, () =>
    i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_ADDRESS_LINE_1'),
      max: 60,
    }),
  )
  .matches(REGEX_FORM_FIELDS.REGEX_POSTAL_ADDRESS, {
    message: () => i18n.t('SIGNUP:VALIDATION_ADDRESS'),
  });

export const addressLine2 = yup
  .string()
  .notRequired()
  .max(60, () =>
    i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_ADDRESS_LINE_2'),
      max: 60,
    }),
  )
  .matches(REGEX_FORM_FIELDS.REGEX_POSTAL_ADDRESS, {
    message: () => i18n.t('SIGNUP:VALIDATION_ADDRESS'),
    excludeEmptyString: true,
  });

export const birthDate = yup
  .date()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_BIRTH_DATE') }),
  )
  .when('accountType', {
    is: INDIVIDUAL,
    then: yup
      .date()
      .max(subYears(new Date(), AIRTM_MIN_AGE), () =>
        i18n.t('SIGNUP:VALIDATION_MINIMUM_AGE', { minimumAge: AIRTM_MIN_AGE }),
      ),
  });

export const city = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_CITY') }),
  )
  .max(30, () =>
    i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_CITY'),
      max: 30,
    }),
  )
  .matches(REGEX_FORM_FIELDS.REGEX_POSTAL_ADDRESS, {
    message: () => i18n.t('SIGNUP:VALIDATION_ADDRESS'),
  });

export const country = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_COUNTRY') }),
  )
  .test({
    name: 'argentian-business-validation',
    message: () => i18n.t('SIGNUP:ARG_VALIDATION_TITLE'),
    test(value) {
      const accountType = this.resolve(yup.ref('accountType'));
      return value !== 'ARG' || accountType !== BUSINESS;
    },
  });

export const getEmailSchema = (fieldName = 'SIGNUP:REQUEST_LABEL_EMAIL') =>
  yup
    .string()
    .required(() => i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t(fieldName) }))
    .matches(REGEX_FORM_FIELDS.REGEX_EMAIL, () =>
      i18n.t('ERRORS:FORM_REGEX_EMAIL', { name: i18n.t(fieldName) }),
    );

export const email = getEmailSchema();

export const firstName = yup.string().when('accountType', {
  is: BUSINESS,
  then: yup
    .string()
    .required(() =>
      i18n.t('ERRORS:FORM_REQUIRED', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_BUSINESS_NAME'),
      }),
    )
    .min(2, () =>
      i18n.t('ERRORS:FORM_MIN_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_BUSINESS_NAME'),
        min: 2,
      }),
    )
    .max(250, () =>
      i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_BUSINESS_NAME'),
        max: 250,
      }),
    )
    .matches(REGEX_FORM_FIELDS.REGEX_BUSINESS_NAME, () =>
      i18n.t('ERRORS:FORM_REGEX_BUSINESS_NAME', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_BUSINESS_NAME'),
      }),
    ),
  otherwise: yup
    .string()
    .required(() =>
      i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_FIRST_NAME') }),
    )
    .matches(REGEX_FORM_FIELDS.REGEX_NAME, () =>
      i18n.t('ERRORS:FORM_REGEX_NAME', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_FIRST_NAME'),
      }),
    )
    .max(250, () =>
      i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_FIRST_NAME'),
        max: 250,
      }),
    ),
});

export const lastName = yup.string().when('accountType', {
  is: BUSINESS,
  then: yup
    .string()
    .required(() =>
      i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_ENTITY_NAME') }),
    )
    .max(250, () =>
      i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_ENTITY_NAME'),
        max: 250,
      }),
    ),
  otherwise: yup
    .string()
    .required(() =>
      i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_LAST_NAME') }),
    )
    .matches(REGEX_FORM_FIELDS.REGEX_NAME, () =>
      i18n.t('ERRORS:FORM_REGEX_NAME', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_LAST_NAME') }),
    )
    .max(250, () =>
      i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_LAST_NAME'),
        max: 250,
      }),
    ),
});

export const lastNameSecond = yup.string().when('accountType', {
  is: BUSINESS,
  then: yup.string().notRequired(),
  otherwise: yup
    .string()
    .notRequired()
    .matches(REGEX_FORM_FIELDS.REGEX_NAME, () =>
      i18n.t('ERRORS:FORM_REGEX_NAME', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_SECOND_LAST_NAME'),
      }),
    )
    .max(250, () =>
      i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
        name: i18n.t('SIGNUP:CONFIRMATION_LABEL_SECOND_LAST_NAME'),
        max: 250,
      }),
    ),
});

export const phone = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PHONE') }),
  )
  .test({
    name: 'phone-by-country-validation',
    test(value, ctx) {
      const countryPhoneRegex = ctx.resolve(yup.ref('countryPhoneRegex'));
      let isValid = null;
      let defaultValues = { min: 6, max: 15 };

      if (countryPhoneRegex) {
        const phoneRegex = new RegExp(countryPhoneRegex);
        const limits = phoneRegex.toString().match(/\d+/g) ?? [];
        defaultValues =
          limits.length === 1
            ? { number: limits[0] }
            : {
                min: limits[0],
                max: limits[1],
              };
        isValid = value?.match(phoneRegex);
      } else {
        isValid = value?.match(PHONE_REGEX);
      }

      const exactPhoneLengthText = Object.keys(defaultValues).length === 1 ? '_EXACT_LENGTH' : '';

      if (!isValid) {
        return ctx.createError({
          message: i18n.t(`ERRORS:FORM_REGEX_PHONE${exactPhoneLengthText}`, {
            name: i18n.t('FORM_FIELDS:PHONE_NUMBER'),
            ...defaultValues,
          }),
        });
      }

      return {};
    },
  });

export const phoneCountryCode = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PHONE_COUNTRY_CODE'),
    }),
  )
  .matches(PHONE_COUNTRY_CODE_REGEX);

export const postalCode = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_POSTAL_CODE') }),
  )
  .test({
    name: 'postcode-validation',
    message: () => i18n.t('ERRORS:FORM_REGEX_POSTCODE'),
    test(value) {
      const whichCountry = this.resolve(yup.ref('country'));
      return whichCountry === 'GBR'
        ? value?.match(POSTCODE_GBR_REGEX)
        : value?.match(REGEX_FORM_FIELDS.REGEX_POSTAL_ADDRESS);
    },
  });

export const referrer = yup
  .string()
  .notRequired()
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_USERNAME'),
    name: 'valid-referrer',
    test(value) {
      if (!value) return true;
      return (
        value.match(REGEX_FORM_FIELDS.REGEX_EMAIL) ||
        (value.match(REGEX_USERNAME) && !value.includes('airtm'))
      );
    },
  });

export const state = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_STATE') }),
  )
  .max(250, () =>
    i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_STATE'),
      max: 250,
    }),
  )
  .matches(REGEX_FORM_FIELDS.REGEX_POSTAL_ADDRESS, {
    message: () => i18n.t('SIGNUP:VALIDATION_ADDRESS'),
  });

export const password = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PASSWORD') }),
  )
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_REGEX'),
    name: 'password-regex',
    test(value) {
      return (
        !!value &&
        value.search(PASSWORD_NUMBER_REGEX) !== -1 &&
        value.search(PASSWORD_LETTER_REGEX) !== -1 &&
        value.search(PASSWORD_SPECIAL_CHARS_REGEX) !== -1 &&
        value.search(PASSWORD_LENGTH_REGEX) !== -1
      );
    },
  })
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_NOT_EMAIL'),
    name: 'password-not-email',
    test(value) {
      const emailRef = this.resolve(yup.ref('email'));
      if (!emailRef) {
        return true;
      }
      return !value?.toLowerCase().includes(emailRef.toLowerCase());
    },
  })
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_NOT_AIRTM'),
    name: 'password-not-airtm',
    test(value) {
      return value?.search(AIRTM_REGEX) === -1;
    },
  })
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_NOT_CONSECUTIVE_IDENTICAL_CHARS'),
    name: 'password-not-consecutive-identical-chars',
    test(value) {
      return value?.search(CONSECUTIVE_CHARS_REGEX) === -1;
    },
  })
  .test({
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_NOT_SEQUENTIAL_CHARS'),
    name: 'password-not-sequential-chars',
    test(value) {
      return value?.search(SEQUENTIAL_CHARS_REGEX) === -1;
    },
  })
  .max(80, () =>
    i18n.t('ERRORS:FORM_MAX_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PASSWORD'),
      max: 80,
    }),
  )
  .min(8, () =>
    i18n.t('ERRORS:FORM_MIN_CHARACTERS', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PASSWORD'),
      min: 8,
    }),
  );

export const passwordConfirmation = yup
  .string()
  .required(() =>
    i18n.t('ERRORS:FORM_REQUIRED', {
      name: i18n.t('SIGNUP:CONFIRMATION_LABEL_PASSWORD_CONFIRMATION'),
    }),
  )
  .test({
    name: 'password-confirmation-validation',
    message: () => i18n.t('SIGNUP:VALIDATION_PASSWORD_CONFIRM_MATCH'),
    test(value) {
      const passwordValue = this.resolve(yup.ref('password'));
      return value === passwordValue;
    },
  });

export const username = yup
  .string()
  .required(() => i18n.t('ERRORS:FORM_REQUIRED', { name: i18n.t('SETTINGS:USERNAME') }))
  .matches(/^((?!airtm).)*$/, () => i18n.t(`SETTINGS:USERNAME_MODAL_ERROR_AIRTM`))
  .matches(REGEX_USERNAME, () => i18n.t(`SETTINGS:USERNAME_MODAL_ERROR_REGEX`));
