import React, { useMemo } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  VerifyEmailConfirm,
  AppleLogin,
  GoogleLogin,
  FacebookLogin,
  VerifyKountDevice,
  Login as LOGIN_MUTATION,
} from 'graphqlDocuments/mutations';
import { withCustomProps } from 'hoc/withCustomProps';
import withToast from 'hoc/withToast';
import getBrowserUtmData from 'utils/getBrowserUtmData';
import withCaptcha, { useCaptcha } from 'components/Captcha/withCaptcha';
import { WithUser } from 'graphqlDocuments/queries';
import JwtManager from 'lib/JwtManager';

const DISABLE_KOUNT_ACCESS = process.env.DISABLE_KOUNT_ACCESS || '';

const LoginContainer = (LoginComponent) =>
  compose(
    withCustomProps({
      enableKountAccess: !DISABLE_KOUNT_ACCESS || DISABLE_KOUNT_ACCESS.toUpperCase() === 'FALSE',
    }),
    withTranslation(['LOGIN', 'RECOVER_PASSWORD', 'SIGNUP']),
    withRouter,
    withToast,
    graphql(FacebookLogin, {
      props: ({ mutate }) => ({
        facebookLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(GoogleLogin, {
      props: ({ mutate }) => ({
        googleLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(AppleLogin, {
      props: ({ mutate }) => ({
        appleLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(VerifyEmailConfirm, {
      props: ({ mutate }) => ({
        verifyEmailConfirm: (variables) => mutate({ variables }),
      }),
    }),
    graphql(LOGIN_MUTATION, {
      props: ({ mutate }) => ({
        login: (variables) => mutate({ variables }),
      }),
    }),
    graphql(VerifyKountDevice, {
      props: ({ mutate }) => ({
        kountDeviceVerification: (variables) => mutate({ variables }),
      }),
    }),
    graphql(WithUser, {
      fetchPolicy: 'network-only',
      props: ({ data: { me } }) => ({ me }),
      skip: () => !JwtManager.jwt,
    }),
    withCaptcha(),
    (Component) =>
      function LoginContainerWrapper(props) {
        const captchaProps = useCaptcha();
        const utmData = useMemo(getBrowserUtmData, []);
        return <Component {...props} captchaProps={captchaProps} utmData={utmData} />;
      },
  )(LoginComponent);

export default LoginContainer;
