import formatNumber from './formatNumber';

function countDecimals(value: number) {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  return value.toString().split('.')[1].length || 0;
}

/**
 * Formats a rating with the following:
 * - for example from 4.035 to 4.0359 it should round to 4.03
 * - from 4.036 it should round to 4.04
 * - round based on the thrid decimal place
 * - use round-down rule for '5'
 *
 * Takes advantage of JS always rounding to Positive Infinity
 * @param rating
 * @returns
 */
export default function formatRating(rating: number) {
  // this is necessary because for example 4.036 * 1000 returns 4035.9999999999995
  // with toFixed we get 4036
  const parsedFloat = parseFloat((rating * 1000).toFixed(1));

  const truncated = Math.trunc(parsedFloat);
  const rounded = -Math.round(-truncated / 10);
  const final = rounded / 100;

  const precision = countDecimals(final) || 1;
  return formatNumber(final, false, false, precision);
}
