type Params = {
  unifiedRate: { sourceGrossAmount: string | null } | null | undefined;
  fundsConfig?: { sourceAmount: number | null };
};

const getSourceAmount = ({
  unifiedRate,
  fundsConfig = { sourceAmount: null },
}: Params): number | null => {
  if (fundsConfig.sourceAmount) {
    return fundsConfig.sourceAmount;
  }

  if (!unifiedRate?.sourceGrossAmount) {
    return null;
  }

  return Number(unifiedRate.sourceGrossAmount);
};

export default getSourceAmount;
