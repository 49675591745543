import * as yup from 'yup';

import { password } from 'utils/schemaUtils';
import { passwordBaseSchema } from 'utils/validationSchemas';

export default ({ t }) => {
  return yup.object({
    password,
    passwordConfirmation: passwordBaseSchema(
      t,
      t('SIGNUP:CONFIRMATION_LABEL_PASSWORD_CONFIRMATION'),
    ).test({
      message: t('SIGNUP:VALIDATION_PASSWORD_CONFIRM_MATCH'),
      name: 'password-confirm-match',
      test(value) {
        const passwordRef = this.resolve(yup.ref('password'));
        return passwordRef === value;
      },
    }),
  });
};
