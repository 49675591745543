import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FormikProps } from 'formik';
import * as yup from 'yup';
import { TFAInput } from 'components/MfaForm/MfaForm';
import { RecoverMfaUser, RecoverMfaErrors } from 'screens/Login/Login.utils';
import { useCaptcha } from 'components/Captcha/withCaptcha';

export type FormValues = { phoneCode: string; emailCode: string };

const validationSchema = yup.object({
  phoneCode: yup.string().required().matches(/\d{6}/),
  emailCode: yup.string().required().matches(/\d{6}/),
});

function InnerForm({
  formikProps,
  loading,
  errors,
  user,
}: Readonly<{
  formikProps: FormikProps<FormValues>;
  loading: boolean;
  errors: RecoverMfaErrors;
  user: RecoverMfaUser;
}>) {
  const { values, setFieldValue, handleBlur, handleSubmit } = formikProps;

  const { t } = useTranslation(['RECOVER_PASSWORD']);

  const { captchaIsVerified } = useCaptcha();

  useEffect(() => {
    if (values.phoneCode.length === 6 && values.emailCode.length === 6) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.phoneCode, values.emailCode]);

  const disabled = loading || !captchaIsVerified;

  return (
    <Form>
      <div className="u-mb-3">
        <TFAInput
          label={t('RECOVER_PASSWORD:RECOVER_MFA_PHONE_CODE_LABEL', {
            maskedPhone: user.phone,
          })}
          value={values.phoneCode}
          onChange={(newValue: string) => setFieldValue('phoneCode', newValue)}
          // @ts-expect-error
          onBlur={handleBlur}
          errors={errors.phoneCode}
          disabled={disabled}
          autoFocus
        />
      </div>

      <div>
        <TFAInput
          label={t('RECOVER_PASSWORD:RECOVER_MFA_EMAIL_CODE_LABEL', {
            maskedMail: user.email,
          })}
          value={values.emailCode}
          onChange={(newValue: string) => setFieldValue('emailCode', newValue)}
          // @ts-expect-error
          onBlur={handleBlur}
          errors={errors.emailCode}
          disabled={disabled}
          autoFocus={false}
        />
      </div>
    </Form>
  );
}

function MfaForm({
  formRef,
  user,
  onSubmit,
  errors,
}: Readonly<{
  formRef: React.RefObject<FormikProps<FormValues>>;
  user: RecoverMfaUser;
  onSubmit: (values: FormValues) => Promise<void>;
  errors: RecoverMfaErrors;
}>) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    await onSubmit(values);
    // in case of success, user is redirected
    // in case of error, the screen will show the error message (handled in Login.utils)
    setLoading(false);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ phoneCode: '', emailCode: '' }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <InnerForm formikProps={formikProps} loading={loading} errors={errors} user={user} />
      )}
    </Formik>
  );
}

export default MfaForm;
