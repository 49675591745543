// @flow
import formatNumber from './formatNumber';

export const currencyAliases = {
  USD: 'USDC',
};

export default function formatBalance(balance: {
  amount: number,
  currency: { id: string, symbol: string, precision: number, translationTag: string },
}) {
  return formatNumber(
    balance.amount,
    currencyAliases[balance.currency.id] || balance.currency.id,
    balance.currency.symbol,
    balance.currency.precision,
  );
}

export function formatBalanceWithoutCurrency(balance: {
  amount: number,
  currency: { id: string, symbol: string, precision: number, translationTag: string },
}) {
  return formatNumber(balance.amount, null, balance.currency.symbol, balance.currency.precision);
}
