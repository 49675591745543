import * as React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import * as customPropTypes from 'utils/propTypes';
import { validateEmail } from 'utils/validators';
import IllustrationEmailValidate from 'assets/images/sprite/illustration-email-validation.svg';

const EmailSent = function({ t, location }) {
  if (!location.state || !location.state.email || !validateEmail(location.state.email)) {
    return <Redirect to="/sign-up" />;
  }

  const { email } = location.state;

  return (
    <>
      <div className="section col-sm-3 ml-auto">
        <img
          className="img-fluid"
          alt={t('SIGNUP:EMAIL_SENT_HEADER')}
          src={IllustrationEmailValidate}
        />
      </div>
      <div className="section col-sm-9 col-lg-6 mr-auto">
        <h1>{t('SIGNUP:EMAIL_SENT_HEADER')}</h1>
        <h3>{t('SIGNUP:EMAIL_SENT_SUBHEADER')}</h3>
        <p>{t('SIGNUP:EMAIL_SENT_MESSAGE', { email })}</p>
      </div>
    </>
  );
}

EmailSent.propTypes = {
  t: PropTypes.func.isRequired,
  location: customPropTypes.location.isRequired,
};

export default withTranslation(['SIGNUP'])(EmailSent);
