// @flow
import React from 'react';

import { Alert } from '@airtm/airtm-ui';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { networkStatusVar } from 'lib/apollo/clientSchema/NetworkStatus';

const ApolloNetworkStatus = function () {
  const { t } = useTranslation(['ALERTS']);
  const networkStatus = useReactiveVar(networkStatusVar);

  if (!networkStatus) return null;

  if (!networkStatus.online) {
    return (
      <div className="fixed-bottom">
        <Alert
          className="mb-0 rounded-0 p-3"
          title={t('ALERTS:OFFLINE_TITLE')}
          variant="informative"
        >
          {t('ALERTS:OFFLINE_MESSAGE')}
        </Alert>
      </div>
    );
  }

  if (networkStatus.retrying) {
    // TODO: Notify the user an operation is being retried
  }

  return null;
};

export default ApolloNetworkStatus;
