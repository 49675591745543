import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@airtm/airtm-ui';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import IllustrationServerError from 'assets/images/sprite/illustration-server-error.svg';

const FallbackComponent = function ({ resetError }) {
  const { t } = useTranslation(['STATIC']);
  return (
    <div className="container">
      <section className="section">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h1 className="mb-0 mt-4 font-weight--bold">{t('STATIC:ERROR_BOUNDARY_TITLE')}</h1>
            <h3>{t('STATIC:ERROR_BOUNDARY_SUBTITLE')}</h3>
            <hr className="mt-3" />
            <Button variant="primary" onClick={resetError}>
              {t('STATIC:ERROR_BOUNDARY_RELOAD_BUTTON')}
            </Button>
            <p className="mt-5 text-sm">{t('STATIC:ERROR_BOUNDARY_HELP_TEXT')}</p>
          </div>
          <div className="col-sm-12 col-md-6">
            <p className="text--center">
              <img
                className="img-fluid"
                src={IllustrationServerError}
                alt="server error illustration"
              />
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

FallbackComponent.propTypes = {
  resetError: PropTypes.func.isRequired,
};

const fallBack = (props) => <FallbackComponent {...props} />;
const MainErrorBoundary = function ({ children, errorTag }) {
  return (
    <Sentry.ErrorBoundary
      fallback={fallBack}
      beforeCapture={(scope) => {
        if (errorTag) scope.setTag('location', errorTag);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

MainErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorTag: PropTypes.string,
};

MainErrorBoundary.defaultProps = {
  errorTag: '',
};

export default MainErrorBoundary;
