import React, { useRef, useState } from 'react';
import { Lock } from '@airtm/airtm-ui';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { FormikProps } from 'formik';
import RecoverMfaImage from 'assets/images/recover-mfa.png';
import backgroundDesktop from 'assets/images/drop-image.png';
import { RecoverMfaUser, RecoverMfaErrors } from 'screens/Login/Login.utils';
import Captcha from 'components/Captcha/Captcha';

import ResendButton from './ResendButton';
import MfaForm, { FormValues } from './MfaForm';

type Props = {
  sendMfaRecoveryCodes: () => Promise<void>;
  onSubmit: (values: FormValues) => Promise<void>;
  user: RecoverMfaUser;
  errors: RecoverMfaErrors;
  isSendSuccessful: boolean;
};

function RecoverMfa({
  sendMfaRecoveryCodes,
  onSubmit,
  user,
  errors,
  isSendSuccessful,
}: Readonly<Props>) {
  const { t } = useTranslation(['RECOVER_PASSWORD']);

  const [lastAction, setLastAction] = useState<'form' | 'button' | null>(null);

  const formRef = useRef<FormikProps<FormValues>>(null);

  const handleMfaFormSubmit = async (values: FormValues) => {
    await onSubmit(values);
    setLastAction('form');
  };

  const handleResendButtonClick = async () => {
    await sendMfaRecoveryCodes();
    setLastAction('button');
  };

  return (
    <>
      <div
        className={cx(
          'content-background',
          'content-background--desktop',
          'content-background--public-header',
        )}
        style={{
          opacity: 0.5,
          backgroundImage: `url(${backgroundDesktop})`,
        }}
      />

      <div className="row col-12">
        <div
          className={cx(
            'col-lg-6',
            'd-none',
            'd-lg-flex',
            'align-items-center',
            'justify-content-center',
            'img-recover-mfa-container',
          )}
        >
          <img className="img-recover-mfa" src={RecoverMfaImage} alt="recover-mfa" />
        </div>

        <div
          className={cx(
            'col-12',
            'col-sm-10',
            'col-md-8',
            'col-lg-4',
            'offset-sm-1',
            'offset-md-2',
            'offset-lg-1',
          )}
        >
          <section className="section my-8 mt-sm-6 mt-md-5">
            <div className="section__heading">
              <Lock className="text--black u-mb-2" size={56} />
              <h1>{t('RECOVER_PASSWORD:RECOVER_MFA_HEADER')}</h1>
            </div>

            <div className="section__content">
              <div className="text--lg u-mb-3">{t('RECOVER_PASSWORD:RECOVER_MFA_MESSAGE')}</div>

              <MfaForm
                formRef={formRef}
                user={user}
                onSubmit={handleMfaFormSubmit}
                errors={errors}
              />
              {lastAction === 'form' && (
                <Captcha className="u-mt-3" onSuccess={formRef.current?.submitForm} />
              )}

              <ResendButton onClick={handleResendButtonClick} isSendSuccessful={isSendSuccessful} />
              {lastAction === 'button' && (
                <Captcha className="u-mt-3" onSuccess={sendMfaRecoveryCodes} />
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default RecoverMfa;
