import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { signupSources as SIGNUP_PLATFORM_SOURCE } from '@airtm/utils/dist/constants';
import { LoadingOverlay, KountAccessWarning } from '@airtm/airtm-ui';
import { LOCAL_STORAGE } from '@constants';
import { captchaPropsPropType, location as locationPropType } from 'utils/propTypes';
import jwtDecode from 'jwt-decode';
import { trackLoginRedirect, validateRedirectAttempts } from 'utils/monitorLoginRedirect';

import {
  conditionalLogin,
  handleLoginError,
  handleLoginSuccess,
  LOGIN_ERRORS,
} from './Login.utils';
import LoginForm from './LoginForm/LoginForm';
import MfaFormContainer from './MfaFormContainer/MfaFormContainer';
import LoginContainer from './LoginContainer';
import CommentsSliderContainer from './CommentsSliderContainer';
import RecoverMfa from './RecoverMfa/RecoverMfa';

export class LoginComp extends React.Component {
  static propTypes = {
    location: locationPropType.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    me: PropTypes.shape({}),
    login: PropTypes.func.isRequired,
    facebookLogin: PropTypes.func.isRequired,
    googleLogin: PropTypes.func.isRequired,
    appleLogin: PropTypes.func.isRequired,
    verifyEmailConfirm: PropTypes.func.isRequired,
    kountDeviceVerification: PropTypes.func.isRequired,
    toast: PropTypes.func.isRequired,
    toastErr: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    enableKountAccess: PropTypes.bool,
    utmData: PropTypes.shape({}).isRequired,
    captchaProps: captchaPropsPropType.isRequired,
  };

  static defaultProps = {
    enableKountAccess: true,
    me: undefined,
  };

  constructor(props) {
    super(props);

    const { state = {} } = props.location;

    this.state = {
      error: null,
      loginErrorData: null,
      submitting: false,
      loginVariables: {
        ...(state?.googleAuthData && { googleAuthData: state.googleAuthData }),
        ...(state?.facebookAuthData && { facebookAuthData: state.facebookAuthData }),
        ...(state?.appleAuthData && { appleAuthData: state.appleAuthData }),
      },
      mfaMethod: state?.challengeMethod || null,
      showKountInReview: false,
      kountIp: '',
      recoverMfaErrors: {},
      recoverMfaUser: null,
      recoverMfaIsSendSuccessful: false,
    };
  }

  async componentDidMount() {
    const {
      me,
      verifyEmailConfirm,
      kountDeviceVerification,
      location,
      history,
      t,
      toast,
      toastErr,
    } = this.props;

    const { verificationEmailCode, deviceVerificationCode, ...params } = queryString.parse(
      location.search,
    );

    /**
     * This next block is now under phase out stage. It does not work as expected if user has an active session
     * /verify-email screen now is created to handled this code. This will be kept here for old links to still work.
     * Remove after a couple of weeks after this code is merged.
     */
    if (verificationEmailCode) {
      try {
        await verifyEmailConfirm({ code: verificationEmailCode });

        toast({
          title: t('LOGIN:VERIFY_EMAIL_TITLE'),
          message: t('LOGIN:VERIFY_EMAIL_MESSAGE'),
          type: 'success',
        });

        history.replace({
          search: queryString.stringify({ ...params }),
        });
      } catch (error) {
        if (error.graphQLErrors?.[0]?.extensions?.response?.status === 409) {
          toast({
            title: t('LOGIN:VERIFY_EMAIL_TITLE'),
            message: t('LOGIN:VERIFY_EMAIL_MESSAGE'),
            type: 'success',
          });
        } else {
          toastErr(error);
        }
      }
    }

    if (deviceVerificationCode) {
      try {
        await kountDeviceVerification({ code: deviceVerificationCode });

        toast({
          title: t('LOGIN:KOUNT_WHITELISTED'),
          message: t('LOGIN:KOUNT_LOGIN'),
          type: 'success',
        });

        history.replace(queryString.stringify({ ...params }));
      } catch (error) {
        toast({
          title: t('LOGIN:KOUNT_CODE_NOT_FOUND'),
          message: t('LOGIN:KOUNT_TRY_LOGIN'),
          type: 'warning',
        });
      }
    }

    const shouldHardReload = localStorage.getItem(LOCAL_STORAGE.FORCE_RELOAD) || null;

    if (shouldHardReload) {
      localStorage.removeItem(LOCAL_STORAGE.FORCE_RELOAD);
      window.location.reload(true);
    }

    if (me) {
      this.maybeRedirectTo();
    }
  }

  onSubmitCredentials = async (values) => {
    if (!this.validateKountSessionId()) return;
    this.setState({
      error: null,
      loginErrorData: null,
      submitting: true,
      loginVariables: {
        credentials: values,
      },
    });
    const {
      login,
      utmData,
      captchaProps: { captchaHide, captchaMutationErrorCallback, captchaSolution },
    } = this.props;
    try {
      const {
        data: { login: loginData },
      } = await login({
        ...values,
        captcha: captchaSolution,
        metadata: {
          ...utmData,
          oauth_source: SIGNUP_PLATFORM_SOURCE.INTERNAL,
        },
      });

      captchaHide();

      handleLoginSuccess(this, loginData);
    } catch (error) {
      captchaMutationErrorCallback(error);
      this.setState({ submitting: false });
      handleLoginError(this, error);
    }
  };

  onFacebookLogin = async (fbResponse) => {
    if (!this.validateKountSessionId()) return;
    const { facebookLogin, utmData } = this.props;
    this.setState({
      submitting: true,
      loginVariables: {
        facebookAuthData: { fbResponse },
      },
    });
    try {
      const {
        data: { facebookLogin: loginData },
      } = await facebookLogin({
        params: {
          fbResponse,
          metadata: {
            ...utmData,
            oauth_source: SIGNUP_PLATFORM_SOURCE.FACEBOOK,
          },
        },
      });

      handleLoginSuccess(this, loginData);
    } catch (error) {
      this.setState({ submitting: false });
      handleLoginError(this, error);
    }
  };

  onGoogleLogin = async (idToken) => {
    if (!this.validateKountSessionId()) return;
    const { googleLogin, utmData } = this.props;
    this.setState({
      submitting: true,
      loginVariables: {
        googleAuthData: { idToken },
      },
    });
    try {
      const {
        data: { googleLogin: loginData },
      } = await googleLogin({
        params: {
          idToken,
          metadata: {
            ...utmData,
            oauth_source: SIGNUP_PLATFORM_SOURCE.GOOGLE,
          },
        },
      });

      handleLoginSuccess(this, loginData);
    } catch (error) {
      this.setState({ submitting: false });
      handleLoginError(this, error);
    }
  };

  onAppleLogin = async (appleAuth) => {
    if (!this.validateKountSessionId()) return;
    const { appleLogin, utmData } = this.props;
    try {
      const jwtObj = jwtDecode(appleAuth.authorization.id_token);
      const appleResponse = {
        idToken: appleAuth.authorization.id_token,
        email: appleAuth.user?.email || jwtObj.email || '',
        appleId: jwtObj.sub, // Apple user id

        user: {
          firstName: appleAuth.user?.name?.firstName || '',
          lastName: appleAuth.user?.name?.lastName || '',
        },
      };
      this.setState({
        submitting: true,
        loginVariables: {
          appleAuthData: { appleResponse },
        },
      });
      const {
        data: { appleLogin: loginData },
      } = await appleLogin({
        params: {
          appleResponse,
          metadata: {
            ...utmData,
            oauth_source: SIGNUP_PLATFORM_SOURCE.APPLE,
          },
        },
      });

      handleLoginSuccess(this, loginData);
    } catch (error) {
      this.setState({ submitting: false });
      handleLoginError(this, error);
    }
  };

  // These functions are passed as props and need to be arrow functions
  onSubmitMfa = async (mfaData) => {
    const {
      login,
      appleLogin,
      facebookLogin,
      googleLogin,
      utmData,
      captchaProps: { captchaHide, captchaMutationErrorCallback, captchaSolution },
    } = this.props;
    const { loginVariables } = this.state;

    this.setState({
      submitting: true,
      error: null,
      loginErrorData: null,
      recoverMfaErrors: {},
      recoverMfaIsSendSuccessful: false,
    });

    try {
      const loginData = await conditionalLogin({
        loginVariables,
        utmData,
        mfaData,
        login,
        appleLogin,
        facebookLogin,
        googleLogin,
        captcha: captchaSolution,
      });

      captchaHide();

      handleLoginSuccess(this, loginData, mfaData);
    } catch (error) {
      captchaMutationErrorCallback(error);
      this.setState({ submitting: false });
      handleLoginError(this, error);
    }
  };

  sendMfaRecoveryCodes = async () => {
    await this.onSubmitMfa({ isMfaReset: true });
  };

  validateKountSessionId = () => {
    const { enableKountAccess } = this.props;
    if (enableKountAccess && !localStorage.getItem(LOCAL_STORAGE.KOUNT_SESSIONID)) {
      this.setState({ error: LOGIN_ERRORS.INVALID_KOUNT_SESSIONID });
      return false;
    }
    return true;
  };

  // eslint-disable-next-line react/sort-comp
  maybeRedirectTo = () => {
    const { location, history, t, toast } = this.props;

    trackLoginRedirect();

    const destination = {
      pathname: '/',
      state: { from: '/login' },
    };

    if (location.state?.redirectPath) {
      destination.pathname = location.state.redirectPath;
    }
    if (location.state?.redirectSearch) {
      destination.search = location.state.redirectSearch;
    }
    if (location.state?.redirectHash) {
      destination.hash = location.state.redirectHash;
    }

    if (validateRedirectAttempts()) {
      history.push(destination);
    } else {
      toast({
        title: t('LOGIN:SESSION_TERMINATED_TITLE'),
        message: t('LOGIN:SESSION_TERMINATED'),
        type: 'warning',
      });
    }
  };

  render() {
    const { t, captchaProps } = this.props;
    const {
      submitting,
      error,
      loginVariables,
      loginErrorData,
      mfaMethod,
      showKountInReview,
      kountIp,
      recoverMfaUser,
      recoverMfaErrors,
      recoverMfaIsSendSuccessful,
    } = this.state;
    const errorToShow = typeof error === 'string' ? t(error, loginErrorData) : error;

    const showRecoverMfa = !!recoverMfaUser;

    let screen;

    if (showKountInReview) {
      screen = (
        <CommentsSliderContainer className="mt-8">
          <KountAccessWarning
            kountIp={kountIp}
            textHead={t('LOGIN:KOUNT_UNKNOWN')}
            textBody={t('LOGIN:KOUNT_IP')}
            textFoot={t('LOGIN:KOUNT_CHECK_INBOX')}
          />
        </CommentsSliderContainer>
      );
    } else if (showRecoverMfa) {
      screen = (
        <RecoverMfa
          sendMfaRecoveryCodes={this.sendMfaRecoveryCodes}
          onSubmit={this.onSubmitMfa}
          user={recoverMfaUser}
          errors={{ emailCode: error ? [t(error)] : null, ...recoverMfaErrors }}
          isSendSuccessful={recoverMfaIsSendSuccessful}
        />
      );
    } else if (mfaMethod) {
      screen = (
        <CommentsSliderContainer>
          <MfaFormContainer
            t={t}
            submitting={submitting}
            serverErrors={error ? { code: [t(error)] } : {}}
            onSubmit={this.onSubmitMfa}
            sendMfaRecoveryCodes={this.sendMfaRecoveryCodes}
          />
        </CommentsSliderContainer>
      );
    } else {
      screen = (
        <CommentsSliderContainer>
          <LoginForm
            captchaProps={captchaProps}
            submitting={submitting}
            initialValues={loginVariables}
            serverErrors={errorToShow ? { password: [errorToShow] } : {}}
            onSubmit={this.onSubmitCredentials}
            onFacebookLogin={this.onFacebookLogin}
            onGoogleLogin={this.onGoogleLogin}
            onAppleLogin={this.onAppleLogin}
          />
        </CommentsSliderContainer>
      );
    }

    return (
      <>
        <Helmet>
          <title>{t('LOGIN:TITLE_LOGIN')}</title>
        </Helmet>

        {screen}

        {submitting && <LoadingOverlay />}
      </>
    );
  }
}

export default LoginContainer(LoginComp);
