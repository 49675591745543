const debug = process.env.NODE_ENV !== 'production';

const trackCustom = (event, data = {}) => {
  if (window.fbq) {
    // eslint-disable-next-line no-undef
    window.fbq('trackCustom', event, data);
  }

  if (debug) {
    console.info(`[FacebookPixel] fbq('trackCustom', ${event});`, data);
  }
};

const track = (event, data = {}) => {
  if (window.fbq) {
    // eslint-disable-next-line no-undef
    window.fbq('track', event, data);
  }

  if (debug) {
    console.info(`[FacebookPixel] fbq('track', ${event});`, data);
  }
};

export const fbPixelInit = () => {
  try {
    const pixelId = process.env.FACEBOOK_PIXEL_ID;

    if (typeof window.fbq !== 'undefined' || !pixelId) {
      return;
    }

    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */

    fbq('init', pixelId); // eslint-disable-line no-undef
    fbq('track', 'PageView'); // eslint-disable-line no-undef
  } catch (error) {
    console.warn('Facebook pixel initialization failed: %o', error);
  }
};

/**
 * operationType [BUY, SELL]
 */
export const trackFunds = (operationType, amount) => {
  const event = `Request${operationType === 'BUY' ? 'Add' : 'Withdraw'}Funds`;
  const data = {
    currency: 'USD',
    value: amount,
  };

  trackCustom(event, data);
};

/**
 * operationType [BUY, SELL]
 */
export const trackOperationCompleted = (operationType, operationId) => {
  const event = `Complete${operationType === 'BUY' ? 'Add' : 'Withdraw'}Funds`;
  const data = {
    transaction_id: operationId,
  };

  trackCustom(event, data);
};

export const trackFirstTransactionCompleted = (operationId) => {
  const event = 'FirstCompleteTransaction';
  const data = {
    transaction_id: operationId,
  };

  trackCustom(event, data);
};

export const trackSignup = () => {
  const event = 'CompleteRegistration';
  const data = {
    currency: 'USD',
    value: 0,
  };

  track(event, data);
};

export const trackProfileCompleted = () => {
  const event = 'CompleteProfile';

  trackCustom(event);
};

export const trackReferralInvite = () => {
  const event = 'InviteFriend';

  trackCustom(event);
};
