import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Button } from '@airtm/airtm-ui';
import { APPSTORE_LINK } from '@constants';
import appStoreES from 'assets/images/appStoreES.svg';
import appStoreEN from 'assets/images/appStoreEN.svg';
import appStorePT from 'assets/images/appStorePT.svg';

const appstoreLogoSrc = {
  es: appStoreES,
  en: appStoreEN,
  pt: appStorePT,
};

const AppStoreButton = function ({ className }) {
  const { i18n } = useTranslation();
  const classes = cx('p-0', className);

  return (
    <Button
      component="a"
      variant="tertiary"
      className={classes}
      target="_blank"
      rel="noopener noreferrer"
      href={APPSTORE_LINK}
      data-testid="app-store-login"
    >
      <img src={appstoreLogoSrc[i18n.language]} className="u-h50" alt="logo appstore" />
    </Button>
  );
};

AppStoreButton.propTypes = {
  className: PropTypes.string,
};

AppStoreButton.defaultProps = {
  className: null,
};

export default AppStoreButton;
