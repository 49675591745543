import { v4 as uuid } from 'uuid';
import { makeVar } from '@apollo/client';

type Notification = { id: string; message: string };

const initialData = [] as any;

export const minimalNotificationsVar = makeVar<Notification[]>(initialData);

const readNotifications = (): Notification[] => {
  try {
    const notifications = minimalNotificationsVar();

    return notifications;
  } catch (error) {
    return [];
  }
};

const writeNotifications = (notifications: Notification[]) => {
  minimalNotificationsVar(notifications);
};

export const resolvers = {
  Query: {
    minimalNotifications: readNotifications,
  },

  Mutation: {
    addMinimalNotification: (root: {}, args: { notification: Notification }) => {
      const { notification } = args;

      const notifications = readNotifications();

      try {
        const newNotifications = [...notifications, { ...notification, id: uuid() }];
        writeNotifications(newNotifications);
        return true;
      } catch (error) {
        return false;
      }
    },

    removeMinimalNotification: (root: {}, args: { id: string }) => {
      const { id } = args;

      const notifications = readNotifications();

      try {
        const newNotifications = notifications.filter((notification) => notification.id !== id);
        writeNotifications(newNotifications);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
