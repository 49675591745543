// @flow
import { graphql } from '@apollo/client/react/hoc';
import { RemoveNotification } from 'graphqlDocuments/mutations';
import compose from 'lodash.flowright';

const NotificationsContainer = (Notifications) =>
  compose(
    graphql(RemoveNotification, {
      props: ({ mutate }) => ({
        removeNotification: (variables) =>
          mutate({
            variables,
          }),
      }),
    }),
  )(Notifications);

export default NotificationsContainer;
