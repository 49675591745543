import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import * as Sentry from '@sentry/react';

export function isApolloError(error: unknown): error is ApolloError {
  return error instanceof ApolloError;
}

export function getGraphqlErrMessage({ t, error }: any) {
  const code = error?.graphQLErrors?.[0]?.extensions?.code;
  const errorMessage = error?.message || 'error';

  return code ? t([`ERRORS:${code}`, errorMessage]) : errorMessage;
}

type ErrorConditions = {
  codes?: string[];
  messages?: string[];
  errorNumbers?: string[];
};

/**
 * Given an ApolloError object, find a graphQLError by message or code
 * @param error
 * @param conditions
 * @returns first match or undefined
 */
export function findGraphQLError(
  error: ApolloError,
  { codes, messages, errorNumbers }: ErrorConditions,
): GraphQLError | undefined {
  return error.graphQLErrors?.find(
    (graphQLError) =>
      codes?.includes(graphQLError.extensions.code) ||
      messages?.includes(graphQLError.message) ||
      errorNumbers?.includes(graphQLError.extensions.errorNumber),
  );
}

/**
 * Report unexpected errors to Sentry
 * @param error
 * @param variables
 */
export function reportGraphQLError(error: ApolloError, variables: any) {
  const operationalError = findGraphQLError(error, {
    codes: ['INSUFFICIENT_PERMISSIONS_ERROR', 'FORBIDDEN', 'INVALID_DLOCAL_PAYMENT_METHOD'],
  });
  const internalError = findGraphQLError(error, { codes: ['INTERNAL_SERVER_ERROR'] });

  if (!operationalError && !internalError) {
    Sentry.addBreadcrumb(variables);
    Sentry.captureException(error);
  }
}
