import { TFunction } from 'i18next';

export enum ErrorCodes {
  MatchingPeerModal = 350001,
  PeerMatchHeader = 350003,
  PeerTransferFilter = 350008,
  ChangePasswordModal = 350009,
  SecondaryEmail = 350013,
  Client = 350014,
}

type Error = {
  type: string;
  title: string;
  message: string;
  autoClose?: boolean;
};

export const getErrorMessage = (t: TFunction, errorCode: ErrorCodes): string =>
  `${t('ERRORS:UNEXPECTED_ERROR')} - ${errorCode}`;
export const getError = (t: TFunction, errorCode: ErrorCodes, override?: Error): Error => ({
  type: 'error',
  title: t('ERRORS:ERROR'),
  message: getErrorMessage(t, errorCode),
  ...(override || {}),
});
export const hasMessageError = (messageError: string) => (messageError || '').includes(' - 350');

export default {
  hasMessageError,
};
