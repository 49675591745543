import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import noop from '@airtm/utils/dist/utilities/noop';
import CAPTCHA_CONFIG_QUERY from './CaptchaConfigQuery.graphql';
import { CaptchaConfigQuery } from './__generated__/CaptchaConfigQuery';
import { GeetestConfig, GeetestSolution, CaptchaError, CaptchaState } from './Captcha.types';
import { CAPTCHA_TIMEOUT } from './Captcha.constants';

type Options = { alwaysRequired?: boolean };

export const CaptchaContext = createContext<CaptchaState>({
  captchaConfig: null,
  captchaErrorCallback: noop,
  captchaHide: noop,
  captchaIsLoading: true,
  captchaIsMounted: false,
  captchaIsVerified: true,
  captchaMutationErrorCallback: noop,
  captchaShow: noop,
  captchaSolution: null,
  captchaSuccessCallback: noop,
});

export const useCaptcha = (): CaptchaState => useContext(CaptchaContext);

/**
 * Constraints to take into consideration
 * 1. The geetest widget (from react-geetest-captcha) is an error-prone b*tch.
 * 2. The geetest challenge is only valid for 10 minutes. More info: https://docs.geetest.com/captcha/help/faq
 * 3. The geetest challenge can only be used a limited amount of times.
 * 4. Redirection should be done when the captcha widget is unmounted or ready (not verified).
 */

const withCaptcha = (options?: Options) => (Component: ComponentType<any>) => {
  const { alwaysRequired = false } = options || {};

  const WithCaptcha = function (props: any) {
    /**
     * Captcha widget states
     * 1. It isn't mounted and is loading (start)
     * Note: Here it is loading to ensure it will start loading when mounted
     * 2. It is mounted and is loading (loading)
     * 3. It is mounted and isn't loading (ready)
     * 4. It is mounted, isn't loading and has solution (verified)
     */
    const [captchaIsLoading, setCaptchaIsLoading] = useState<boolean>(true);
    const [captchaIsMounted, setCaptchaIsMounted] = useState<boolean>(false);
    const [captchaSolution, setCaptchaSolution] = useState<GeetestSolution | null>(null);

    /**
     * Captcha config query states (captcha widget should be mounted for 2 and 3)
     * 1. Config is null, query shouldn't poll and timer is null
     * 2. Config isn't null, query should poll and timer is null
     * 3. Config isn't null, query shouldn't poll and timer isn't null
     */
    const [captchaConfig, setCaptchaConfig] = useState<GeetestConfig | null>(null);
    const [skipConfigQuery, setSkipConfigQuery] = useState(true);
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

    /**
     * Cleaning setTimeout
     */
    useEffect(
      () => () => {
        if (timer.current) {
          clearTimeout(timer.current);
          timer.current = null;
        }
      },
      [],
    );

    /**
     * Actions
     */

    const captchaHide = useCallback(() => {
      setCaptchaIsMounted(false);
      setCaptchaIsLoading(true);
      setCaptchaSolution(null);
      setCaptchaConfig(null);
      setSkipConfigQuery(true);
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    }, []);

    const captchaConfigBeforeUpdate = () => {
      // `setCaptchaIsLoading` must be executed before `setCaptchaSolution` to avoid `old challenge` error.
      // This error is raised by "unexpected" re-rendering.
      setCaptchaIsLoading(true);
      setCaptchaSolution(null);
    };

    const captchaConfigAfterUpdate = () => {
      setCaptchaIsLoading(false);
    };

    /**
     * Query
     */

    const configQuery = useQuery<CaptchaConfigQuery>(CAPTCHA_CONFIG_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: CaptchaConfigQuery) => {
        setCaptchaConfig(data.captchaConfig);
      },
      pollInterval: CAPTCHA_TIMEOUT,
      skip: skipConfigQuery,
    });

    useEffect(() => {
      if (configQuery.loading) {
        captchaConfigBeforeUpdate();
      }
    }, [configQuery.loading]);

    /**
     * Listen to challenge
     */

    useEffect(() => {
      if (captchaConfig?.challenge) {
        captchaConfigAfterUpdate();
      }
    }, [captchaConfig?.challenge]);

    /**
     * Flow
     * Case: Captcha was initialized with config from error
     * 1. captchaShow(config)
     * 2. setCaptchaConfig(config)
     * 3. setTimeout -> setSkipConfigQuery(false)
     * 4. setCaptchaIsMounted(true)
     * 5. setCaptchaIsLoading(false)
     */
    const captchaShow = useCallback(
      (config: GeetestConfig) => {
        if (captchaIsMounted) {
          return;
        }

        if (timer.current) {
          clearTimeout(timer.current);
        }

        captchaConfigBeforeUpdate();
        setCaptchaConfig(config);
        timer.current = setTimeout(() => {
          setSkipConfigQuery(false);
        }, CAPTCHA_TIMEOUT);

        setCaptchaIsMounted(true);
      },
      [captchaIsMounted],
    );

    /**
     * Note that reloading involves doing another query because geetest doesn't like repeating challenges.
     */
    const captchaReload = useCallback(() => {
      configQuery.refetch();
    }, [configQuery]);

    /**
     * Callbacks
     */

    const captchaMutationErrorCallback = useCallback(
      (error: CaptchaError) => {
        if (captchaIsMounted) {
          const isInvalid = !!error?.graphQLErrors?.find(
            (graphQLError) => graphQLError.extensions.code === 'INVALID_GEETEST_CAPTCHA_ERROR',
          );
          if (isInvalid || alwaysRequired) {
            captchaReload();
          } else {
            captchaHide();
          }
        } else {
          const captchaChallenge = error?.graphQLErrors?.find(
            (graphQLError) => graphQLError.extensions.code === 'GEETEST_CHALLENGE',
          );
          if (captchaChallenge) {
            const config = captchaChallenge.extensions.captcha;
            captchaShow(config);
          }
        }
      },
      [captchaHide, captchaIsMounted, captchaReload, captchaShow],
    );

    const captchaIsVerified = !captchaIsMounted || !!captchaSolution;

    /**
     * Value
     */

    const value = useMemo(
      () => ({
        captchaConfig,
        captchaErrorCallback: captchaReload,
        captchaHide,
        captchaIsLoading,
        captchaIsMounted,
        captchaIsVerified,
        captchaMutationErrorCallback,
        captchaShow,
        captchaSolution,
        captchaSuccessCallback: setCaptchaSolution,
      }),
      [
        captchaConfig,
        captchaHide,
        captchaIsLoading,
        captchaIsMounted,
        captchaIsVerified,
        captchaMutationErrorCallback,
        captchaReload,
        captchaShow,
        captchaSolution,
      ],
    );

    return (
      <CaptchaContext.Provider value={value}>
        <Component {...props} />
      </CaptchaContext.Provider>
    );
  };

  WithCaptcha.displayName = `WithCaptcha(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WithCaptcha;
};

export default withCaptcha;
