import { callFunctionByProbability } from 'utils/callFunctionByProbability';
import { LOCAL_STORAGE } from '@constants';

const MAX_SESSION_COUNTS = window.HEAP_MAX_SESSION_PER_USER_PER_DAY || 3;
const A_MINUTE = 1000 * 60;
let intervalSessionCounter: NodeJS.Timer;
let loadTimeout: NodeJS.Timer;

const clearTimeoutLoadScript = () => clearTimeout(loadTimeout);
const getLotteryTimestamp = (): Number => {
  const value = Number(localStorage.getItem(LOCAL_STORAGE.HEAP_LOTTERY_TIMESTAMP));
  if (value !== 0 && !value) return 0;
  return value;
};

const getSessionId = (): String | null => localStorage.getItem(LOCAL_STORAGE.HEAP_SESSION_ID);
const getSessionCount = (): number => {
  const value = localStorage.getItem(LOCAL_STORAGE.HEAP_SESSION_COUNT);
  return value ? +value : 0;
};
const wonLottery = (): boolean => {
  const value = localStorage.getItem(LOCAL_STORAGE.HEAP_WON_LOTTERY);
  return value === 'true';
};
const isParticipatedLottery = (): boolean => getLotteryTimestamp() !== 0;

const saveLoadTimestamp = (time = Date.now()) => {
  const now = String(time);
  window.localStorage.setItem(LOCAL_STORAGE.HEAP_LOAD_TIMESTAMP, now);
  // The StorageEvent is fired whenever a change is made to the Storage object.
  // This won't work on the same page that is making the changes — it is really a way for other pages
  // on the domain using the storage to sync any changes that are made.
  // So we need to dispatch the event manually to be able to use it in
  // `containers/SessionContainer/useHeapUserProperties.ts`.
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: LOCAL_STORAGE.HEAP_LOAD_TIMESTAMP,
      newValue: now,
    }),
  );
};
const saveLotteryTimestamp = (time = Date.now()) =>
  localStorage.setItem(LOCAL_STORAGE.HEAP_LOTTERY_TIMESTAMP, String(time));
const saveSessionCount = (count: number | undefined) =>
  localStorage.setItem(LOCAL_STORAGE.HEAP_SESSION_COUNT, !count ? '0' : String(count));
const saveSessionId = (sessionId: string | undefined) =>
  localStorage.setItem(LOCAL_STORAGE.HEAP_SESSION_ID, sessionId as string);
const saveWonLottery = (won: boolean) =>
  localStorage.setItem(LOCAL_STORAGE.HEAP_WON_LOTTERY, won ? 'true' : 'false');

const hasChangeDate = (): boolean => {
  const actualTimestamp = getLotteryTimestamp();
  const lastSessionDate =
    actualTimestamp === 0 ? new Date().toDateString() : new Date(+actualTimestamp).toDateString();
  const actualDate = new Date().toDateString();
  return lastSessionDate !== actualDate;
};

/**
 * This is a lib code extracted from: https://developers.heap.io/docs/web
 * There is not intention to be linted/prettier.
 */
const loadHeapScript = (onLoad: () => void) => {
  if (window.heap && window.heap.load) {
    window.heap.load(process.env.HEAP_APP_ID as string);
    saveLoadTimestamp();
    onLoad();
  } else {
    import('./heapLib').then(() => {
      if (window.heap && window.heap.load) {
        window.heap.load(process.env.HEAP_APP_ID as string);
        loadTimeout = setTimeout(() => {
          saveLoadTimestamp();
          onLoad();
        }, 1000); // we need to give sime time to load the heap scrip
      }
    });
  }
};

const handlerWin = () => {
  loadHeapScript(() => {
    saveWonLottery(true);
    saveLotteryTimestamp();
    saveSessionId(window.heap && window.heap.getSessionId && window.heap.getSessionId());
    saveSessionCount(1); // initialize with one heap session
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    initializeIntervalCounterSession();
    clearTimeoutLoadScript();
  });
};

const handlerLose = () => {
  saveWonLottery(false);
  saveLotteryTimestamp();
  saveSessionId('');
  saveSessionCount(0);
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  initializeIntervalCounterSession();
};

const initializeIntervalCounterSession = () => {
  intervalSessionCounter = setInterval(() => {
    const isDayChanged = hasChangeDate();

    if (isDayChanged) {
      clearInterval(intervalSessionCounter); // stop the current interval
      // the day changed, so make sure the user participate again
      callFunctionByProbability(
        handlerWin,
        handlerLose,
        window.HEAP_SAMPLE_RATE, // should be assigned from Google Tag Manager
      );
    } else if (window.heap && wonLottery()) {
      const actualSessionId = getSessionId();
      const possibleNewSessionId = window.heap.getSessionId?.();
      const heapSessionIdHasChanged = possibleNewSessionId !== actualSessionId;

      if (heapSessionIdHasChanged) {
        if (getSessionCount() >= MAX_SESSION_COUNTS) {
          // indicate for now to heap that user make like a log out action.
          // I sent a email to Heap support, to get information
          // how to stop the script  like `heap.unload`).
        } else {
          const actualSessionCount = getSessionCount();
          saveSessionCount(actualSessionCount + 1);
          saveSessionId(possibleNewSessionId);
        }
      }
    }
  }, A_MINUTE) as NodeJS.Timer;
};

export const heapInit = () => {
  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!isParticipatedLottery()) {
    callFunctionByProbability(
      handlerWin,
      handlerLose,
      window.HEAP_SAMPLE_RATE, // should be assigned from Google Tag Manager
    );
  } else if (wonLottery() && getSessionCount() < MAX_SESSION_COUNTS) {
    loadHeapScript(() => {
      initializeIntervalCounterSession();
      clearTimeoutLoadScript();
    });
  } else {
    initializeIntervalCounterSession();
  }
};

export const heapLogout = (): void => {
  if (window.heap && window.heap.resetIdentity) {
    window.heap.resetIdentity();
  }
};

export const heapInitSampleRate = (): void => {
  const customSampleRate = Number(localStorage.getItem(LOCAL_STORAGE.HEAP_CUSTOM_SAMPLE_RATE));
  if (customSampleRate > 0) {
    window.HEAP_SAMPLE_RATE = customSampleRate;
  }
};

export default heapInit;
