import { graphql } from '@apollo/client/react/hoc';
import * as compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';
import { UserByInvitationCode } from 'graphqlDocuments/queries';
import setAndroidReferrer from 'utils/setAndroidReferrer';

const InvitationContainer = (Invitation) =>
  compose(
    withTranslation('REFERRALS'),
    graphql(UserByInvitationCode, {
      options: (props) => {
        const { code } = props.match.params;
        setAndroidReferrer('referralCode', code);
        return {
          variables: { code },
        };
      },
      props: ({ data }) => ({
        loading: data.loading,
        referrerEmail: data.user?.email || null,
        matchingPeer: {
          id: data.user?.id,
          name: data.user?.username,
        },
      }),
    }),
  )(Invitation);

export default InvitationContainer;
