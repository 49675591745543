import React from 'react';
import PropTypes from 'prop-types';
import { BUSINESS, INDIVIDUAL } from '@airtm/utils/dist/constants/accountTypes';
import { signupSources as SIGNUP_PLATFORM_SOURCE } from '@airtm/utils/dist/constants';
import { Trans } from 'react-i18next';
import JwtManager from 'lib/JwtManager';
import { trackCustomEvent } from 'lib/gtag4/gtag4';
import { LOCAL_STORAGE } from '@constants';
import { trackSignup } from 'utils/facebookPixel';
import { getGraphqlErrMessage } from 'utils/graphql';
import {
  Alert,
  IconModal,
  Business,
  Button,
  LoadingOverlay,
  KountAccessWarning,
} from '@airtm/airtm-ui';
import isMainDomain from 'utils/isMainDomain';
import { captchaConfigPropType, captchaPropsPropType } from 'utils/propTypes';
import jwtDecode from 'jwt-decode';
import CommentsSlider from 'components/CommentsSlider/CommentsSlider';
import SignUpForm from './SignUpForm/SignUpForm';
import SignUpContainer from './SignUpContainer';
import { AddressLocationModal, ADDRESS_LOCATION_MODAL_CODES } from './AddressLocationModal';

// TODO: @3ddmoo Show error if mutation returns null.
const SIGN_UP_ERRORS = {
  USER_REGISTERED: 'SIGNUP:ERROR_USER_REGISTERED',
};
const INVALID_RESPONSES = {
  USER_REGISTERED: 'SIGNUP:ERROR_USER_REGISTERED',
};

export class SignUpComp extends React.Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    signUpPartial: PropTypes.func.isRequired,
    utmData: PropTypes.objectOf(PropTypes.string).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    facebookLogin: PropTypes.func.isRequired,
    googleLogin: PropTypes.func.isRequired,
    appleLogin: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    enableKountAccess: PropTypes.bool,
    captchaProps: captchaPropsPropType.isRequired,
    captchaConfig: captchaConfigPropType,
  };

  static defaultProps = {
    enableKountAccess: true,
    captchaConfig: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      submitting: false,
      oauthData: {},
      userData: {
        country: null,
        accountType: INDIVIDUAL,
      },
      userDataErrors: {
        country: null,
        accountType: null,
      },
      isBusinessWarningOpen: false,
      addressLocationModalCode: null,
      disabled: false,
    };

    const { history } = props;
    if (JwtManager.jwt) {
      history.push('/');
    }
  }

  onSubmit = async (user) => {
    const {
      signUpPartial,
      utmData,
      i18n,
      captchaProps: { captchaHide, captchaMutationErrorCallback, captchaSolution },
    } = this.props;
    const { timeZone: timezone } = Intl.DateTimeFormat().resolvedOptions();
    const { language } = i18n;

    this.setState({ submitting: true });
    if (!this.validateKountSessionId()) return;

    try {
      const {
        data: {
          signUpPartial: { user: userRegistered, jwt, challengeMethod = null },
        },
      } = await signUpPartial({
        user,
        metadata: {
          ...utmData,
          oauth_source: SIGNUP_PLATFORM_SOURCE.INTERNAL,
        },
        preferences: {
          profile: {
            language,
            timezone,
          },
        },
        captcha: captchaSolution,
      });
      captchaHide();
      localStorage.setItem(
        `${LOCAL_STORAGE.REQUEST_VERIFICATION_EMAIL}-${userRegistered?.id || ''}`,
        'true',
      );
      this.setState({ submitting: false });
      this.handleSignupResponse(jwt, challengeMethod, { isSignup: true });
    } catch (error) {
      captchaMutationErrorCallback(error);
      this.setState({ submitting: false });
      this.validateUserData();
      this.handleSignupError(error);
    }
  };

  onGoogleLogin = async (idToken) => {
    if (!this.validateKountSessionId()) return;
    const { googleLogin } = this.props;
    const thirdPartyAuthSignupPayload = this.buildThirdPartyAuthSignupPayload(
      SIGNUP_PLATFORM_SOURCE.GOOGLE,
    );

    this.setState({
      submitting: true,
      oauthData: {
        googleAuthData: { idToken },
      },
    });

    try {
      const {
        data: {
          googleLogin: { authenticationResponse, challengeMethod, isSignup, userId },
        },
      } = await googleLogin({
        params: {
          idToken,
          ...thirdPartyAuthSignupPayload,
        },
      });

      localStorage.setItem(`${LOCAL_STORAGE.REQUEST_VERIFICATION_EMAIL}-${userId}`, 'true');
      this.setState({ submitting: false });
      this.handleSignupResponse(authenticationResponse, challengeMethod, { isSignup });
    } catch (error) {
      this.setState({ submitting: false });
      this.validateUserData();
      this.handleSignupError(error);
    }
  };

  onFacebookLogin = async (fbResponse) => {
    if (!this.validateKountSessionId()) return;
    const { facebookLogin } = this.props;
    const thirdPartyAuthSignupPayload = this.buildThirdPartyAuthSignupPayload(
      SIGNUP_PLATFORM_SOURCE.FACEBOOK,
    );

    this.setState({
      submitting: true,
      oauthData: {
        facebookAuthData: { fbResponse },
      },
    });

    try {
      const {
        data: {
          facebookLogin: { authenticationResponse, challengeMethod, isSignup, userId },
        },
      } = await facebookLogin({
        params: {
          fbResponse,
          ...thirdPartyAuthSignupPayload,
        },
      });
      localStorage.setItem(`${LOCAL_STORAGE.REQUEST_VERIFICATION_EMAIL}-${userId}`, 'true');
      this.setState({ submitting: false });
      this.handleSignupResponse(authenticationResponse, challengeMethod, { isSignup });
    } catch (error) {
      this.setState({ submitting: false });
      this.validateUserData();
      this.handleFacebookSignupError(error);
      this.handleSignupError(error);
    }
  };

  onAppleLogin = async (appleAuth) => {
    if (!this.validateKountSessionId()) return;
    const { appleLogin } = this.props;
    const thirdPartyAuthSignupPayload = this.buildThirdPartyAuthSignupPayload(
      SIGNUP_PLATFORM_SOURCE.APPLE,
    );

    try {
      const jwtObj = jwtDecode(appleAuth.authorization.id_token);
      const appleResponse = {
        idToken: appleAuth.authorization.id_token,
        email: appleAuth.user?.email || jwtObj.email || '',
        appleId: jwtObj.sub, // Apple user id

        user: {
          firstName: appleAuth.user?.name?.firstName || '',
          lastName: appleAuth.user?.name?.lastName || '',
        },
      };
      this.setState({
        submitting: true,
        oauthData: {
          appleAuthData: { appleResponse },
        },
      });
      const {
        data: {
          appleLogin: { authenticationResponse, challengeMethod, userId, isSignup },
        },
      } = await appleLogin({
        params: {
          appleResponse,
          ...thirdPartyAuthSignupPayload,
        },
      });

      localStorage.setItem(`${LOCAL_STORAGE.REQUEST_VERIFICATION_EMAIL}-${userId}`, 'true');
      this.setState({ submitting: false });
      this.handleSignupResponse(authenticationResponse, challengeMethod, { isSignup });
    } catch (error) {
      this.setState({ submitting: false });
      this.validateUserData();
      this.handleSignupError(error);
    }
  };

  onChange = (field, value) => {
    // if business option is selected and popup is not open, open the popUp
    const { isBusinessWarningOpen, userData } = this.state;
    if (
      field === 'accountType' &&
      value === BUSINESS &&
      !isBusinessWarningOpen &&
      userData.country !== 'ARG'
    ) {
      this.setState({ isBusinessWarningOpen: true });
      return;
    }

    this.setState(
      (prevState) => ({
        userData: {
          ...prevState.userData,
          [field]: value,
        },
      }),
      () => {
        if (field === 'accountType' || field === 'country') {
          this.validateUserData();
        }
      },
    );
  };

  onAcceptBusinessWarning = () => {
    this.onChange('accountType', BUSINESS);
    this.setState({ isBusinessWarningOpen: false });
  };

  onCloseBusinessWarning = () => {
    this.setState({ isBusinessWarningOpen: false });
  };

  validateKountSessionId = () => {
    const { enableKountAccess } = this.props;
    if (enableKountAccess && !localStorage.getItem(LOCAL_STORAGE.KOUNT_SESSIONID)) {
      this.handleSignupError(new Error('KOUNT_CONTROL_INVALID_REQUEST'));
      return false;
    }
    return true;
  };

  handleFacebookSignupError = (error) => {
    const { t, addNotification } = this.props;
    if (error.graphQLErrors) {
      const [graphQLError] = error.graphQLErrors;
      const {
        extensions: { response },
      } = graphQLError;

      if (response?.status === 422) {
        const {
          body: { messages },
        } = response;

        if (messages.email) {
          trackCustomEvent('error__signup__fb_no_email');
          addNotification({
            notification: {
              title: t('SIGNUP:ERROR_CREATE_ACCOUNT'),
              message: t('SIGNUP:ERROR_FB_NO_EMAIL'),
              autoClose: false,
              type: 'error',
            },
          });
        }
      }
    }
  };

  buildThirdPartyAuthSignupPayload = (platformSource) => {
    const { utmData, i18n } = this.props;
    const { userData } = this.state;
    const { timeZone: timezone } = Intl.DateTimeFormat().resolvedOptions();
    const { language } = i18n;
    const referrerEmail = localStorage.getItem(LOCAL_STORAGE.REFERRER_EMAIL);

    return {
      user: {
        country: userData.country,
        accountType: userData.accountType,
        ...(referrerEmail && { referrerEmail }),
      },
      metadata: {
        ...utmData,
        oauth_source: platformSource,
      },
      preferences: {
        profile: {
          language,
          timezone,
        },
      },
    };
  };

  handleSignupResponse = (jwt, challengeMethod, { isSignup } = {}) => {
    const { addNotification, history, t } = this.props;
    const {
      oauthData: { appleAuthData = {}, facebookAuthData = {}, googleAuthData = {} },
    } = this.state;

    if (jwt && !challengeMethod) {
      if (isSignup) {
        trackSignup();
      }
      JwtManager.jwt = jwt.jwt;
      history.replace('/home', { showVerifyEmailCompleteProfileModal: true });
    }

    /** this actually is a third party login with 2fa,
     * so let's redirect to login and pass the token */
    if (!jwt && challengeMethod) {
      history.push({
        pathname: '/login',
        state: {
          challengeMethod,
          ...(googleAuthData.idToken && { googleAuthData }),
          ...(facebookAuthData.fbResponse && { facebookAuthData }),
          ...(appleAuthData.appleResponse && { appleAuthData }),
        },
      });
    }

    if (!jwt && !challengeMethod) {
      addNotification({
        notification: {
          title: t('SIGNUP:ERROR_USER_REGISTERED'),
          autoClose: false,
          message: null,
          type: 'warning',
        },
      });
      history.replace('/login');
    }
  };

  handleSignupError = (error) => {
    const { addNotification, t } = this.props;

    const getErrorMessage = () => {
      if (error.networkError) {
        return t('LOGIN:NETWORK_CONNECTION_ERROR');
      }

      if (error.graphQLErrors) {
        const [
          {
            message: graphqlErrorMessage,
            extensions: { code: errorCode, errorNumber, response: errorResponse } = {},
          } = {},
        ] = error.graphQLErrors || [];

        if (errorCode === 'KOUNT_CONTROL_INVALID_REQUEST') {
          return t('LOGIN:INVALID_KOUNT_SESSIONID');
        }

        if (errorCode === 'KOUNT_DEVICE_NOT_TRUSTED') {
          this.setState({
            showKountInReview: true,
            kountReviewIp: graphqlErrorMessage,
          });
          return null;
        }

        if (ADDRESS_LOCATION_MODAL_CODES[errorNumber]) {
          const { code, disableForm } = ADDRESS_LOCATION_MODAL_CODES[errorNumber];
          this.setState({
            addressLocationModalCode: code,
          });
          if (disableForm) {
            this.setState({ disabled: true });
          }
          return null;
        }

        const errorMessage = errorResponse?.body?.message || graphqlErrorMessage;
        const errorEmailMessage = errorResponse?.body?.messages?.email?.[0];

        if (errorResponse?.status === 422 && errorEmailMessage) {
          if (errorEmailMessage === SIGN_UP_ERRORS.USER_REGISTERED) {
            this.setState({
              error: INVALID_RESPONSES.USER_REGISTERED,
            });
            return null;
          }

          return errorEmailMessage;
        }

        if (errorMessage === 'Unprocessable entity') {
          return t('SIGNUP:ERROR_USER_REGISTERED');
        }

        return getGraphqlErrMessage({ t, error });
      }

      if (error.message === 'KOUNT_CONTROL_INVALID_REQUEST') {
        return t('LOGIN:INVALID_KOUNT_SESSIONID');
      }

      return null;
    };

    const errorMessage = getErrorMessage();

    if (!errorMessage) {
      // Some error handlers need to skip the notification.
      // For example: field errors, modals.
      return;
    }

    trackCustomEvent('error__signup__unhandled', {
      message: errorMessage,
    });

    addNotification({
      notification: {
        title: t('ERRORS:ERROR'),
        message: errorMessage,
        autoClose: false,
        type: 'error',
      },
    });

    this.setState({
      submitting: false,
    });
  };

  /**
   * ARG can not have business accounts
   */
  validateArgentinianBusiness = () => {
    const { addNotification, t } = this.props;
    const {
      userData: { country, accountType },
    } = this.state;
    if (country === 'ARG' && accountType === BUSINESS) {
      addNotification({
        notification: {
          title: t('SIGNUP:ARG_VALIDATION_TITLE'),
          autoClose: false,
          message: t('SIGNUP:ARG_VALIDATION_MESSAGE'),
          type: 'informative',
        },
      });
      this.setState({
        userData: {
          accountType: INDIVIDUAL,
          country: 'ARG',
        },
      });
      return false;
    }
    return true;
  };

  validateUserData = () => {
    const { t } = this.props;
    const {
      userData: { accountType, country },
    } = this.state;

    const accountTypeRequiredError = t('ERRORS:FORM_REQUIRED', {
      name: t('SIGNUP:CONFIRMATION_ACCOUNT_TYPE'),
    });
    const countryRequiredError = t('ERRORS:FORM_REQUIRED', {
      name: t('SIGNUP:CONFIRMATION_LABEL_COUNTRY'),
    });

    this.setState({
      userDataErrors: {
        accountType: accountType ? null : accountTypeRequiredError,
        country: country ? null : countryRequiredError,
      },
    });

    return !!accountType && !!country && this.validateArgentinianBusiness();
  };

  addressLocationModalClose = () => {
    this.setState({ addressLocationModalCode: null });
  };

  render() {
    const { t, captchaConfig, captchaProps } = this.props;
    const {
      error,
      submitting,
      isBusinessWarningOpen,
      addressLocationModalCode,
      disabled,
      userData,
      userDataErrors,
      showKountInReview,
      kountReviewIp,
    } = this.state;
    const errorToShow = typeof error === 'string' ? t(error) : error;

    return (
      <div className="row col-12">
        <div className="col-lg-6 p-0">
          <CommentsSlider />
        </div>

        <div className="col-12 col-sm-10 col-md-8 col-lg-4 offset-sm-1 offset-md-2 offset-lg-1">
          <section className="section mb-6">
            {showKountInReview && (
              <div className="col-12 offset-sm-1 offset-md-2 offset-lg-1 text--left mt-8">
                <KountAccessWarning
                  kountIp={kountReviewIp}
                  textHead={t('LOGIN:KOUNT_UNKNOWN')}
                  textBody={t('LOGIN:KOUNT_IP')}
                  textFoot={t('LOGIN:KOUNT_CHECK_INBOX')}
                />
              </div>
            )}
            {!showKountInReview && (
              <>
                {isMainDomain() && window.location.pathname === '/sign-up' && (
                  <Alert variant="warning" title={t('LOGIN:AVOID_FRAUD_ALERT_TITLE')}>
                    <div className="text--sm">
                      <Trans i18nKey="LOGIN:AVOID_FRAUD_ALERT_SUBTITLE_SIGN_UP" />
                    </div>
                  </Alert>
                )}

                <h1 className="u-my-4">{t('SIGNUP:TITLE')}</h1>

                <SignUpForm
                  id="sign-up"
                  submitting={submitting}
                  serverErrors={errorToShow ? { email: [errorToShow] } : {}}
                  onSubmit={this.onSubmit}
                  onFacebookLogin={this.onFacebookLogin}
                  onGoogleLogin={this.onGoogleLogin}
                  onAppleLogin={this.onAppleLogin}
                  onChange={this.onChange}
                  userData={userData}
                  userDataErrors={userDataErrors}
                  validateUserData={this.validateUserData}
                  disabled={disabled}
                  captchaConfig={captchaConfig}
                  captchaProps={captchaProps}
                />
              </>
            )}
          </section>
        </div>

        <IconModal show={isBusinessWarningOpen} modalClose={this.onCloseBusinessWarning}>
          <IconModal.Body icon={<Business />} title={t('SIGNUP:BUSINESS_WARNING_TITLE')}>
            <div className="h6 font-weight--regular">
              <Trans i18nKey="SIGNUP:BUSINESS_WARNING_BODY" />
            </div>
            <div className="h6 mb-0 font-weight--regular">
              <Trans i18nKey="SIGNUP:BUSINESS_WARNING_BODY_LIMIT" />
            </div>
          </IconModal.Body>
          <IconModal.Actions>
            <Button
              variant="secondary"
              data-testid="continue-invidual-profile-su"
              onClick={this.onCloseBusinessWarning}
            >
              {t('SIGNUP:BUSINESS_WARNING_CANCEL')}
            </Button>
            <Button
              variant="primary"
              onClick={this.onAcceptBusinessWarning}
              data-testid="continue-business-profile-su"
            >
              {t('SIGNUP:BUSINESS_WARNING_ACCEPT')}
            </Button>
          </IconModal.Actions>
        </IconModal>

        {addressLocationModalCode && (
          <AddressLocationModal
            code={addressLocationModalCode}
            modalClose={this.addressLocationModalClose}
          />
        )}

        {submitting && <LoadingOverlay />}
      </div>
    );
  }
}

export default SignUpContainer(SignUpComp);
