import * as yup from 'yup';
import { REGEX_USERNAME } from '@airtm/utils/dist/constants';
import { email } from 'utils/schemaUtils';

export const passwordBaseSchema = (t, name) => {
  return yup
    .string()
    .ensure()
    .required(
      t('ERRORS:FORM_REQUIRED', {
        name,
      }),
    )
    .max(
      80,
      t('ERRORS:FORM_MAX_CHARACTERS', {
        name,
        max: 80,
      }),
    )
    .min(
      8,
      t('ERRORS:FORM_MIN_CHARACTERS', {
        name,
        min: 8,
      }),
    );
};

export function loginSchema(t) {
  return yup.object({
    email,
    password: yup
      .string()
      .required(t('ERRORS:FORM_REQUIRED', { name: t('LOGIN:CONFIRMATION_LABEL_PASSWORD') })),
  });
}

export const mfaCodeSchema = yup.object({
  code: yup.string().required().matches(/\d{6}/),
});

export const usernameSchema = yup
  .string()
  .required()
  .matches(REGEX_USERNAME)
  .test({
    name: 'username-includes-airtm',
    test(value) {
      return !value.toLowerCase().includes('airtm');
    },
  });

export const paymentMethodSearchSchema = (t) => {
  return yup.string().matches('^(?!.* {2})[0-9A-Za-zÀ-ÖØ-öø-ÿ ]{0,}$', {
    message: t('PAYMENT_METHODS:SEARCH_TEXT_INVALID'),
  });
};

export const reasonValidationSchema = (t) => {
  return yup.object().shape({
    reason: yup.string().required(() =>
      t('ERRORS:FORM_REQUIRED', {
        name: t('CANCELLATION_REASONS:LABEL_REASON'),
      }),
    ),
    comment: yup.string().when('reason', {
      is: 'CANCELLATION_REASONS:OTHER',
      then: yup
        .string()
        .required(() =>
          t('ERRORS:FORM_REQUIRED', {
            name: t('CANCELLATION_REASONS:LABEL_MORE_DETAILS'),
          }),
        )
        .min(10, () =>
          t('ERRORS:FORM_MIN_CHARACTERS', {
            name: t('CANCELLATION_REASONS:LABEL_MORE_DETAILS'),
            min: 10,
          }),
        ),
    }),
  });
};
