import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useQueryFix } from 'utils/hooks/useQueryFix';
import { GetPurchase } from 'graphqlDocuments/queries';
import { ConfirmPurchase, CancelPurchase } from 'graphqlDocuments/mutations';
import { findGraphQLError } from 'utils/graphql';
import { findVolumeOrTransactionError } from 'utils/volumeOrTransactionErrors';
import URL from 'url-parse';

function redirectToPartner(uri, purchase) {
  const parsedUri = new URL(uri, true);
  const { code } = purchase;
  parsedUri.set('query', {
    ...parsedUri.query,
    code,
  });
  window.location = parsedUri.toString();
}

/**
 * useCheckout
 * - Encapsulates the logic related to checking out a purchase.
 * - Exposes a simplified state and two actions, confirm & cancel.
 *
 * @param {String} purchaseID checking out.
 */
export default function useCheckout(purchaseID) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    sending: false,
    poll: false,
    err: false,
    waitingRedirect: false,
    spendingLimitError: null,
    showMfaForm: false,
    invalidMfa: false,
    overLimitError: null,
  });
  const { data } = useQueryFix(GetPurchase, {
    variables: { id: purchaseID },
    networkPolicy: 'network-only',
    pollInterval: state.poll ? 5000 : 0,
  });
  const purchase = data && data.purchase;

  // Redirect to partner effect (on proper status change).
  useEffect(() => {
    if (!purchase || !state.poll) {
      return;
    }
    if (purchase.status === 'CONFIRMED') {
      setState({ ...state, waitingRedirect: true });
      redirectToPartner(purchase.confirmationUri, purchase);
    }
    if (purchase.status === 'CANCELED') {
      setState({ ...state, waitingRedirect: true });
      redirectToPartner(purchase.cancelUri, purchase);
    }
    if (purchase.status === 'FAILED_WITH_ERROR' || purchase.status === 'FAILED') {
      setState({ ...state, err: 'This transaction failed to be processed.', poll: false });
    }
  }, [state.poll, purchase && purchase.status]);

  // Wrap up the mutations we'll be using below.
  const [cancelPurchase] = useMutation(CancelPurchase);
  const [confirmPurchase] = useMutation(ConfirmPurchase);

  async function executeAction(mutation, variables = {}) {
    try {
      setState({ ...state, sending: true, invalidMfa: false });
      await mutation({ variables: { ...variables, id: purchaseID } });
      setState({ ...state, sending: false, poll: true });
    } catch (err) {
      const foundError = findGraphQLError(err, {
        codes: ['INSUFFICIENT_TIER_ERROR', 'USER_REQUIRES_VERIFICATION_TO_COMPLETE_PURCHASE'],
      });
      const overLimitError = findVolumeOrTransactionError(err, t);

      if (foundError) {
        setState({
          ...state,
          spendingLimitError: foundError.extensions,
          showMfaForm: false,
          invalidMfa: false,
        });
      } else if (findGraphQLError(err, { codes: ['MFA_CHALLENGE'] })) {
        setState({ ...state, showMfaForm: true });
      } else if (findGraphQLError(err, { codes: ['INVALID_MFA_ERROR'] })) {
        setState({ ...state, invalidMfa: true });
      } else if (overLimitError) {
        setState({
          ...state,
          overLimitError,
        });
      } else {
        setState({ ...state, err: err.message });
      }
    }
  }

  const actions = {
    dismissTierUpgradeAlert: () => setState({ ...state, spendingLimitError: null }),
    dismissMfaForm: () => setState({ ...state, showMfaForm: false, invalidMfa: false }),
    confirmPurchase: (mfaCode) => executeAction(confirmPurchase, { mfaCode }),
    cancelPurchase: () => executeAction(cancelPurchase),
  };

  return [{ ...state, purchase }, actions];
}
