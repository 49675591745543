import React from 'react';
import ReactDOM from 'react-dom';

import { deviceTypes } from '@airtm/utils/dist/constants';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { fbPixelInit } from 'utils/facebookPixel';
import App from './App';
import './utils/i18n';
import { LOCAL_STORAGE } from './constants';
import { hasMessageError } from './constants/errorCodes/errorCodes';
import { loadScript } from 'utils';
import { heapInit, heapInitSampleRate } from 'thirdPartyScripts/heap';
import i18n from './utils/i18n';
import { initFacebookSdk } from './thirdPartyScripts/FacebookSdk';

// prevent this app be used in not allows domains
// also because there is no way to make history.pushState work when base is defined
const baseUrl = document.querySelector('base')?.href;
if (baseUrl) window.location.href = baseUrl;

/**
 * The following two functions are just loggers to understand if the user which face errors like `removeChild` and `insertBefore`,
 * are using some translations tool or not. This error could be related with the following:
 * https://github.com/facebook/react/issues/17256
 * Issues related DV-723 & DV-724.
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this && Sentry && localStorage) {
      try {
        Sentry.addBreadcrumb({
          category: 'remove-child-error',
          level: Sentry.Severity.Error,
          message: 'remove child error',
          data: {
            airtmLng: localStorage.getItem('AirtmLng'),
            text: getBalanceLabel(),
            navigatorLanguage: navigator.language,
            navigatorLanguages: navigator.languages,
          },
        });
      } catch (err) {
        console.warn('something went wrong when trying to create breadcrumb', err);
      }
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this && Sentry && localStorage) {
      try {
        Sentry.addBreadcrumb({
          category: 'insert-before-error',
          level: Sentry.Severity.Error,
          message: 'insert before error',
          data: {
            airtmLng: localStorage.getItem('AirtmLng'),
            text: getBalanceLabel(),
            navigatorLanguage: navigator.language,
            navigatorLanguages: navigator.languages,
          },
        });
      } catch (err) {
        console.warn('something went wrong when trying to create breadcrumb', err);
      }
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

function getBalanceLabel() {
  const el = document.querySelector('.d-none.d-sm-inline-block.pr-2');
  return el ? el.innerText : '';
}

// facebook pixel tracker
fbPixelInit();

// Register the languageChanged event and perform initial setup
i18n.on('languageChanged', async (language = DEFAULT_LANGUAGE) => {
  initFacebookSdk(language);
});

heapInitSampleRate();

heapInit();

const KOUNT_URL = process.env.KOUNT_URL || '';
const KOUNT_MERCHANT_ID = process.env.KOUNT_MERCHANT_ID || '';
const DISABLE_KOUNT_ACCESS = process.env.DISABLE_KOUNT_ACCESS || '';

if (!DISABLE_KOUNT_ACCESS || DISABLE_KOUNT_ACCESS.toUpperCase() === 'FALSE') {
  loadScript(`${KOUNT_URL}/collect/sdk?m=${KOUNT_MERCHANT_ID}`, () => !!window.ka)
    .then((loaded) => {
      if (window.ka) {
        const client = new window.ka.ClientSDK();
        client.collectData();
        localStorage.setItem(LOCAL_STORAGE.KOUNT_SESSIONID, window.ka.sessionId);
      } else {
        console.error('Kount not loaded', window.ka, ka);
      }
    })
    .catch(() => {
      console.error('Kount failed to load');
      localStorage.removeItem(LOCAL_STORAGE.KOUNT_SESSIONID);
    });
}

const chameleonToken = process.env.CHAMELEON_TOKEN;

if (chameleonToken) {
  /* eslint-disable */
  !(function (t, n, o) {
    var a = 'chmln',
      c = 'setup identify alias track clear set show on off custom help _data'.split(' ');
    n[a] || (n[a] = {}), (n[a].accountToken = o), (n[a].location = n.location.href.toString());
    for (var e = 0; e < c.length; e++)
      !(function () {
        var t = (n[a][c[e] + '_a'] = []);
        n[a][c[e]] = function () {
          t.push(arguments);
        };
      })();
    var s = t.createElement('script');
    (s.src = 'https://fast.trychameleon.com/messo/' + o + '/messo.min.js'),
      (s.async = !0),
      t.head.appendChild(s);
  })(document, window, chameleonToken);
  /* eslint-enable */
}

let lastEvent = '';

if (process.env.SENTRY_DSN) {
  // Prevent default Sentry handling for the following promise rejections
  const unhandledPromisesToIgnore = [
    // Metamask
    'chain is not set up',
    'Ethereum is not initialized',
  ];

  const sentryOptions = {
    debug: process.env.SENTRY_DEBUG === 'true',
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    release: `${__APP_NAME__}@${__APP_VERSION__}`,
    environment: process.env.SENTRY_ENVIRONMENT || 'development',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: process.env.SENTRY_SAMPLE_RATE ? Number(process.env.SENTRY_SAMPLE_RATE) : 0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
      'Object Not Found Matching Id',
      // UC Browser Samsung browser
      'Refused to send beacon',
      // ignore https://ucads.ucweb.com/ ads in UC browser
      'https://plugin.ucads.ucweb.com',
      // ignore frame error in Opera Mini browser on Android
      'SecurityError: Blocked a frame with origin',
      // geetest network error
      'Error: 网络错误',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // All Chromium (incl Edge)
      /^chrome-extension:\/\//i,
      // Mozilla
      /^moz-extension:\/\//i,
      // Safari
      /safari-(web-)?extension:/i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /static.geetest.com\/static\/js\/geetest.0.0.0.js/,
      /fast\.trychameleon\.com\//i,
      // Google Tag Manager
      /static\.hotjar\.com\/c\/hotjar-1008237\.js\?sv=7/,
      /www\.google-analytics\.com\/analytics\.js/,
      /www\.googleadservices\.com\/pagead\/conversion_async\.js/,
      // Freshchat widget
      /wchat\.freshchat\.com\/js\/widget\.js/,
    ],
    // allowUrls doesn't like this regex so we are using it on beforeSend to drop
    // events that didn't occur on /login, /sign-up, or /activity, for now.
    beforeSend: (event, hint) => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Sentry Error', event, hint);
      }
      const message = hint.originalException?.message || hint.originalException;
      if (unhandledPromisesToIgnore.includes(message)) return null;
      // if the event message contains message or tags error, should ve tracked by Sentry
      if (hasSomeErrorCode(hint, event)) return event;
      // only these pages are allowed to send events
      if (
        !/https?:\/\/.*\/(login|sign-up|activity|funds|operations|home|peer-transfers|send-or-request|crypto|settings|checkout|invite)/.test(
          event.request.url,
        )
      ) {
        return null;
      }
      // Ignore errors thrown by injected 3rd party scripts
      // This first frame of the stack trace will look similar to `at ? (<anonymous>:131:45)`
      // https://github.com/getsentry/sentry-javascript/issues/3147#issuecomment-768978043
      // Or in the case of scripts loaded by the above (gtag.js), <anonymous> is not the first frame
      const isAnonymousFile = event?.exception?.values[0]?.stacktrace?.frames.reduce(
        (acc, curr) => acc + (curr.filename === '<anonymous>' ? 1 : 0),
        0,
      );
      if (isAnonymousFile > 0) {
        return null;
      }

      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      // remove graphql requests from logs
      if (breadcrumb.data?.url === process.env.CORSOLA_URL) return null;

      // remove duplicate XHR 200 events
      if (breadcrumb.category === 'xhr' && breadcrumb.data?.status_code === 200) {
        if (breadcrumb.data.url === lastEvent) return null;
        lastEvent = breadcrumb.data.url;
      } else {
        lastEvent = '';
      }

      return breadcrumb;
    },
    normalizeDepth: 5,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
  };

  let appDevice;
  if (window.RENDER_ON_APP) {
    if (window.DEVICE_TYPE === deviceTypes.IOS) {
      appDevice = deviceTypes.IOS;
    }
    appDevice = deviceTypes.ANDROID;
  }

  Sentry.init(sentryOptions);
  Sentry.setTag('app_android', appDevice === deviceTypes.ANDROID ? 'yes' : 'no');
  Sentry.setTag('app_ios', appDevice === deviceTypes.IOS ? 'yes' : 'no');
}

/**
 * Given sentry context we check if there is an error there. Sometimes `captureContext` is `unidefined` so
 * it's convenient to double-check others sources of messages error as well.
 * @param hint
 * @param event
 * @returns {*|boolean}
 */
function hasSomeErrorCode(hint, event) {
  return (
    hint.captureContext?.tags?.errorCode ||
    hasMessageError(event.message) ||
    hasMessageError(hint.originalException?.message)
  );
}

if (process.env.NODE_ENV === 'development' && process.env.MOCK_REQUESTS === 'true') {
  require('testUtils/mocks/browser');
}

ReactDOM.render(React.createElement(App), document.getElementById('root'));
