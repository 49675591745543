// @flow

type Balance = {|
  id: string,
  amount: number,
  currency: {|
    id: string,
    symbol: string,
    precision: number,
  |},
|};

type Params = {|
  currencyId: string,
  balances: Array<Balance>,
|};

const getWalletBalance = ({ currencyId, balances = [] }: Params): Balance => {
  const balance = balances?.find((b) => b.currency.id === currencyId);

  if (!balance) {
    return {
      amount: '0',
      currency: {
        id: currencyId,
      },
    };
  }

  return balance;
};

export default getWalletBalance;
