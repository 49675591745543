import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconCheckCircle, Input } from '@airtm/airtm-ui';
import {
  PASSWORD_LENGTH_REGEX,
  PASSWORD_LETTER_REGEX,
  PASSWORD_NUMBER_REGEX,
  PASSWORD_SPECIAL_CHARS_REGEX,
} from '@airtm/utils/dist/constants';
import cx from 'classnames';

const ValidationItem = function ({ label, valid }) {
  return (
    <Input.HelperText>
      <span className={cx({ 'text--success': valid })}>{label}</span>
      {valid && <IconCheckCircle size="18" className="ml-3 text--success" />}
    </Input.HelperText>
  );
};

const PasswordValidation = function ({ password, invalid }) {
  const { t } = useTranslation(['SIGNUP']);

  return (
    <div>
      {!invalid && <Input.HelperText>{t('SIGNUP:VALIDATION_PASSWORD_REGEX')}</Input.HelperText>}
      <ValidationItem
        label={t('SIGNUP:VALIDATION_PASSWORD_NUMBER')}
        valid={password.search(PASSWORD_NUMBER_REGEX) !== -1}
      />
      <ValidationItem
        label={t('SIGNUP:VALIDATION_PASSWORD_LETTER')}
        valid={password.search(PASSWORD_LETTER_REGEX) !== -1}
      />
      <ValidationItem
        label={t('SIGNUP:VALIDATION_PASSWORD_SPECIAL_CHAR')}
        valid={password.search(PASSWORD_SPECIAL_CHARS_REGEX) !== -1}
      />
      <ValidationItem
        label={t('SIGNUP:VALIDATION_PASSWORD_LENGTH')}
        valid={password.search(PASSWORD_LENGTH_REGEX) !== -1}
      />
    </div>
  );
};

PasswordValidation.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordValidation;
