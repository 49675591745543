export default {
  possibleTypes: {
    Auth__AnyUser: ['Auth__OperationUser', 'Auth__User'],
    Operations__Abstract: [
      'Operations__Buy',
      'Operations__CardPayment',
      'Operations__CardPurchase',
      'Operations__CardRefund',
      'Operations__CardReload',
      'Operations__CardUnload',
      'Operations__Crypto',
      'Operations__Direct_Crypto',
      'Operations__Direct',
      'Operations__DebitDirect',
      'Operations__DirectDlocal',
      'Operations__Payment',
      'Operations__PromotionReward',
      'Operations__ReferralReward',
      'Operations__Sell',
      'Operations__Send',
      'Operations__MergedPeerToPeer',
    ],
    Payments__Abstract: ['Payments__Payout', 'Payments__Purchase'],
  },
};
