import React, { useEffect, useMemo } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import FacebookLogin from 'graphqlDocuments/mutations/FacebookLogin.graphql';
import GoogleLogin from 'graphqlDocuments/mutations/GoogleLogin.graphql';
import compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { SignUpPartial, AddNotification, AppleLogin } from 'graphqlDocuments/mutations';
import { withCustomProps } from 'hoc/withCustomProps';
import getBrowserUtmData from 'utils/getBrowserUtmData';
import withCaptcha, { useCaptcha } from 'components/Captcha/withCaptcha';
import SIGN_UP_CAPTCHA_IS_REQUIRED_QUERY from './SignUpCaptchaIsRequiredQuery.graphql';

const DISABLE_KOUNT_ACCESS = process.env.DISABLE_KOUNT_ACCESS || '';

const SignUpContainer = (SignUp) =>
  compose(
    withCustomProps({
      enableKountAccess: !DISABLE_KOUNT_ACCESS || DISABLE_KOUNT_ACCESS.toUpperCase() === 'FALSE',
    }),
    withTranslation(['SIGNUP', 'LOGIN', 'ERRORS']),
    graphql(SignUpPartial, {
      props: ({ mutate }) => ({
        signUpPartial: (variables) => mutate({ variables }),
      }),
    }),
    graphql(AddNotification, {
      props: ({ mutate }) => ({
        addNotification: (variables) => mutate({ variables }),
      }),
    }),
    graphql(FacebookLogin, {
      props: ({ mutate }) => ({
        facebookLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(GoogleLogin, {
      props: ({ mutate }) => ({
        googleLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(AppleLogin, {
      props: ({ mutate }) => ({
        appleLogin: (variables) => mutate({ variables }),
      }),
    }),
    graphql(SIGN_UP_CAPTCHA_IS_REQUIRED_QUERY, {
      fetchPolicy: 'no-cache',
      props: ({ data }) => ({ captchaConfig: data?.captchaIsRequired?.signUp }),
    }),
    withCaptcha({ alwaysRequired: true }),
    (Component) =>
      function SignUpContainerWrapper(props) {
        const { captchaConfig } = props;
        const captchaProps = useCaptcha();
        const { captchaShow } = captchaProps;
        useEffect(() => {
          if (captchaConfig) {
            captchaShow(captchaConfig);
          }
        }, [captchaConfig, captchaShow]);
        const utmData = useMemo(getBrowserUtmData, []);
        return <Component {...props} captchaProps={captchaProps} utmData={utmData} />;
      },
    withRouter,
  )(SignUp);

export default SignUpContainer;
