const googleAnalytics4Id = process.env.GOOGLE_ANALYTICS_4_ID;

export const gtag4Init = ({ user } = {}) => {
  if (user?.id && window.dataLayer && googleAnalytics4Id) {
    const newUser =
      user?.status === 'ACTIVE' &&
      user?.numbers?.completedOperations >= 1 &&
      user?.numbers?.completedOperations <= 3
        ? 'true'
        : 'false';
    window.gtag('config', googleAnalytics4Id, {
      user_id: user.id,
    });
    window.gtag('set', 'user_properties', {
      new_user: newUser,
    });
  }
};

export const trackCustomEvent = (event, params = {}) => {
  if (window.dataLayer) {
    const payload = {
      event,
      ...params,
    };
    window.dataLayer.push(payload);
  }
};
