import { useReactiveVar } from '@apollo/client';
import { networkStatusVar } from 'lib/apollo/clientSchema/NetworkStatus';

const useNetwork = (): { isOnline: boolean } => {
  const networkStatus = useReactiveVar(networkStatusVar);

  const isOnline = networkStatus.online;

  return { isOnline };
};

export default useNetwork;
