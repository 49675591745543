import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { TFunction } from 'i18next';
import formatNumber from './formatNumber';

const volumeOrTransactionLimitErrors = [
  'MAX_DAILY_TRANSACTION_LIMIT_REACHED_FOR_USER',
  'MAX_WEEKLY_TRANSACTION_LIMIT_REACHED_FOR_USER',
  'MAX_MONTHLY_TRANSACTION_LIMIT_REACHED_FOR_USER',
  'MAX_DAILY_VOLUME_LIMIT_REACHED_FOR_USER',
  'MAX_WEEKLY_VOLUME_LIMIT_REACHED_FOR_USER',
  'MAX_MONTHLY_VOLUME_LIMIT_REACHED_FOR_USER',
];

const parseVolumeOrTransactionError = (graphQLError: GraphQLError, t: TFunction) => {
  const { code, response } = graphQLError.extensions;
  const { limit, amountExceeded } = response?.body?.data || {};
  let params;
  if (code.includes('VOLUME')) {
    params = {
      limit: formatNumber(limit, 'USDC', '$'),
      amountExceeded: formatNumber(amountExceeded, 'USDC', '$'),
    };
  } else {
    params = { limit };
  }
  return {
    title: t(`ERRORS:${code}_TITLE`),
    message: t(`ERRORS:${code}_MESSAGE`, params),
  };
};

export const findVolumeOrTransactionError = (error: ApolloError, t: TFunction) => {
  const volumeOrTransactionError = error.graphQLErrors.find((graphQLError) =>
    volumeOrTransactionLimitErrors.includes(graphQLError.extensions.code),
  );
  if (!volumeOrTransactionError) return null;
  return parseVolumeOrTransactionError(volumeOrTransactionError, t);
};

export default findVolumeOrTransactionError;
