import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AirtmLogotype, Button, ChevronRight, LoadingOverlay } from '@airtm/airtm-ui';
import cx from 'classnames';
import { WEBAPP_LINK } from '@constants';
import prism from 'assets/images/prism.png';
import AppStoreButton from 'components/AppStoreButton';
import PlaystoreButton from 'components/PlaystoreButton';

const AppBanner = function (props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [apps, setApps] = useState();
  const isAndroid = navigator.userAgent.includes('Android');

  useEffect(() => {
    if (isAndroid && navigator.getInstalledRelatedApps) {
      navigator
        .getInstalledRelatedApps()
        .then((result) => setApps(Array.isArray(result) ? result : []));
    } else {
      setApps([]);
    }
  }, []);

  if (!apps) {
    return <LoadingOverlay />;
  }

  const isAppInstalled = apps.length > 0;
  const prefix = isAppInstalled ? 'OPEN' : 'DOWNLOAD';

  const DownloadAppButton = isAndroid ? PlaystoreButton : AppStoreButton;

  return (
    <div
      className="content background-contain"
      style={{
        backgroundImage: `url(${prism})`,
      }}
    >
      <div className="d-flex flex-column align-items-center text--center mx-6 my-8">
        <AirtmLogotype className="text--black mb-8" />
        <h3 className="text--uppercase">{t(`STATIC:ANDROID.${prefix}_APP_TITLE`)}</h3>
        <div className="text--lg mt-3">{t(`STATIC:ANDROID.${prefix}_APP_SUBTITLE`)}</div>
        <DownloadAppButton className={cx('mt-4', { 'd-none': isAppInstalled })} />
        <div className="w-100 px-4">
          {isAppInstalled && (
            <Button
              variant="primary"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4"
              href={WEBAPP_LINK}
              onClick={onClose}
              block
            >
              {t(`STATIC:ANDROID.${prefix}_APP_BUTTON`)}
            </Button>
          )}
          <Button
            variant="tertiary-icon-after"
            className="mt-5"
            icon={<ChevronRight />}
            onClick={onClose}
            block
          >
            {t('STATIC:ANDROID.OPEN_ON_WEB')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppBanner;
