import PropTypes from 'prop-types';

export const user = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const ledgerAccountBalances = PropTypes.arrayOf(
  PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
);

export const ledgerAccount = PropTypes.shape({
  balances: ledgerAccountBalances,
});

export const currency = PropTypes.shape({
  id: PropTypes.string,
  precision: PropTypes.number,
  symbol: PropTypes.string,
});

export const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
});

export const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func,
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location),
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

const routeShape = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string,
  sensitive: PropTypes.bool,
  strict: PropTypes.bool,
};
routeShape.routes = PropTypes.arrayOf(PropTypes.shape(routeShape));

export const route = PropTypes.shape(routeShape);

export const i18nPropsShape = {
  t: PropTypes.func,
};

export const formikPropsShape = {
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  touched: PropTypes.objectOf(PropTypes.any).isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  serverErrors: PropTypes.objectOf(PropTypes.node).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  status: PropTypes.any,
};

export const captchaConfigPropType = PropTypes.shape({
  gt: PropTypes.string,
  challenge: PropTypes.string,
  success: PropTypes.number,
});

export const captchaPropsPropType = PropTypes.shape({
  captchaHide: PropTypes.func.isRequired,
  captchaMutationErrorCallback: PropTypes.func.isRequired,
  captchaShow: PropTypes.func.isRequired,
  captchaSolution: PropTypes.shape({
    challenge: PropTypes.string,
    validate: PropTypes.string,
    seccode: PropTypes.string,
  }),
});
