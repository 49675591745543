import React from 'react';
import cx from 'classnames';
import CommentsSlider from 'components/CommentsSlider/CommentsSlider';

function CommentsSliderContainer({
  children,
  className,
}: Readonly<React.PropsWithChildren<{ className?: string }>>) {
  return (
    <div className="row col-12">
      <div className="col-lg-6 p-0">
        <CommentsSlider />
      </div>

      <div
        className={cx(
          'col-12',
          'col-sm-10',
          'col-md-8',
          'col-lg-4',
          'offset-sm-1',
          'offset-md-2',
          'offset-lg-1',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default CommentsSliderContainer;
