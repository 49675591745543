import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@airtm/airtm-ui';
import { useTranslation } from 'react-i18next';
import ERROR_CODES from '@airtm/utils/dist/errorCodes/dodrio';

const {
  BANNED_COUNTRY: { code: BANNED_COUNTRY_CODE },
} = ERROR_CODES;

export const ADDRESS_LOCATION_MODAL_CODES = {
  [BANNED_COUNTRY_CODE]: {
    code: 'BANNED_ADDRESS_LOCATION',
    disableForm: true,
  },
};

export function AddressLocationModal({ code, modalClose }) {
  const { t } = useTranslation(['SIGNUP']);

  return (
    <Modal show modalClose={modalClose}>
      <Modal.Header>
        <h3>{t(`SIGNUP:ADDRESS_LOCATION_MODAL_TITLE_${code}`)}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p className="m-0">{t(`SIGNUP:ADDRESS_LOCATION_MODAL_BODY_${code}`)}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={modalClose} variant="primary">
          {t(`SIGNUP:ADDRESS_LOCATION_MODAL_CONFIRM_${code}`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddressLocationModal.propTypes = {
  code: PropTypes.oneOf(['BANNED_ADDRESS_LOCATION']).isRequired,
  modalClose: PropTypes.func.isRequired,
};
