import { LoadingOverlay } from '@airtm/airtm-ui';
import ApolloNetworkStatus from 'components/ApolloNetworkStatus/ApolloNetworkStatus';
import Notifications from 'containers/Notifications/Notifications';
import SessionContainer from 'containers/SessionContainer/SessionContainer';
import SignupOrLoginContainer from 'containers/SignupOrLoginContainer/SignupOrLoginContainer';
import TranslationsContainer from 'containers/TranslationsContainer';
import MinimalNotifications from 'containers/MinimalNotifications/MinimalNotifications';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import Invitation from 'screens/Invitation/Invitation';

const Layout = React.lazy(() => import(/* webpackChunkName: "Layout" */ 'screens/Layout/Layout'));
const ServerError = React.lazy(() =>
  import(/* webpackChunkName: "ServerError" */ 'screens/ServerError/ServerError'),
);
const EmailVerificationScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "EmailVerificationScreen" */ 'screens/EmailVerificationScreen/EmailVerificationScreen'
  ),
);
const PromotionOptInScreen = React.lazy(() =>
  import(
    /* webpackChunkName: "PromotionOptInScreen" */ 'screens/PromotionOptInScreen/PromotionOptInScreen'
  ),
);

const Routes = function () {
  const { t } = useTranslation(['STATIC']);
  const { state } = useLocation();
  const { skipAppBanner } = state || {};
  return (
    <SessionContainer>
      <TranslationsContainer>
        <ApolloNetworkStatus />
        <Notifications />
        <React.Suspense fallback={<LoadingOverlay />}>
          <Helmet
            defaultTitle={`Airtm - ${t('STATIC:UNLOCK_YOUR_POTENTIAL')}`}
            titleTemplate="Airtm - %s"
          />

          <Switch>
            <Route
              path="/login"
              render={() => <SignupOrLoginContainer skipAppBanner={skipAppBanner} />}
            />
            <Route
              path="/sign-up/sent"
              exact
              render={() => <SignupOrLoginContainer skipAppBanner={skipAppBanner} />}
            />
            <Route
              path="/sign-up"
              render={() => <SignupOrLoginContainer skipAppBanner={skipAppBanner} />}
            />
            <Route
              path="/forgot-password"
              render={() => <SignupOrLoginContainer skipAppBanner />}
            />
            <Route
              path="/recover-password"
              render={() => <SignupOrLoginContainer skipAppBanner />}
            />
            <Route path="/verify-email" component={EmailVerificationScreen} />
            <Route path="/promotion-opt-in" component={PromotionOptInScreen} />
            <Route path="/ivt/:code" component={Invitation} />
            <Route exact path="/500" component={ServerError} />
            <Route path="/" component={Layout} />
          </Switch>
        </React.Suspense>
        <MinimalNotifications />
      </TranslationsContainer>
    </SessionContainer>
  );
};

export default Routes;
