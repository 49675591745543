/* eslint-disable no-restricted-syntax */
import { useEffect, useRef } from 'react';

export const useChangePreventer = (target: React.RefObject<HTMLElement>) => {
  const config = {
    subtree: true,
    childList: true,
    attributes: true,
    attributeOldValue: true,
    characterData: true,
    characterDataOldValue: true,
  };

  function preventChanges(records: MutationRecord[], observer: MutationObserver) {
    for (const record of records) {
      observer.disconnect();
      if (record.type === 'attributes' && record.attributeName) {
        if (record.attributeName === 'class') {
          (record.target as HTMLElement).className = record.oldValue ?? '';
        } else if (record.attributeName === 'style') {
          (record.target as HTMLElement).style.cssText = record.oldValue ?? '';
        } else if (record.attributeName in record.target) {
          (record.target as any)[record.attributeName] = record.oldValue ?? '';
        }
      } else if (record.type === 'childList') {
        for (let i = 0; i < record.addedNodes.length; i++) {
          const newNode = record.addedNodes[i];
          record.target.removeChild(newNode);
        }
        for (let i = 0; i < record.removedNodes.length; i++) {
          const oldNode = record.removedNodes[i];
          if (record.nextSibling) {
            record.target.insertBefore(oldNode, record.nextSibling);
          } else {
            record.target.appendChild(oldNode);
          }
        }
      } else if (record.type === 'characterData') {
        (record.target as CharacterData).data = record.oldValue ?? '';
      }
      if (target.current) {
        observer.observe(target.current, config);
      }
    }
  }

  const changePreventer = useRef<MutationObserver>(new MutationObserver(preventChanges));

  useEffect(() => {
    if (target.current) {
      changePreventer.current.observe(target.current, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current]);
};

export const useRemovalPreventer = (target: React.RefObject<HTMLElement>, id: string) => {
  const config = {
    childList: true,
  };

  function preventRemoval(records: MutationRecord[], observer: MutationObserver) {
    for (const record of records) {
      observer.disconnect();
      if (record.type === 'childList') {
        for (let i = 0; i < record.addedNodes.length; i++) {
          const newNode = record.addedNodes[i] as HTMLElement;
          if (newNode.id === id) {
            record.target.removeChild(newNode);
          }
        }
        for (let i = 0; i < record.removedNodes.length; i++) {
          const oldNode = record.removedNodes[i] as HTMLElement;
          if (oldNode.id === id) {
            if (record.nextSibling) {
              record.target.insertBefore(oldNode, record.nextSibling);
            } else {
              record.target.appendChild(oldNode);
            }
          }
        }
      }
      if (target.current) {
        observer.observe(target.current, config);
      }
    }
  }

  const removalPreventer = useRef<MutationObserver>(new MutationObserver(preventRemoval));

  useEffect(() => {
    if (target.current) {
      removalPreventer.current.observe(target.current, config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current]);
};
