// @flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import JwtManager from 'lib/JwtManager';
import { LoadingOverlay } from '@airtm/airtm-ui';
import { LOCAL_STORAGE } from '@constants';
import InvitationContainer from './InvitationContainer';

type InvitationProps = {
  t: (string, ?Object) => string,
  loading: boolean,
  match: Object,
  matchingPeer: Object,
  referrerEmail: string,
};

const Invitation = function ({ t, loading, match, matchingPeer, referrerEmail }: InvitationProps) {
  if (loading) {
    return <LoadingOverlay />;
  }

  let referrerAlert;
  if (!referrerEmail) {
    referrerAlert = {
      title: t('REFERRALS:ALERT_ACCEPT_INVITER_TITLE_FAILED'),
      message: t('REFERRALS:ALERT_ACCEPT_INVITER_TEXT_FAILED', { email: match.params.code }),
      type: 'error',
    };
  } else if (referrerEmail) {
    localStorage.setItem(LOCAL_STORAGE.REFERRER_EMAIL, referrerEmail);
    if (!JwtManager.jwt) {
      referrerAlert = {
        title: t('REFERRALS:ALERT_ACCEPT_INVITER_TITLE'),
        message: t('REFERRALS:ALERT_ACCEPT_INVITER_TEXT', { email: matchingPeer.name }),
        type: 'success',
      };
    }
  }

  return (
    <Redirect
      to={{
        pathname: '/sign-up',
        state: { skipAppBanner: true, referrerAlert },
      }}
    />
  );
};

export default InvitationContainer(Invitation);
