import { es, pt } from 'date-fns/locale';

export const VALID_LANGUAGES = ['es', 'en', 'pt'];
export const DEFAULT_LANGUAGE = 'en';
export const LANG_LOCALES = {
  en: 'en-US',
  es: 'es-LA',
  pt: 'pt-BR',
};

export const DATE_FORMAT_LOCALES = {
  en: 'MM/dd/yyyy',
  es: 'dd/MM/yyyy',
  pt: 'dd/MM/yyyy',
};

export const PLACEHOLDER_LOCALES = {
  en: 'mm/dd/yyyy',
  es: 'dd/mm/yyyy',
  pt: 'dd/mm/yyyy',
};

export function getBaseURL() {
  return window.location.origin.replace('app.', '');
}

export function getTosLink(language) {
  const lang = VALID_LANGUAGES.includes(language) ? `/${language}` : '';
  return `https://airtm.com${lang}/terms-and-conditions`;
}

export const localeES = es;
localeES.code = LANG_LOCALES.es;

export const localePT = pt;
localePT.code = LANG_LOCALES.pt;

export const LOCALES = {
  es: localeES,
  pt: localePT,
};

export const STATUS = {
  ACCEPTED: 'ACCEPTED',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  CONFIRMED: 'CONFIRMED',
  CREATED: 'CREATED',
  PENDING_CREATION: 'PENDING_CREATION',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  MEDIATION: 'MEDIATION',
  MEDIATION_SETTLED_MAKER: 'MEDIATION_SETTLED_MAKER',
  MEDIATION_SETTLED_TAKER: 'MEDIATION_SETTLED_TAKER',
  MEDIATION_SETTLED_BUYER: 'MEDIATION_SETTLED_BUYER',
  MEDIATION_SETTLED_SELLER: 'MEDIATION_SETTLED_SELLER',
  PENDING: 'PENDING',
  UPDATE_PENDING: 'UPDATE_PENDING',
  FRAUD_REJECTED: 'FRAUD_REJECTED',
  FRAUD_REVIEW: 'FRAUD_REVIEW',
  FRAUD_APPROVED: 'FRAUD_APPROVED',
  MATCHED: 'MATCHED',
  MERGED: 'MERGED',
};

export const STATUS_CLOSED = [
  STATUS.CANCELED,
  STATUS.COMPLETED,
  STATUS.ERROR,
  STATUS.FAILED,
  STATUS.MEDIATION_SETTLED_MAKER,
  STATUS.MEDIATION_SETTLED_TAKER,
  STATUS.MEDIATION_SETTLED_BUYER,
  STATUS.MEDIATION_SETTLED_SELLER,
  STATUS.FRAUD_REJECTED,
  STATUS.MERGED,
];

export const STATUS_OPEN = [
  STATUS.ACCEPTED,
  STATUS.CANCEL_REQUESTED,
  STATUS.CONFIRMED,
  STATUS.CREATED,
  STATUS.MEDIATION,
  STATUS.PENDING,
  STATUS.UPDATE_PENDING,
  STATUS.FRAUD_REVIEW,
  STATUS.FRAUD_APPROVED,
  STATUS.MATCHED,
];
export const STATUS_AVAILABLE_OPERATIONS = [STATUS.CREATED, STATUS.FRAUD_APPROVED];
export const STATUS_CREATED = [
  STATUS.CREATED,
  STATUS.PENDING,
  STATUS.FRAUD_REVIEW,
  STATUS.FRAUD_APPROVED,
];
export const STATUS_CANCELED = [STATUS.CANCELED, STATUS.FRAUD_REJECTED];

export const LOCAL_STORAGE = {
  JWT: 'AirtmJWT',
  JWT_EXPIRES_AT: 'AirtmJWTExpiresAt',
  JWT_IDLE_EXPIRES_AT: 'airtm-jwt-idle-expires-at',
  SHOW_WELCOME_POPUP: 'showWelcomePopup',
  REFERRER_EMAIL: 'AirtmReferrerEmail',
  STORED_NS: 'stored',
  ACTIVITY_FILTERS: 'AirtmActivityFilters',
  PEER_FILTERS: 'AirtmPeerTransfersFilters',
  RESPONSE_TO_TERMS: 'AirtmResponseToTerms',
  LANGUAGE: 'AirtmLng',
  AVATAR: 'Airtm-img-avatar',
  ATTACHMENT: 'Airtm-chat-attachment',
  FORCE_RELOAD: 'AirtmReload',
  KOUNT_WHITELISTED: 'AirtmKountWhitelisted',
  KOUNT_SESSIONID: 'KountSessionid',
  UTM: 'AirtmUTM',
  SKIP_LOGIN_REDIRECTION: 'AirtmSkipLoginRedirection',
  REQUEST_VERIFICATION_EMAIL: 'AirtmVerifyEmailSent',
  TRANSFER_FUNDS_HYDRATOR: 'AirtmTransferFundsHydrator',
  OPT_IN_PROMOTION_ID: 'AirtmOptInPromotionId',
  SHOW_SUCCESSFUL_OPT_IN: 'AirtmShowSuccessfulOptIn',
  HEAP_LOAD_TIMESTAMP: 'AirtmHeapLoadTimestamp',
  HEAP_LOTTERY_TIMESTAMP: 'AirtmHeapLotteryTimestamp',
  HEAP_SESSION_ID: 'AirtmHeapSessionId',
  HEAP_SESSION_COUNT: 'AirtmHeapSessionCount',
  HEAP_WON_LOTTERY: 'AirtmHeapWonLottery',
  HEAP_CUSTOM_SAMPLE_RATE: 'AirtmHeapCustomSampleRate',
  PAYONEER_SUCCESSFUL_CONNECTION: 'AirtmPayoneerSuccessfulConnection',
  PAYONEER_UNSUCCESSFUL_CONNECTION: 'AirtmPayoneerUnsuccessfulConnection',
  PAYONEER_SUCCESSFUL_CHALLENGE: 'AirtmPayoneerSuccessfulChallenge',
  MOONPAY_SUCCESSFUL_CREATION: 'AirtmMoonpaySuccessfulCreation',
  MOONPAY_UNSUCCESSFUL_CREATION: 'AirtmMoonpayUnsuccessfulCreation',
  LOGIN_LOOP_LOG: 'AirtmLoginLoopLog',
  ANDROID_REFERRER: 'AirtmAndroidReferrer',
  SKIP_UPDATE_PASSWORD_MODAL: 'AirtmSkipUpdatePasswordModal',
};

export const LOCAL_STORAGE_WHITELIST = [
  LOCAL_STORAGE.SKIP_LOGIN_REDIRECTION,
  LOCAL_STORAGE.PEER_FILTERS,
  LOCAL_STORAGE.FORCE_RELOAD,
  LOCAL_STORAGE.KOUNT_SESSIONID,
  LOCAL_STORAGE.HEAP_LOTTERY_TIMESTAMP,
  LOCAL_STORAGE.HEAP_SESSION_ID,
  LOCAL_STORAGE.HEAP_SESSION_COUNT,
  LOCAL_STORAGE.HEAP_WON_LOTTERY,
  LOCAL_STORAGE.HEAP_CUSTOM_SAMPLE_RATE,
  LOCAL_STORAGE.SKIP_UPDATE_PASSWORD_MODAL,
  '__leanplum_device_id',
  '__leanplum_user_id',
  '__leanplum_session',
  '__leanplum_message_cache',
  '__leanplum_variables',
  '__leanplum_variants',
  '__leanplum_action_metadata',
  '__leanplum_variant_debug_info',
  '__leanplum_token',
];

export const MIGRATION_STATUS = {
  MIGRATION_COMPLETED: 'MIGRATION_COMPLETED',
  MIGRATION_FAILED: 'MIGRATION_FAILED',
  MIGRATION_REQUIRED: 'MIGRATION_REQUIRED',
  MIGRATION_REQUIRED_TIER_1: 'MIGRATION_REQUIRED_TIER_1',
  MIGRATION_REQUIRED_TIER_2: 'MIGRATION_REQUIRED_TIER_2',
};

export const URL_HASHES = {
  SHOW_MFA: '#mfa-code',
  EMAIL_SENT: '#email-sent',
};

export const PAGINATION_PER_PAGE = 25;

export const SUPPORT_EMAIL = 'support@airtm.com';

export const WEBAPP_LINK = 'https://app.airtm.com';

export const PLAYSTORE_LINK = 'https://play.google.com/store/apps/details?id=com.airtm.android';

export const APPSTORE_LINK = 'https://apps.apple.com/mx/app/airtm/id1634470409';

export const CHARGEBACK_RISK_DECLINED_TAG = 'CANCELLATION_REASONS:CHARGEBACK_RISK_DECLINED';

export const TOS_LINK = `${getBaseURL()}/legal/terms-of-service`;

export const LIMIT_REACHED_MODAL_TYPES = {
  IN: 'IN',
  OUT: 'OUT',
};

export const OTP_CODE_LENGTH = 6;

export const CURRENCY = {
  USD: {
    id: 'USD',
    precision: 2,
    symbol: '$',
  },
  USDC: {
    id: 'USDC',
    precision: 2,
    symbol: '$',
  },
};

/**
 * Each time do you set a destination payment method on funds/withdraw page it will pre-fill as default = $100 USDC
 * @type {number}
 */
export const DEFAULT_FUNDS_AMOUNT = 100;

export const DEFUALT_ITEM_BUFFER = 4;
export const USA_BANK_ACCOUNT_MIN_AGE = 14;

export const MAX_AVG_ACCEPTANCE_TIME = 720;

export const MIN_RATE_PRECISION = 4;

// Anclap Card
export const DEBIT_CARD_PROFITS_ACCOUNT = 'card@airtm.com';

export const DEBIT_CARD_COST_CURRENCY = 'USD';

export const USER_DEBIT_CARD_PAYMENT_TAG = 'anclap-prepaid-ars';
