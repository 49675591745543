/**
 * A helper function that will call given function with given probability.
 * Eg:
 * - .5  --> Will call 50% porcent of the times.
 * - .1  --> Will call 10% porcent of the times.
 * - 1   --> Will call always.
 * - 0   --> never will be called.
 */
export const callFunctionByProbability = (
  onWin: Function,
  onLose: Function,
  probability: number,
): void => {
  if (probability === 1 && onWin) {
    onWin();
  } else {
    const score = Math.random();
    if (score < probability && onWin) {
      onWin();
    } else if (onLose) {
      onLose();
    }
  }
};

export default callFunctionByProbability;
