import { differenceInSeconds } from 'date-fns';
import jwtDecode from 'jwt-decode';
import { LOCAL_STORAGE } from '@constants';

class JwtManager {
  jwtListeners = [];

  constructor() {
    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === LOCAL_STORAGE.JWT) {
        this.jwt = newValue;
      }
    });
  }

  get expiresIn() {
    return this.expiresAt ? differenceInSeconds(this.expiresAt, new Date()) : 0;
  }

  // eslint-disable-next-line class-methods-use-this
  get jwt() {
    return localStorage.getItem(LOCAL_STORAGE.JWT);
  }

  // eslint-disable-next-line class-methods-use-this
  set jwt(jwt) {
    if (jwt) {
      localStorage.setItem(LOCAL_STORAGE.JWT, jwt);
    } else {
      localStorage.removeItem(LOCAL_STORAGE.JWT);
    }

    this.resetIdleSessionExpirationDate();
    this.callListeners();
  }

  get currentUser() {
    if (!this.jwt) return null;
    try {
      return jwtDecode(this.jwt);
    } catch (e) {
      return null;
    }
  }

  get expiresAt() {
    if (!this.currentUser) return null;
    return new Date(this.currentUser.exp * 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  get idleExpiresAt() {
    return new Date(localStorage.getItem(LOCAL_STORAGE.JWT_IDLE_EXPIRES_AT) || Date.now());
  }

  // eslint-disable-next-line class-methods-use-this
  set idleExpiresAt(date) {
    localStorage.setItem(LOCAL_STORAGE.JWT_IDLE_EXPIRES_AT, date.toISOString());
  }

  resetIdleSessionExpirationDate() {
    if (this.currentUser) {
      this.idleExpiresAt = new Date(Date.now() + this.currentUser.idleSessionLimitMs);
    }
  }

  destroyJwt() {
    localStorage.removeItem(LOCAL_STORAGE.JWT);
    localStorage.removeItem(LOCAL_STORAGE.JWT_IDLE_EXPIRES_AT);
    this.callListeners();
  }

  addJwtListener(listener) {
    if (this.jwtListeners.indexOf(listener) === -1) {
      this.jwtListeners.push(listener);
    }
  }

  removeJwtListener(listener) {
    const idx = this.jwtListeners.indexOf(listener);
    if (idx !== -1) {
      this.jwtListeners.splice(idx, 1);
    }
  }

  callListeners() {
    this.jwtListeners.forEach((listener) => {
      listener(this.jwt, this.expiresAt);
    });
  }
}

export default new JwtManager();
