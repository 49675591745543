// @flow
import { v4 as uuid } from 'uuid';

export default ({
  autoClose = true,
  showCta = false,
  autoCloseDuration = 10000,
  errorCode,
  message,
  persist = false,
  title,
  type = 'info',
}: {
  autoClose?: boolean,
  showCta?: boolean,
  autoCloseDuration?: number,
  errorCode?: string,
  message?: string,
  persist: boolean,
  title: string,
  type?: string,
}) => ({
  __typename: 'Notification',
  id: uuid(),
  autoClose,
  showCta,
  autoCloseDuration,
  errorCode,
  message,
  persist,
  title,
  type,
});
