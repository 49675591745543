// @flow

const getMaskedLocalCurrency = (currency: string): string => {
  if (currency === 'LOL') return 'USD (Lollars)';
  if (currency === 'ZUSD') return 'USD';

  return currency;
};

const getUnmaskedLocalCurrency = (currency: string): string => {
  if (currency === 'USD (Lollars)') return 'LOL';

  return currency;
};

export { getMaskedLocalCurrency, getUnmaskedLocalCurrency };
