// @flow
import { BigNumber } from 'bignumber.js';

import getWalletBalance from './getWalletBalance';

type Params = {|
  amount: number,
  currencyId: string,
  balances: Array<{|
    id: string,
    amount: number,
    currency: {|
      id: string,
      symbol: string,
      precision: number,
    |},
  |}>,
|};

export const hasEnoughBalance = ({ amount, currencyId, balances }: Params): boolean => {
  const balance = getWalletBalance({
    currencyId,
    balances,
  });

  return new BigNumber(balance.amount).gte(amount);
};

export default hasEnoughBalance;
