// @flow
import { makeVar } from '@apollo/client';
import { buildNotification } from 'utils';

export const initialData = [];
export const notificationsVar = makeVar(initialData);

export const readNotifications = (): Array<any> => {
  try {
    const notifications = notificationsVar();

    return notifications;
  } catch (error) {
    return [];
  }
};

export const writeNotifications = (notifications) => {
  notificationsVar(notifications);
};

export const resolvers = {
  Query: {
    notifications: () => {
      return readNotifications();
    },
  },

  Mutation: {
    addNotification: (root: {}, args: { notification: Object }) => {
      const { notification } = args;

      const notifications = readNotifications();

      try {
        const newNotifications = [...notifications, buildNotification(notification)];
        writeNotifications(newNotifications);
        return true;
      } catch (error) {
        return false;
      }
    },

    removeNotification: (root: {}, args: { id: string }) => {
      const { id } = args;

      const notifications = readNotifications();

      try {
        const newNotifications = notifications.filter((notification) => notification.id !== id);
        writeNotifications(newNotifications);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
