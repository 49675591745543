// @flow
/* eslint-disable import/prefer-default-export */
import * as AuthUser from './AuthUser';
import * as MinimalNotification from './MinimalNotification';
import * as NetworkStatus from './NetworkStatus';
import * as Notification from './Notification';

export const resolvers = [
  AuthUser.resolvers,
  MinimalNotification.resolvers,
  NetworkStatus.resolvers,
  Notification.resolvers,
];
