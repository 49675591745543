import React, { Suspense } from 'react';
import '@airtm/airtm-ui/dist/airtm.css';

import { ApolloProvider } from '@apollo/client';
import { LoadingOverlay } from '@airtm/airtm-ui';
import { BrowserRouter } from 'react-router-dom';
import MainErrorBoundary from 'components/ErrorBoundaries/MainErrorBoundary';

import apolloClient from 'lib/apollo/client';
import Routes from './routes';

const App = function () {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <MainErrorBoundary errorTag="AppLevelError">
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ApolloProvider>
      </MainErrorBoundary>
    </Suspense>
  );
};

export default App;
