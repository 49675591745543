import * as React from 'react';
import PropTypes from 'prop-types';
import useGoogleApi from 'utils/hooks/useGoogleApi';
import { DEFAULT_LANGUAGE } from '@constants';
import { useTranslation } from 'react-i18next';
import { GoogleLogoButton } from '@airtm/airtm-ui';

const GoogleLoginButton = function (props) {
  const [GoogleApi, loading] = useGoogleApi();
  const { i18n } = useTranslation();
  const { language = DEFAULT_LANGUAGE } = i18n;
  const { onGoogleLogin, text, ...buttonProps } = props;

  React.useEffect(() => {
    if (GoogleApi && process.env.GOOGLE_CLIENT_ID) {
      GoogleApi.accounts.id.initialize({
        client_id: process.env.GOOGLE_CLIENT_ID,
        callback: ({ credential }) => {
          if (onGoogleLogin) {
            onGoogleLogin(credential);
          }
        },
      });
      GoogleApi.accounts.id.renderButton(document.getElementById('buttonAnchor'), {
        theme: 'outline',
        size: 'large',
        shape: 'pill',
        text,
        locale: language,
      });
    }
  }, [GoogleApi, language, onGoogleLogin, text]);

  return (
    <div className="social-button-container">
      <div className="social-button-wrapper">
        <GoogleLogoButton {...buttonProps} disabled={loading} />
      </div>
      <div id="buttonAnchor" className="social-button-hidden" />
    </div>
  );
};

GoogleLoginButton.propTypes = {
  onGoogleLogin: PropTypes.func.isRequired,
  text: PropTypes.oneOf(['signup_with', 'signin_with']).isRequired,
};

export default GoogleLoginButton;
