import * as Sentry from '@sentry/react';
import { deviceTypes } from '@airtm/utils/dist/constants';
import fetch from 'unfetch'; // eslint-disable-line import/extensions
import buildNotification from 'utils/buildNotification';
import i18n from 'i18next';
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { persistCache } from 'apollo3-cache-persist';
import { SentryLink } from 'apollo-link-sentry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { LOCAL_STORAGE, LOCAL_STORAGE_WHITELIST } from '@constants';
import { ErrorCodes } from '@constants/errorCodes/errorCodes';
import JwtManager from 'lib/JwtManager';
import { resolvers } from './clientSchema';
import {
  initialData as initialNetworkStatus,
  writeNetworkStatus,
} from './clientSchema/NetworkStatus';
import {
  initialData as initialNotifications,
  readNotifications,
  writeNotifications,
} from './clientSchema/Notification';
import introspectionResult from './introspectionResult';
import CustomStorage from './storage';

import typeDefs from './local-schema.graphql';

const cache = new InMemoryCache({
  // https://www.graphql-code-generator.com/docs/plugins/fragment-matcher
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Crypto__Wallet: {
      keyFields: ['walletId'],
    },
    Numbers__Permission: {
      keyFields: ['userId', 'paymentMethodId'],
    },
    SecurityHub__Tier: {
      keyFields: ['userId'],
    },
    PaymentMethods__DirectFees: {
      keyFields: ['userId', 'paymentMethodId'],
    },
    Saasquatch__Promotion: {
      keyFields: ['promotionId'],
    },
    PaymentMethods__ExchangeAmounts: {
      keyFields: ['type', 'categoryId'],
    },
    // By default, in the cache, the field data is completely replaced by the incoming one.
    // Apollo throws a warning if the merge function is not explicitily set for arrays and objects.
    PaymentMethods__SuggestedCategoriesResponse: {
      // Merge types of suggested payment methods (byTag, byCountry, recent)
      merge: true,
    },
    PaymentMethods__Category: {
      merge: true,
    },
    Query: {
      fields: {
        availableOperations: {
          // Only keep incoming available operations
          merge: false,
        },
        myPaginatedOperations: {
          merge: true,
        },
        unifiedRate: {
          keyArgs: ({ fromAmountInput, ...args }) => Object.keys(args),
        },
      },
    },
  },
});

const isDevEnv = process.env.NODE_ENV === 'development';

const maxSize = localStorage.getItem('memoryCacheMaxSize');

const OPERATIONS_WITH_HEADERS = ['UploadAttachment'];

persistCache({
  cache,
  // The maxSize config will only be defined if previously
  // defined in localStorage due previous quota failure.
  maxSize: maxSize || undefined,
  storage: CustomStorage,
});

const retryLink = new RetryLink({
  attempts: {
    max: Infinity,
    retryIf: (error, operation) => {
      const isQuery = operation.query?.definitions?.[0]?.operation === 'query';
      if (
        isQuery &&
        // retry gateway errors
        ([502, 504].includes(error.statusCode) ||
          // or changed ip auth error
          error.extensions?.reason === 'CHANGED_IP')
      ) {
        writeNetworkStatus({
          __typename: 'Local__NetworkStatus',
          online: true,
          retrying: true,
        });
        return true;
      }
      return false;
    },
  },
  delay: {
    initial: 300,
    jitter: true,
    max: 30000,
  },
});

const refererLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Referer': window.location.href.split('?')[0],
  },
}));

const getDeviceType = () => {
  if (window.RENDER_ON_APP) {
    return window.DEVICE_TYPE || deviceTypes.ANDROID;
  }

  const isAndroid = /android/i.test(navigator.userAgent);
  // iPhone and iPad including iPadOS 13+ regardless of desktop mode settings
  // https://stackoverflow.com/a/9039885
  const isIOS =
    /iPhone|iPad/.test(navigator.userAgent) ||
    (/Mac/.test(navigator.userAgent) && 'ontouchend' in document);

  if (isAndroid) {
    return deviceTypes.ANDROID_WEB;
  }

  if (isIOS) {
    return deviceTypes.IOS_WEB;
  }

  return deviceTypes.DESKTOP;
};

const deviceTypeLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Device-Type': getDeviceType(),
  },
}));

const webappNameLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    'X-Webapp-Name': __APP_NAME__,
  },
}));

const langLink = setContext((_, { headers }) => {
  const { language } = i18n;

  return {
    headers: {
      ...headers,
      'Accept-Language': language,
    },
  };
});

const authLink = setContext((_, { headers }) => {
  const { jwt } = JwtManager;
  const kountSessionid = localStorage.getItem(LOCAL_STORAGE.KOUNT_SESSIONID);
  return {
    headers: {
      ...headers,
      'X-Kount-Session-Id': kountSessionid,
      authorization: jwt ? `Bearer ${jwt}` : '',
    },
  };
});

const networkStatusLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    writeNetworkStatus({
      __typename: 'Local__NetworkStatus',
      online: true,
      retrying: false,
    });

    return response;
  }),
);

const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  const headers = OPERATIONS_WITH_HEADERS.includes(operation.operationName)
    ? operation.getContext()?.response?.headers
    : null;
  if (graphQLErrors) {
    const notifications = readNotifications();
    graphQLErrors.forEach((error) => {
      if (headers) {
        Object.assign(error.extensions, { headers });
      }
      switch (error.extensions.code) {
        case 'ACCEPT_LIMIT_REACHED_ERROR':
          writeNotifications([
            ...notifications,
            buildNotification({
              autoClose: true,
              errorCode: error.extensions.errorNumber,
              message: i18n.t('ERRORS:ACCEPT_LIMIT_REACHED_MESSAGE'),
              title: i18n.t('ERRORS:ACCEPT_LIMIT_REACHED_TITLE'),
              type: 'informative',
            }),
          ]);
          break;
        case 'JWT_SESSION_ERROR':
          JwtManager.destroyJwt();
          // Since the user is logged out, there's no need to pass the error up in the link chain
          response.errors = undefined;
          break;
        case 'UNAUTHENTICATED':
          JwtManager.destroyJwt();
          break;
        case 'MAX_UNCONFIRMED_ACCEPTED_SELLS_REACHED':
          writeNotifications([
            ...notifications,
            buildNotification({
              autoClose: true,
              errorCode: error.extensions.errorNumber,
              message: i18n.t('ERRORS:MAX_UNCONFIRMED_ACCEPTED_SELLS_REACHED_MESSAGE'),
              title: i18n.t('ERRORS:MAX_UNCONFIRMED_ACCEPTED_SELLS_REACHED_TITLE'),
              type: 'informative',
            }),
          ]);
          break;
        case 'RATE_LIMIT_ERROR': {
          const { id } = error.extensions;
          writeNotifications([
            ...notifications,
            buildNotification({
              autoClose: true,
              errorCode: error.extensions.errorNumber,
              message: i18n.t([
                `ERRORS:RATE_LIMIT_ERROR_MESSAGE_${id}`,
                'ERRORS:RATE_LIMIT_ERROR_MESSAGE',
              ]),
              title: i18n.t([
                `ERRORS:RATE_LIMIT_ERROR_TITLE_${id}`,
                'ERRORS:RATE_LIMIT_ERROR_TITLE',
              ]),
              type: 'informative',
            }),
          ]);
          break;
        }
        default:
          if (
            !error?.extensions?.errorNumber &&
            error?.extensions?.code === 'INTERNAL_SERVER_ERROR' &&
            (error?.extensions?.response?.status || 500) >= 500
          ) {
            Sentry.addBreadcrumb({
              category: 'graphql',
              data: {
                operation: operation.operationName,
                error: JSON.stringify(error, null, 2),
                query: operation.query.loc?.source?.body,
                variables: JSON.stringify(operation.variables, null, 2),
              },
              level: Sentry.Severity.Error,
              message: error.message || 'An unexpected error occurred',
            });
            const message = error.message || 'No error message available';
            const exception = new Error(message);
            Object.assign(exception, error);
            Sentry.captureException(exception, {
              tags: {
                errorCode: ErrorCodes.Client,
              },
            });
          }
      }
    });
  }

  if (networkError) {
    if (headers) {
      Object.assign(networkError, { headers });
    }
    Sentry.addBreadcrumb({
      category: 'networkError',
      data: {
        networkError: JSON.stringify(networkError, null, 2),
        operation: operation.operationName,
        query: operation.query.loc?.source.body,
        variables: JSON.stringify(operation.variables, null, 2),
      },
      level: Sentry.Severity.Error,
      message: `[Network error]: ${networkError.message || 'An unexpected error occurred'}`,
    });

    if (!networkError.statusCode) {
      // an undefined statusCode means the server could not be reached.
      // Most likely, the user is offline.
      writeNetworkStatus({
        __typename: 'Local__NetworkStatus',
        online: false,
        retrying: false,
      });
    }
  }
});

// https://www.npmjs.com/package/apollo-link-sentry/
const sentryLink = new SentryLink({
  setTransaction: true,
  setFingerprint: true,
  breadcrumb: {
    enable: true,
    includeQuery: false,
    includeCache: false,
    includeVariables: true,
    includeResponse: true,
    includeError: true,
    includeContextKeys: [],
  },
  beforeBreadcrumb: (breadcrumb) => {
    // remove sensible or redundant data response
    const isSensibleOperation = [
      'CreateChatMessage',
      'getChatMessages',
      'getChatNotifications',
      'Login',
    ].includes(breadcrumb.message);

    if (isSensibleOperation) {
      // eslint-disable-next-line no-param-reassign
      delete breadcrumb.response;
      // eslint-disable-next-line no-param-reassign
      delete breadcrumb.variables;
    }

    return breadcrumb;
  },
});

const operationNameLink = setContext((request, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Apollo-Operation-Name': request.operationName,
    },
  };
});

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: process.env.CORSOLA_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.CORSOLA_WS_URL,
    connectionParams: () => {
      const { jwt } = JwtManager;

      return {
        authorization: jwt ? `Bearer ${jwt}` : '',
        'X-Device-Type': getDeviceType(),
      };
    },
    shouldRetry: () => true,
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const link = ApolloLink.from([
  sentryLink,
  retryLink,
  errorLink,
  authLink,
  langLink,
  deviceTypeLink,
  webappNameLink,
  refererLink,
  operationNameLink,
  networkStatusLink,
  splitLink,
]);

const client = new ApolloClient({
  name: 'web',
  cache,
  defaultOptions: {
    query: {
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    },
  },
  link,
  resolvers,
  typeDefs,
  connectToDevTools: isDevEnv,
});

const initializeData = () => {
  writeNetworkStatus(initialNetworkStatus);
  writeNotifications(initialNotifications);
};

initializeData();

// on reset apollo cache, we clear local and session storages
client.onClearStore(() => {
  sessionStorage.clear();
  Object.keys(localStorage).forEach((key) => {
    if (!LOCAL_STORAGE_WHITELIST.includes(key)) {
      localStorage.removeItem(key);
    }
  });
  initializeData();
});

// if jwt is expired, reset apollo cache
const onJwtUpdated = (jwt) => {
  if (!jwt) client.clearStore();
};

JwtManager.addJwtListener(onJwtUpdated);

export default client;
