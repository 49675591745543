import * as React from 'react';
import PropTypes from 'prop-types';
import useAppleJs from 'utils/hooks/useAppleJs';
import { AppleLogoButton } from '@airtm/airtm-ui';

type AppleLoginButtonProps = {
  onAppleLogin: () => void;
  onError: () => void;
  text: string;
  width: number;
};

const AppleLoginButton = function (props: AppleLoginButtonProps) {
  const { onAppleLogin, onError, text, ...buttonProps } = props;
  const { loading } = useAppleJs({ onLogin: onAppleLogin, onError: () => {} });

  return (
    <div className="social-button-container">
      <div className="social-button-wrapper">
        <AppleLogoButton {...buttonProps} disabled={loading} />
      </div>
      {!loading && (
        <div
          className="social-button-hidden"
          id="appleid-signin"
          data-color="black"
          data-border-radius="50"
          data-border="true"
          data-type={text}
          data-width="150"
          data-height="40"
        />
      )}
    </div>
  );
};

AppleLoginButton.propTypes = {
  onAppleLogin: PropTypes.func.isRequired,
  text: PropTypes.oneOf(['sign-up', 'sign-in']).isRequired,
};

export default AppleLoginButton;
