import { LANG_LOCALES } from 'constants/index';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';

const SDK_NOT_LOADED_ERROR = 'Facebook SDK not loaded';

export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID || null;
export const FACEBOOK_API_VERSION = process.env.FACEBOOK_API_VERSION || 'v4.0';

export const initFacebookSdk = (language) => {
  if (FACEBOOK_APP_ID) {
    // Called after SDK script is injected into the doc
    window.fbAsyncInit = () => {
      const { FB } = window;
      try {
        FB.init({
          appId: FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: FACEBOOK_API_VERSION,
        });
      } catch (e) {
        console.error('Facebook failed to init:', e);
        Sentry.captureException(e);
      }
    };

    try {
      // Load and inject the facebook SDK
      const elementId = 'facebook-jssdk';
      const scripts = document.getElementsByTagName('script');
      const fjs = scripts[scripts.length - 1];
      const maybeScript = document.getElementById(elementId);
      const lang = (LANG_LOCALES[language] || LANG_LOCALES.en).replace('-', '_');
      if (maybeScript) {
        if (maybeScript.src.indexOf(`/${language}_`) !== -1) {
          return;
        }
        const fbLang = /https:\/\/connect.facebook.net\/([a-zA-Z_]{2,5})\/sdk\.js/.exec(
          maybeScript.src,
        )[1];
        const allScripts = document.querySelectorAll(
          `script[src^="https://connect.facebook.net/${fbLang}/sdk"]`,
        );
        allScripts.forEach((script) => {
          script.parentNode.removeChild(script);
        });
        window.FB = null;
      }
      const js = document.createElement('script');
      js.id = elementId;
      js.defer = true;
      js.async = true;
      js.crossOrigin = 'anonymous';
      js.src = `https://connect.facebook.net/${lang}/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    } catch (e) {
      console.error('Failed to download Facebook SDK:', e);
      Sentry.captureException(e);
    }
  }
};

/**
 * Wraps the SDK in a promise to improve error handling
 */
const facebookCheckStatus = async () => {
  return new Promise((resolve, reject) => {
    if (window.FB) {
      try {
        window.FB.getLoginStatus((response) => {
          resolve(response);
        });
      } catch (e) {
        reject(e);
      }
    } else {
      Sentry.captureException(SDK_NOT_LOADED_ERROR);
      reject(new Error(SDK_NOT_LOADED_ERROR));
    }
  });
};

/**
 * Wraps the SDK in a promise to improve error handling
 */
const facebookLoginAsync = async () => {
  return new Promise((resolve, reject) => {
    if (window.FB) {
      try {
        window.FB.login(
          (response) => {
            resolve(response);
          },
          { scope: 'email' },
        );
      } catch (e) {
        reject(e);
      }
    } else {
      Sentry.captureException(SDK_NOT_LOADED_ERROR);
      reject(new Error(SDK_NOT_LOADED_ERROR));
    }
  });
};

/**
 * Perform facebook login and validate status
 */
export const facebookLogin = async (
  callback = (result) => {
    result?.toString();
  },
) => {
  if (window.FB) {
    try {
      // Already logged in
      let statusResponse = await facebookCheckStatus();
      if (statusResponse.status === 'connected') {
        callback(statusResponse);
        return;
      }

      const loginResponse = await facebookLoginAsync();
      if (loginResponse.status === 'connected') {
        callback(loginResponse);
      } else if (loginResponse.status === 'unknown') {
        statusResponse = await facebookCheckStatus();
        if (statusResponse.status === 'connected') {
          callback(statusResponse);
        }
      } else {
        throw new Error('Facebook SDK failed to perform login');
      }
    } catch (e) {
      // Do not throw internal SDK error to Sentry.  Local errors have already been reported
      console.error('Unable to log in to facebook', e);
    }
  } else {
    Sentry.captureException(SDK_NOT_LOADED_ERROR);
  }
};

/**
 * Performs a Facebook logout if the user is logged in with FB
 */
export const facebookLogout = () => {
  // Only call logout if we are logged in
  if (window.FB && window.FB.getAccessToken()) {
    window.FB.logout();
    Cookies.remove(`fblo_${FACEBOOK_APP_ID}`);
  }
};
