import i18n from 'i18next';
import { locizePlugin } from 'locize';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Locize from 'i18next-locize-backend';
import { LOCAL_STORAGE, DEFAULT_LANGUAGE, VALID_LANGUAGES } from '@constants';
import { logError } from './logger';

const isDevEnv = process.env.NODE_ENV === 'development';
const LOCIZE_PROJECT_ID = process.env.LOCIZE_PROJECT_ID || '';
const LOCIZE_API_KEY = process.env.LOCIZE_API_KEY || '';

const onComplete = (err) => {
  if (err) {
    logError('Locize loading failed', err);
  }
};

const normalizeLangs = (langs) =>
  langs.map((lng) => i18n.services.languageUtils.getLanguagePartFromCode(lng));

const navigatorLangDetector = {
  name: 'navigator-custom',

  lookup() {
    const found = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) {
        // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i = 0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i]);
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage);
      }
      if (navigator.language) {
        found.push(navigator.language);
      }
    }

    const normLanguages = normalizeLangs(found);
    return normLanguages.length > 0 ? normLanguages : undefined;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(navigatorLangDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(locizePlugin) // locize InContextEditor postMessage
  .use(Locize)
  .init(
    {
      // locize-backend
      backend: {
        projectId: LOCIZE_PROJECT_ID,
        ...(isDevEnv && { apiKey: LOCIZE_API_KEY }),
        version: 'PROD',
      },
      // saveMissing: isDevEnv,
      // debug: isDevEnv,
      ns: ['STATIC', 'ERRORS', 'CATEGORY_TREE', 'SIGNUP', 'FORM_FIELDS'],
      preload: [DEFAULT_LANGUAGE],
      detection: {
        order: ['querystring', 'localStorage', 'navigator-custom'],
        lookupLocalStorage: LOCAL_STORAGE.LANGUAGE,
      },
      fallbackLng: DEFAULT_LANGUAGE,
      cleanCode: true,
      whitelist: VALID_LANGUAGES,

      react: {
        bindI18n: 'languageChanged editorSaved',
        transWrapTextNodes: 'span', // this prevent google translate removeChild issues
      },

      interpolation: {
        escapeValue: false,
        format(value, format, lng) {
          if (value instanceof Date) {
            if (format === 'date') {
              return new Intl.DateTimeFormat(lng, {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit',
              }).format(value);
            }

            if (format === 'time') {
              return new Intl.DateTimeFormat(lng, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              }).format(value);
            }

            return new Intl.DateTimeFormat(lng, {
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              month: 'numeric',
              second: 'numeric',
              year: 'numeric',
            }).format(value);
          }

          return value;
        },
      },
    },
    onComplete,
  )
  .then(() => {
    localStorage.setItem(LOCAL_STORAGE.LANGUAGE, i18n.language);
  });

export default i18n;
