import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mutation } from '@apollo/client/react/components';
import { Button, Input, Alert, LoadingOverlay } from '@airtm/airtm-ui';
import LoginImage from 'assets/images/login.jpg';
import { RequestPasswordReset } from 'graphqlDocuments/mutations';
import {
  singleActionReducer,
  singleActionState,
  STARTED,
  FINISHED,
  ERRORED,
  RESET,
  setValue,
} from 'utils/hooks/singleAction';
import { validateEmail } from 'utils/validators';
import Captcha from 'components/Captcha/Captcha';
import withCaptcha, { useCaptcha } from 'components/Captcha/withCaptcha';

const ForgotPassword = function ({ history }) {
  const { captchaHide, captchaIsVerified, captchaMutationErrorCallback, captchaSolution } =
    useCaptcha();
  const { t } = useTranslation(['RECOVER_PASSWORD', 'LOGIN', 'STATIC']);
  const [state, dispatch] = useReducer(singleActionReducer, {
    ...singleActionState,
    email: '',
  });

  // We use the single action reducer because it's all we need for this UI.
  const { working, error, finished, email } = state;

  async function onSubmit({ event, requestPasswordReset }) {
    event.preventDefault();
    if (working || finished) {
      return;
    }
    try {
      dispatch({ type: STARTED });
      await requestPasswordReset({ variables: { captcha: captchaSolution, email } });
      captchaHide();
      dispatch({ type: FINISHED });
    } catch (err) {
      captchaMutationErrorCallback(err);
      if (err.graphQLErrors) {
        const [graphQLError] = err.graphQLErrors;
        const {
          extensions: { code },
        } = graphQLError;

        switch (code) {
          case 'GEETEST_CHALLENGE':
            dispatch({ type: RESET });
            return;
          case 'RATE_LIMIT_ERROR':
            dispatch({ type: ERRORED, error: code });
            return;
          default:
            break;
        }
      }
      dispatch({ type: ERRORED, error: err.message });
    }
  }

  return (
    <div className="row col-12">
      <div className="col-lg-6">
        <img className="img-login-signup" src={LoginImage} alt="login" />
      </div>

      <div className="col-12 col-sm-10 col-md-8 col-lg-4 offset-sm-1 offset-md-2 offset-lg-1">
        <section className="section my-8 mt-sm-6 mt-md-5">
          <div className="section__heading">
            <h1>
              {t(finished ? 'RECOVER_PASSWORD:FORGOT_SENT_TITLE' : 'RECOVER_PASSWORD:FORGOT_TITLE')}
            </h1>
          </div>
          <div className="section__content mt-6">
            {error && error !== 'RATE_LIMIT_ERROR' && (
              <Alert variant="error" title={t('STATIC:SOMETHING_WENT_WRONG')}>
                {t('RECOVER_PASSWORD:FORGOT_SEND_ERROR')}
              </Alert>
            )}
            {finished && (
              <>
                <h4 className="mb-5">{t('RECOVER_PASSWORD:FORGOT_SENT')}</h4>
                <Button block onClick={() => history.push('/login')}>
                  {t('STATIC:UNDERSTOOD')}
                </Button>
              </>
            )}
            {!finished && (
              <>
                <h4>{t('RECOVER_PASSWORD:FORGOT_INSTRUCTIONS')}</h4>
                <p className="text--lg my-5">{t('RECOVER_PASSWORD:FORGOT_DESCRIPTION')}</p>
                <Mutation mutation={RequestPasswordReset}>
                  {(requestPasswordReset) => (
                    <form
                      autoComplete="off"
                      onSubmit={(event) => onSubmit({ event, requestPasswordReset })}
                    >
                      <div className="form-group">
                        <div className="form-control-styled">
                          <Input
                            type="email"
                            id="email"
                            label={t('RECOVER_PASSWORD:FORGOT_EMAIL')}
                            placeholder={t('LOGIN:PLACEHOLDER_EMAIL')}
                            autoComplete="email"
                            value={email}
                            onChange={(event) => dispatch(setValue('email', event.target.value))}
                          />
                          <Input.HelperText>{t('LOGIN:EMAIL_HELPER_TEXT')}</Input.HelperText>
                        </div>
                      </div>
                      <div className="text--center mb-5">
                        <Captcha />
                      </div>
                      <Button
                        variant="primary"
                        type="submit"
                        data-testid="forgot-password-request"
                        disabled={!validateEmail(email) || finished || !captchaIsVerified}
                        loading={working}
                        block
                      >
                        {t('STATIC:CONTINUE')}
                      </Button>
                      <Button variant="secondary" onClick={() => history.push('/login')} block>
                        {t('STATIC:GO_BACK')}
                      </Button>
                    </form>
                  )}
                </Mutation>
              </>
            )}
          </div>
          {working && <LoadingOverlay />}
        </section>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

export default withCaptcha()(ForgotPassword);
