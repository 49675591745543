import { LOCAL_STORAGE } from '@constants';
import * as Sentry from '@sentry/react';
import { differenceInSeconds, parseISO } from 'date-fns';
import JwtManager from 'lib/JwtManager';

const MAX_ATTEMPTS = 10;
const DURATION_SAFE_MINIMUM = 10;

function getLog() {
  try {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOGIN_LOOP_LOG) || '[]');
  } catch {
    return [];
  }
}

function killExistingSession() {
  Sentry.captureException(new Error('Login loop detected'));
  localStorage.setItem(LOCAL_STORAGE.LOGIN_LOOP_LOG, '[]');
  JwtManager.destroyJwt();
}

export function trackLoginRedirect() {
  const log = getLog();
  localStorage.setItem(
    LOCAL_STORAGE.LOGIN_LOOP_LOG,
    JSON.stringify([...log.slice(-(MAX_ATTEMPTS - 1)), new Date()]),
  );
}

export function validateRedirectAttempts() {
  const log = getLog();
  const duration = differenceInSeconds(parseISO(log[log.length - 1]), parseISO(log[0]));

  if (log.length >= MAX_ATTEMPTS && duration < DURATION_SAFE_MINIMUM) {
    killExistingSession();
    return false;
  }
  return true;
}
