import React, { useEffect, useState } from 'react';
import { Button, CountdownV2 } from '@airtm/airtm-ui';
import { useTranslation } from 'react-i18next';
import { useCaptcha } from 'components/Captcha/withCaptcha';

const RESEND_BUTTON_INTERVAL = 2 * 60 * 1000; // 2 minutes in ms

function ResendButton({
  onClick,
  isSendSuccessful,
}: Readonly<{
  onClick: () => Promise<void>;
  isSendSuccessful: boolean;
}>) {
  const { t } = useTranslation(['RECOVER_PASSWORD']);

  const { captchaIsVerified } = useCaptcha();

  const [loading, setLoading] = useState<boolean>(false);
  const [now, setNow] = useState<Date | null>(new Date());

  useEffect(() => {
    if (isSendSuccessful) {
      setNow(new Date());
    }
  }, [isSendSuccessful]);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined;

    if (now) {
      timer = setInterval(() => {
        setNow(null);
      }, RESEND_BUTTON_INTERVAL);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [now]);

  const disabled = loading || !captchaIsVerified;
  const showCountdown = !!now;

  return (
    <div className="u-mt-3">
      {showCountdown ? (
        <div className="text--lg font-weight--bold text--shade-90">
          {t('RECOVER_PASSWORD:RECOVER_MFA_RESEND_CODES')}:{' '}
          <CountdownV2
            className="text--lg font-weight--bold text--shade-90"
            startDate={new Date(now).toISOString()}
            limitDate={new Date(now.getTime() + RESEND_BUTTON_INTERVAL).toISOString()}
            unstyled
          />
        </div>
      ) : (
        <Button className="p-0" variant="link" onClick={handleClick} disabled={disabled}>
          {t('RECOVER_PASSWORD:RECOVER_MFA_RESEND_CODES')}
        </Button>
      )}
    </div>
  );
}

export default ResendButton;
