import { BUSINESS, INDIVIDUAL } from '@airtm/utils/dist/constants/accountTypes';
import { Alert, Button, Check, EyeClosed, EyeOpened, IconButton, Input } from '@airtm/airtm-ui';
import cx from 'classnames';
import Captcha from 'components/Captcha/Captcha';
import FacebookLoginButton from 'components/FacebookLoginButton/FacebookLoginButton';
import GoogleLoginButton from 'components/GoogleLoginButton/GoogleLoginButton';
import AppleLoginButton from 'components/AppleLoginButton/AppleLoginButton';
import PasswordValidation from 'components/PasswordValidation';
import SelectCountry from 'components/SelectCountry';
import { useFormikContext, withFormik } from 'formik';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { LOCAL_STORAGE, getTosLink } from '@constants';
import { useTranslation } from 'react-i18next';
import {
  captchaConfigPropType,
  captchaPropsPropType,
  formikPropsShape,
  location as locationPropType,
} from 'utils/propTypes';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PasswordSafetyAlert from 'components/PasswordAlerts/PasswordSafetyAlert';
import SignUpFormSchema from './SignUpFormSchema';

const STEP_TWO_PATH = 'account-credentials';
const STEP_THREE_PATH = 'account-information';

const accountTypeFieldsMapper = {
  business: {
    firstName: 'BUSINESS_NAME',
    lastName: 'ENTITY_NAME',
  },
  individual: {
    firstName: 'FIRST_NAME',
    lastName: 'LAST_NAME',
    secondLastName: 'SECOND_LAST_NAME',
  },
};

function SignUpFormAgreement() {
  const { i18n, t } = useTranslation(['SIGNUP']);

  return (
    <p className="text--xs">
      {t('SIGNUP:AGREEMENT')}{' '}
      <a
        href={getTosLink(i18n.language)}
        rel="noopener noreferrer"
        target="_blank"
        data-testid="tc-su"
      >
        {t('SIGNUP:AGREEMENT_TOS')} {t('SIGNUP:AGREEMENT_AND')} {t('SIGNUP:AGREEMENT_PRIVACY')}
      </a>
      .
    </p>
  );
}

function SignUpFormAlreadyAMember() {
  const { t } = useTranslation(['SIGNUP']);

  return (
    <div>
      <span className="text--lg text--base mr-2">{t('SIGNUP:ALREADY_A_MEMBER')}</span>
      <Link className="text--lg text--base" data-testid="login-su" to="/login">
        {t('SIGNUP:LOGIN')}
      </Link>
    </div>
  );
}

function SignUpFormStepOne({
  onChange,
  referrerAlert,
  setPath,
  userData,
  userDataErrors,
  validateUserData,
}) {
  const { setSubmitting } = useFormikContext();
  const { t } = useTranslation(['SIGNUP']);
  const [touched, setTouched] = useState(false);

  const handleContinueClick = () => {
    if (!validateUserData()) {
      setSubmitting(false);
      return;
    }

    if (userData.accountType === INDIVIDUAL) {
      setPath(STEP_TWO_PATH);
    } else {
      setPath(STEP_THREE_PATH);
    }
  };

  return (
    <>
      {referrerAlert && (
        <Alert variant={referrerAlert.type} title={referrerAlert.title}>
          {referrerAlert.message}
        </Alert>
      )}
      <div className="form-group row">
        <div className="col-12">
          <label className="mb-3 d-block" htmlFor="accountType">
            <strong>{t('SIGNUP:LABEL_PATIAL_COUNTRY')}</strong>
          </label>
        </div>
        <div className="col-12">
          <SelectCountry
            onChange={({ value }) => onChange('country', value)}
            placeholder={t('SIGNUP:CHOOSE_COUNTRY')}
            value={userData.country}
            maxMenuHeight={200}
            invalid={!!userDataErrors.country && touched}
            onBlur={() => setTouched(true)}
          />
          {touched && userDataErrors.country ? (
            <Input.HelperText invalid>{userDataErrors.country}</Input.HelperText>
          ) : (
            <Input.HelperText>
              <span>{t('SIGNUP:CANT_FIND_COUNTRY')}</span>
              <a
                href={t('SIGNUP:COUNTRY_FAQ_LINK')}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text--sm text--shadow-50"
              >
                {t('SIGNUP:CLICK_HERE')}
              </a>
            </Input.HelperText>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <strong>{t('SIGNUP:SELECT_ACCOUNT_TYPE')}</strong>
        </div>
      </div>
      <div className="form-group row">
        <div className="col">
          <Check
            checked={userData.accountType === INDIVIDUAL}
            data-testid="individual-su"
            id="individual"
            inline
            label={t('SIGNUP:REQUEST_LABEL_ACCOUNT_TYPE_INDIVIDUAL')}
            name="accountType"
            onChange={(event) => onChange('accountType', event.target.value)}
            type="radio"
            value={INDIVIDUAL}
          />
        </div>
        <div className="col">
          <Check
            checked={userData.accountType === BUSINESS}
            id="business"
            data-testid="business-su"
            inline
            label={t('SIGNUP:REQUEST_LABEL_ACCOUNT_TYPE_BUSINESS')}
            name="accountType"
            onChange={(event) => onChange('accountType', event.target.value)}
            type="radio"
            value={BUSINESS}
          />
        </div>
        {userDataErrors.accountType && (
          <Input.HelperText invalid>{userDataErrors.accountType}</Input.HelperText>
        )}
      </div>

      <div className="form-group text--center">
        <Button
          block
          variant="primary"
          data-tracking-id="sign-up-form-step-1-button"
          onClick={handleContinueClick}
          disabled={!userData.country}
        >
          {t('SIGNUP:CONTINUE')}
        </Button>
      </div>
      <SignUpFormAlreadyAMember />
    </>
  );
}

function SignUpFormStepTwo({ onFacebookLogin, onGoogleLogin, onAppleLogin, setPath }) {
  const { t } = useTranslation(['SIGNUP']);

  const handleSignupEmailClick = () => {
    setPath(STEP_THREE_PATH);
  };

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <strong>{t('SIGNUP:SELECT_SIGNUP_METHOD')}</strong>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between align-items-start">
        <span className="social-button-mask" />
        <GoogleLoginButton
          data-tracking-id="sign-up-button-google"
          onGoogleLogin={onGoogleLogin}
          text="signup_with"
        />
        <span className="social-button-spacer" />
        <FacebookLoginButton
          data-testid="facebook-su"
          data-tracking-id="sign-up-button-facebook"
          onFacebookLogin={onFacebookLogin}
          text="continue_with"
        />
        <span className="social-button-spacer" />
        <AppleLoginButton
          onAppleLogin={onAppleLogin}
          text="sign-up"
          data-tracking-id="sign-up-button-apple"
        />
        <span className="social-button-mask" />
      </div>
      <hr className="font-weight--bold" data-text={t('STATIC:OR')} />
      <div className="form-group text--center">
        <Button
          block
          variant="primary"
          data-tracking-id="sign-up-form-step-2-button"
          onClick={handleSignupEmailClick}
        >
          {t('SIGNUP:SIGNUP_EMAIL')}
        </Button>
      </div>
      <SignUpFormAgreement />
      <SignUpFormAlreadyAMember />
    </>
  );
}

function SignUpFormStepThree({
  captchaConfig,
  captchaSolution,
  disabled,
  serverErrors,
  showPassword,
  showPasswordConfirmation,
  togglePasswordView,
  togglePasswordConfirmationView,
  userData,
}) {
  const { errors, handleBlur, handleChange, isSubmitting, touched, values } = useFormikContext();
  const { t } = useTranslation(['SIGNUP']);
  const fieldMapper = accountTypeFieldsMapper[userData.accountType];

  return (
    <>
      <div className="row mb-5">
        <div className="col">
          <strong>{t('SIGNUP:SIGNUP_EMAIL_ACTION')}</strong>
        </div>
      </div>
      {/** ************* EMAIL ************** */}
      <div className="form-group">
        <Input
          autoComplete="off"
          className={cx('form-control', {
            'is-invalid': (touched.email && errors.email) || serverErrors.email,
          })}
          id="email"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('SIGNUP:REQUEST_LABEL_EMAIL')}
          placeholder={t('SIGNUP:REQUEST_PLACEHOLDER_EMAIL')}
          type="email"
          value={values.email}
          invalid={!!((touched.email && errors.email) || serverErrors.email)}
        />
        {touched.email && errors.email && (
          <Input.HelperText invalid>{errors.email}</Input.HelperText>
        )}
        {serverErrors.email &&
          serverErrors.email.map((error) => (
            <Input.HelperText invalid key={error}>
              {error}
            </Input.HelperText>
          ))}
      </div>

      {/** ************* PASSWORD ************** */}
      <PasswordSafetyAlert />
      <div className="form-group">
        <Input
          addonRight={
            <IconButton
              variant="primary"
              size="sm"
              icon={showPassword ? <EyeOpened /> : <EyeClosed />}
              onClick={togglePasswordView}
              tabIndex="-1"
            />
          }
          id="password"
          invalid={!!((touched.password && errors.password) || serverErrors.password)}
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('SIGNUP:CONFIRMATION_LABEL_PASSWORD')}
          placeholder={t('SIGNUP:CONFIRMATION_PLACEHOLDER_PASSWORD')}
          type={showPassword ? 'text' : 'password'}
          value={values.password}
        />
        {touched.password && <Input.HelperText invalid>{errors.password}</Input.HelperText>}
        <PasswordValidation
          password={values.password}
          invalid={!!((touched.password && errors.password) || serverErrors.password)}
        />
        {serverErrors.password &&
          serverErrors.password.map((error) => (
            <Input.HelperText invalid key={error}>
              {error}
            </Input.HelperText>
          ))}
      </div>

      {/** ************* CONFIRM PASSWORD ************** */}
      <div className="form-group">
        <Input
          addonRight={
            <IconButton
              variant="primary"
              size="sm"
              icon={showPasswordConfirmation ? <EyeOpened /> : <EyeClosed />}
              onClick={togglePasswordConfirmationView}
              tabIndex="-1"
            />
          }
          id="passwordConfirmation"
          invalid={!!(touched.passwordConfirmation && errors.passwordConfirmation)}
          name="passwordConfirmation"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t('SIGNUP:CONFIRMATION_LABEL_PASSWORD_CONFIRMATION')}
          placeholder={t('SIGNUP:CONFIRMATION_PLACEHOLDER_PASSWORD_CONFIRMATION')}
          type={showPasswordConfirmation ? 'text' : 'password'}
          value={values.passwordConfirmation}
          onPaste={(e) => e.preventDefault()}
        />
        {touched.passwordConfirmation && (
          <Input.HelperText invalid>{errors.passwordConfirmation}</Input.HelperText>
        )}
      </div>

      {/** ************* FULL NAME ************** */}
      <div className="form-group">
        <Input
          id="firstName"
          invalid={!!((touched.firstName && errors.firstName) || serverErrors.firstName)}
          name="firstName"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t(`SIGNUP:CONFIRMATION_LABEL_${fieldMapper.firstName}`)}
          placeholder={t(`SIGNUP:CONFIRMATION_PLACEHOLDER_${fieldMapper.firstName}`)}
          type="text"
          value={values.firstName}
        />
        {touched.firstName && errors.firstName && (
          <Input.HelperText invalid>{errors.firstName}</Input.HelperText>
        )}
        {serverErrors.firstName &&
          serverErrors.firstName.map((error) => (
            <Input.HelperText invalid key={error}>
              {error}
            </Input.HelperText>
          ))}
      </div>
      <div className="form-group">
        <Input
          id="lastName"
          invalid={!!((touched.lastName && errors.lastName) || serverErrors.lastName)}
          name="lastName"
          onBlur={handleBlur}
          onChange={handleChange}
          label={t(`SIGNUP:CONFIRMATION_LABEL_${fieldMapper.lastName}`)}
          placeholder={t(`SIGNUP:CONFIRMATION_PLACEHOLDER_${fieldMapper.lastName}`)}
          type="text"
          value={values.lastName}
        />
        {touched.lastName && errors.lastName && (
          <Input.HelperText invalid>{errors.lastName}</Input.HelperText>
        )}
        {serverErrors.lastName &&
          serverErrors.lastName.map((error) => (
            <Input.HelperText invalid key={error}>
              {error}
            </Input.HelperText>
          ))}
      </div>
      {userData.accountType === INDIVIDUAL && (
        <div className="form-group">
          <Input
            id="secondLastName"
            name="secondLastName"
            onBlur={handleBlur}
            onChange={handleChange}
            label={t(`SIGNUP:CONFIRMATION_LABEL_${fieldMapper.secondLastName}`)}
            placeholder={t(`SIGNUP:CONFIRMATION_PLACEHOLDER_${fieldMapper.secondLastName}`)}
            type="text"
            value={values.secondLastName}
            invalid={
              !!((touched.secondLastName && errors.secondLastName) || serverErrors.secondLastName)
            }
          />
          {touched.secondLastName && errors.secondLastName && (
            <Input.HelperText invalid>{errors.secondLastName}</Input.HelperText>
          )}
          {serverErrors.secondLastName &&
            serverErrors.secondLastName.map((error) => (
              <Input.HelperText invalid key={error}>
                {error}
              </Input.HelperText>
            ))}
          <p className="text--left text--xs mt-3">* {t('SIGNUP:CONFIRMATION_INCLUDE_LASTNAMES')}</p>
        </div>
      )}
      <div className="form-group text--center">
        <Captcha />
      </div>
      <div className="form-group text--center">
        <Button
          block
          variant="primary"
          data-tracking-id="sign-up-button-submit-form"
          data-testid="create-account-submit-su"
          disabled={
            isSubmitting ||
            captchaConfig === undefined ||
            (captchaConfig && !captchaSolution) ||
            disabled
          }
          id="submit"
          loading={isSubmitting}
          type="submit"
        >
          {t('SIGNUP:REQUEST_BUTTON_SUBMIT')}
        </Button>
      </div>
      <SignUpFormAgreement />
      <SignUpFormAlreadyAMember />
    </>
  );
}

class SignUpForm extends Component {
  static propTypes = {
    ...formikPropsShape,
    id: PropTypes.string.isRequired,
    onFacebookLogin: PropTypes.func.isRequired,
    onGoogleLogin: PropTypes.func.isRequired,
    onAppleLogin: PropTypes.func.isRequired,
    serverErrors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    disabled: PropTypes.bool,
    captchaConfig: captchaConfigPropType,
    captchaProps: captchaPropsPropType.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: locationPropType.isRequired,
    match: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
  };

  static defaultProps = {
    serverErrors: {},
    disabled: false,
    captchaConfig: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showPasswordConfirmation: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { setFieldValue, setSubmitting, submitting, userData } = this.props;

    if (prevProps.submitting !== submitting) {
      setSubmitting(submitting);
    }

    if (prevProps.userData !== userData) {
      setFieldValue('accountType', userData.accountType);
    }
  }

  togglePasswordView = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  togglePasswordConfirmationView = () => {
    this.setState((prevState) => ({
      showPasswordConfirmation: !prevState.showPasswordConfirmation,
    }));
  };

  setPath = (path) => {
    const {
      history,
      match: { url },
    } = this.props;
    history.push(`${url}/${path}`);
  };

  render() {
    const {
      captchaConfig,
      captchaProps: { captchaSolution },
      disabled,
      handleSubmit,
      id,
      location,
      match: { url },
      onChange,
      onFacebookLogin,
      onGoogleLogin,
      onAppleLogin,
      serverErrors,
      userData,
      userDataErrors,
      validateUserData,
    } = this.props;

    const { showPassword, showPasswordConfirmation } = this.state;

    return (
      <form id={id} onSubmit={handleSubmit} className="d-flex flex-column mb-3">
        <Switch>
          <Route exact path={url}>
            <SignUpFormStepOne
              onChange={onChange}
              referrerAlert={location.state?.referrerAlert}
              setPath={this.setPath}
              userData={userData}
              userDataErrors={userDataErrors}
              validateUserData={validateUserData}
            />
          </Route>
          <Route exact path={`${url}/${STEP_TWO_PATH}`}>
            <SignUpFormStepTwo
              onFacebookLogin={onFacebookLogin}
              onGoogleLogin={onGoogleLogin}
              onAppleLogin={onAppleLogin}
              setPath={this.setPath}
              userData={userData}
            />
          </Route>
          <Route exact path={`${url}/${STEP_THREE_PATH}`}>
            <SignUpFormStepThree
              captchaConfig={captchaConfig}
              captchaSolution={captchaSolution}
              disabled={disabled}
              serverErrors={serverErrors}
              showPassword={showPassword}
              showPasswordConfirmation={showPasswordConfirmation}
              togglePasswordView={this.togglePasswordView}
              togglePasswordConfirmationView={this.togglePasswordConfirmationView}
              userData={userData}
            />
          </Route>
          <Redirect to={url} />
        </Switch>
      </form>
    );
  }
}

export default compose(
  withRouter,
  withFormik({
    handleSubmit: (
      { email, firstName, lastName, password, passwordConfirmation, referrerEmail, secondLastName },
      { props: { onSubmit, userData } },
    ) => {
      onSubmit({
        accountType: userData.accountType,
        country: userData.country,
        email,
        firstName,
        lastName: secondLastName ? `${lastName} ${secondLastName}` : lastName,
        password,
        passwordConfirmation,
        ...(referrerEmail && { referrerEmail }),
      });
    },
    mapPropsToValues: ({ userData }) => ({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
      referrerEmail: localStorage.getItem(LOCAL_STORAGE.REFERRER_EMAIL),
      secondLastName: '',
      accountType: userData.accountType,
    }),
    validationSchema: SignUpFormSchema,
  }),
)(SignUpForm);
