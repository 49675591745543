import { useEffect } from 'react';

export default function useCountryFromValue({ value, options, setCountry }) {
  const deps = [value, options.length];

  // Trigger the sync effect when deps change.
  useEffect(() => {
    if (value === false) {
      return;
    }
    const selected = Array.isArray(value)
      ? options.filter((one) => value.includes(one.value))
      : options.find((one) => one.id === value || one.value === value);
    if (selected) {
      setCountry(selected);
    }
  }, deps);
}
