import qs from 'query-string';

import { LOCAL_STORAGE } from '@constants';

const getBrowserUtmData = () => {
  const utm = window.sessionStorage.getItem(LOCAL_STORAGE.UTM) || false;
  const storedUTM = utm ? JSON.parse(utm) : {};
  const search = qs.parse(window.location.search);
  const allKeys = { ...storedUTM, ...search };

  const utmData = Object.entries(allKeys).reduce((acc, [key, value]) => {
    if (key.startsWith('utm_')) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const cookies = document.cookie.split(/; */).reduce((res, c) => {
    if (!c) return res;
    const eq = c.indexOf('=');
    const key = eq > 0 ? c.slice(0, eq) : c;
    let val = eq > 0 ? c.slice(eq + 1) : null;
    try {
      val = decodeURIComponent(val);
    } catch (e) {
      /* pass */
    }
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

  Object.entries(cookies).forEach(([key, value]) => {
    if (key.startsWith('utm_') && !utmData[key]) {
      utmData[key] = value;
    }
  });

  if (document.referrer) {
    utmData.utm_referrer = document.referrer;
  }

  return utmData;
};

export default getBrowserUtmData;
