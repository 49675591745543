import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { notificationsVar } from 'lib/apollo/clientSchema/Notification';
import Notification from './Notification';
import NotificationsContainer from './NotificationsContainer';

const Notifications = function ({ removeNotification }) {
  const notifications = useReactiveVar(notificationsVar);

  if (!notifications || notifications.length === 0) {
    return null;
  }

  return (
    <div className="fixed-top mt-10 toast-overlay">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          removeNotification={removeNotification}
          {...notification}
        />
      ))}
    </div>
  );
};

Notifications.propTypes = {
  removeNotification: PropTypes.func,
};

Notifications.defaultProps = {
  removeNotification: null,
};

export default NotificationsContainer(Notifications);
