// @flow
import { useEffect } from 'react';
import withUser from 'hoc/withUser';
import type { Node } from 'react';
import * as compose from 'lodash.flowright';
import { withTranslation } from 'react-i18next';

type Props = {
  children: Node,
  i18n: any,
  user?: {
    preferences: {
      profile: {
        language: string,
      },
    },
  },
};

const TranslationsContainer = ({ children, i18n, user }: Props) => {
  useEffect(() => {
    if (
      user &&
      user.preferences &&
      user.preferences.profile &&
      user.preferences.profile.language !== i18n.language
    ) {
      i18n.changeLanguage(user.preferences.profile.language);
    }
  }, [user, i18n]);

  return children;
};

TranslationsContainer.defaultProps = {
  user: null,
};

export default compose(withTranslation(), withUser)(TranslationsContainer);
